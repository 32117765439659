import React from "react";
import { useDispatch } from "react-redux";
import { Col, Form, Row } from "react-bootstrap";
import { Formik } from "formik";
import style from "./Other.module.scss";
import { IoIosArrowDropleftCircle } from "react-icons/io";
import { GiWaterDrop } from "react-icons/gi";
import FormContainer from "../../../components/FormContainer";
import { saveBloodComponent } from "../../../redux/new-order/actions/new-orders.actions";
import { useNavigate } from "react-router-dom";
import { SyncOutlined } from "@ant-design/icons";

const OtherBloodComponent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let userDetails;
  if (localStorage.getItem("userToken")) {
    userDetails = JSON.parse(localStorage.getItem("userToken"));
  }

  if (!userDetails) {
    navigate("/");
  }
  return (
    <>
      <Row>
        <Col md={1}>
          <IoIosArrowDropleftCircle
            size={50}
            color="#b70303"
            style={{ cursor: "pointer" }}
            onClick={() => navigate(-1)}
          />
        </Col>
        <Col md={10}>
          <FormContainer>
            <div className={style.form}>
              <div className="mb-5 d-flex justify-content-center">
                {/* <img src={logo} alt='MEIA' width={158} height={52} /> */}
                <Row>
                  <Col
                    className="d-flex justify-content-center"
                    style={{
                      background: "#950303",
                      padding: "10px",
                      borderRadius: "10px",
                    }}
                  >
                    <GiWaterDrop color="white" size={40} />
                  </Col>
                </Row>
              </div>
              <Formik
                initialValues={{ bloodComponent: "" }}
                validate={(values) => {
                  const errors = {};
                  if (!values.bloodComponent) {
                    errors.bloodComponent = "this field is mandatory";
                  }
                  return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {
                  dispatch(saveBloodComponent(values, setSubmitting));
                  navigate("/blood-group");
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <Form.Group
                      controlId="bloodComponent"
                      className={`${style.group} mb-3`}
                    >
                      <Form.Control
                        type="text"
                        placeholder="Other Blood Component"
                        name="bloodComponent"
                        onChange={handleChange}
                        className={`${style.input} rounded p-2`}
                      ></Form.Control>
                      <GiWaterDrop className={style.icon} />
                      {errors.bloodComponent && touched.bloodComponent ? (
                        <p className="text-danger mt-1 mx-2">
                          {errors.bloodComponent}
                        </p>
                      ) : null}
                    </Form.Group>

                    <button
                      type="submit"
                      variant="primary"
                      className={`${style.button} btn`}
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? <SyncOutlined spin /> : "Proceed"}
                    </button>
                  </Form>
                )}
              </Formik>
            </div>
          </FormContainer>
        </Col>
      </Row>
    </>
  );
};

export default OtherBloodComponent;
