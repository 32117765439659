import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { SelectField } from "../../Form/SelectField";
import style from "./Split.module.scss";
import { TbArrowsLeftRight } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import {
  processBoatOrder,
  processExistingOrder,
} from "../../../redux/new-order/actions/new-orders.actions";
import { SuccessModal } from "../../Modal/SuccessModal";
import MessageInstruction from "../../modalInstruction/MessageInstruction";
import { useNavigate } from "react-router-dom";
import { failedOrder } from "../../NotificationToast";

const ProcessOrderSingle = (props) => {
  const [riderDropdown, setRiderDropDown] = useState();
  const [lab, setLabDropDown] = useState();

  const dispatch = useDispatch();

  const { boatOrder, existingOrder } = useSelector(
    (state) => state.newOrderReducer
  );

  useEffect(() => {
    let options = props.vendor?.map((lab) => ({
      label: lab?.name,
      value: lab?.ref_id,
    }));
    setLabDropDown(options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let options = props.rider?.map((ride) => ({
      label: ride?.dispatch_name,
      value: ride?.dispatch_id,
    }));
    setRiderDropDown(options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [successModal, setSuccessModal] = useState(false);

  const navigate = useNavigate();
  const navigateFunc = () => {
    navigate("/ongoing-orders");
  };

  return (
    <div className={style.container}>
      <Formik
        initialValues={{
          title: props.title,
          order_id: props.order_id,
          agent: props.agent,
          vendor_id: "",
          dispatch_id: "",
        }}
        validate={(values) => {
          const errors = {};
          if (!values.vendor_id) {
            errors.vendor_id = "Select a vendor";
          }

          if (!values.dispatch_id) {
            errors.dispatch_id = "Select a dispatch";
          }
          return errors;
        }}
        onSubmit={(values, actions) => {
          values.dispatch_id = values.dispatch_id.value;
          values.vendor_id = values.vendor_id.value;
          props.boatType && (values.order_by = props.order_by);
          props.boatType && (values.qty = props.qty);
          props.boatType && (values.product = props.blood);
          props.boatType && (values.bloodtype = props.bloodtype);

          if (props.boatType) {
            dispatch(processBoatOrder(values));
            if (boatOrder?.status === 300) {
              failedOrder();
            } else {
              setSuccessModal(true);
            }
          } else {
            dispatch(processExistingOrder(values));
            if (existingOrder?.status === 300) {
              failedOrder();
            } else {
              setSuccessModal(true);
            }
          }
        }}
      >
        {({
          handleChange,
          handleSubmit,
          isSubmitting,
          values,
          resetForm,
          setFieldValue,
          handleBlur,
          errors,
          touched,
        }) => (
          <form>
            <div className={style.innerContainer}>
              <div className={`${style.formInput1} shadow-none`}>
                {errors.vendor_id && touched.vendor_id ? (
                  <p className="text-danger mt-1 mx-2">{errors.vendor_id}</p>
                ) : null}
                <SelectField
                  className={style.input}
                  name="vendor_id"
                  options={lab}
                  onChange={setFieldValue}
                  value={values?.vendor_id}
                  placeholder="Find Vendor"
                  onBlur={handleBlur}
                />
              </div>
              <TbArrowsLeftRight size={30} color={props.color} />
              <div className={`${style.formInput1} shadow-none`}>
                {errors.dispatch_id && touched.dispatch_id ? (
                  <p className="text-danger mt-1 mx-2">{errors.dispatch_id}</p>
                ) : null}
                <SelectField
                  className={style.input}
                  name="dispatch_id"
                  options={riderDropdown}
                  onChange={setFieldValue}
                  value={values?.dispatch_id}
                  placeholder="Find Dispatch Rider"
                  onBlur={handleBlur}
                />
              </div>

              <div className={style.content}>
                <div className="d-flex justify-content-center align-items-center">
                  <button
                    type="submit"
                    className={style.button}
                    onClick={handleSubmit}
                    style={{
                      border: `1px solid ${props.border}`,
                      color: props.textColor,
                    }}
                  >
                    process
                  </button>
                </div>
              </div>
            </div>
          </form>
        )}
      </Formik>

      {/* create MODAL */}
      <SuccessModal
        open={successModal}
        onClose={navigateFunc}
        bgcolor={props.textColor}
        width="55vw"
        height="60vh"
        instruction={
          <MessageInstruction
            heading={"SUCCESSFUL!!"}
            hide={"hide"}
            content={"Your order has been placed successfully"}
            button="COMPLETE"
            onClickBtn={navigateFunc}
            color={props.badgeColor}
          />
        }
      />
    </div>
  );
};

export default ProcessOrderSingle;
