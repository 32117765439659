import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { SelectField } from "../../Form/SelectField";
import { GoPlus } from "react-icons/go";
import { TbArrowsLeftRight } from "react-icons/tb";
import { FaMinus } from "react-icons/fa";
import style from "./Split.module.scss";
import { useDispatch, useSelector } from "react-redux";

import { Form } from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";
import { TextField } from "@mui/material";
import _ from "lodash";
import {
  addNewOrder,
  placeOrder,
} from "../../../redux/new-order/actions/new-orders.actions";
import { failedOrder } from "../../NotificationToast";
import { SuccessModal } from "../../Modal/SuccessModal";
import MessageInstruction from "../../modalInstruction/MessageInstruction";
import { useNavigate } from "react-router-dom";

const ProcessCardSplit = (props) => {
  const [riderDropdown, setRiderDropDown] = useState();
  const [successModal, setSuccessModal] = useState(false);
  const [lab, setLabDropDown] = useState();

  let neworder;
  if (localStorage.getItem("newOrder"))
    neworder = JSON.parse(localStorage.getItem("newOrder"));

  const [qty, setQty] = useState(0);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { placedOrder } = useSelector((state) => state.newOrderReducer);

  useEffect(() => {
    let options = props.vendor?.map((lab) => ({
      label: lab?.name,
      value: lab?.ref_id,
    }));
    setLabDropDown(options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let options = props.rider?.map((ride) => ({
      label: ride?.dispatch_name,
      value: ride?.dispatch_id,
    }));
    setRiderDropDown(options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getQtyRange = () => {
    return _.range(1, qty + 1).map((n) => ({
      label: n,
      value: n,
    }));
  };

  useEffect(() => {
    setQty(+props.qty);
  }, [props.qty]);

  const [split, setSplit] = useState([]);

  const handleRemove = (splitItem) => {
    const newItems = split.filter((item) => item.id !== splitItem.id);
    setSplit(newItems);

    const newQuantity = +splitItem.qty + qty;
    setQty(newQuantity);

    // const items = [...split];
    // let index = items.findIndex((value) => value.id === id);
    // items.splice(index, 1);
    // setSplit(items);
  };

  const items = [...split];
  const handleRemoved = (id) => {
    items.splice(
      items.findIndex((value) => value.id === id),
      1
    );
    setSplit(items);
  };

  const colors = () => {
    if (neworder?.title === "BLOOD") return "#B70303";
    if (neworder?.title === "OXYGEN") return "#0779F7";
    if (neworder?.title === "STOCKBANK") return "#066304";
    return "#DA621B";
  };

  const badgeColor = () => {
    if (neworder?.title === "BLOOD") return "#FDE0E0";
    if (neworder?.title === "OXYGEN") return "#E0EFFD";
    return "#F0FDE0";
  };

  const navigateFunc = () => {
    navigate("/ongoing-orders");
  };
  return (
    <>
      {getQtyRange()?.length > 0 && (
        <div className='d-flex align-items-center'>
          <Formik
            initialValues={{ vendor: "", qty: "" }}
            validate={(values) => {
              const errors = {};
              if (!values.vendor) {
                errors.vendor = "Select a vendor";
              }
              if (!values.qty) {
                errors.qty = "Assign quantity";
              }

              return errors;
            }}
            onSubmit={(values) => {
              values.qty = values.qty.value;

              const newSplit = [...split];
              newSplit.push({ id: uuidv4(), ...values });

              setSplit(newSplit);
              setQty(qty - values.qty);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              setFieldValue,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <div className={style.container}>
                <div className={style.innerContainer}>
                  <div>
                    <TextField
                      name='qty2'
                      label='Product'
                      size='small'
                      type='text'
                      defaultValue={neworder?.b_type}
                      disabled
                    />
                  </div>
                  {/* <TbArrowsLeftRight size={30} color={props.color} /> */}
                  <div>
                    <TextField
                      disabled
                      label='Quantity'
                      size='small'
                      // type="text"
                      defaultValue={neworder?.order_amt}
                    />
                  </div>
                  <div className={`${style.formInput} shadow-none`}>
                    {errors.vendor && touched.vendor ? (
                      <p className='text-danger mt-1 mx-2'>{errors.vendor}</p>
                    ) : null}
                    <SelectField
                      className={style.input}
                      name='vendor'
                      options={lab}
                      // value={sp.rider.value}
                      placeholder='Find Vendor??'
                    />
                  </div>
                  <div className={`${style.formInput} shadow-none`}>
                    {errors.qty && touched.qty ? (
                      <p className='text-danger mt-1 mx-2'>{errors.qty}</p>
                    ) : null}
                    <SelectField
                      className={style.input}
                      name='qty'
                      options={getQtyRange()}
                      onChange={setFieldValue}
                      onBlur={handleBlur}
                      placeholder='Assign Quantity'
                    />
                  </div>
                  <div className='d-flex justify-content-end align-items-center me-5 mb-3'>
                    <button
                      className={style.button}
                      type='submit'
                      onClick={handleSubmit}
                      style={{
                        border: `1px solid ${props.border}`,
                        color: props.textColor,
                      }}
                    >
                      <GoPlus
                        size={30}
                        color={props.color}
                        style={{ cursor: "pointer" }}
                      />
                    </button>
                  </div>
                </div>
              </div>
            )}
          </Formik>
        </div>
      )}

      {split.map((sp) => (
        <Formik
          initialValues={{
            order_by: props.order_by,
            agent: props.agent,
            agentType: props.agentType,
            patient: props.patient,
            hmo: props.hmo,
            doctor: props.doctor,
            blood: props.blood,
            bloodtype: props.bloodtype,
            genotype: props.genotype,
            crossmatch: props.crossmatch,
            genotyping: props.genotyping,
            payment: props.payment,
            payment_type: props.payment_type,
            usage: props.usage,
            schedule_date: props.schedule_date,
            schedule_time: props.schedule_time,
            qty: "",
            temperature: props.temperature,
            security: props.security,
            title: props.title,
            product_id: props.product_id,
            order_id: props.order_id,
            dispatch_id: "",
          }}
          validate={(values) => {
            const errors = {};

            if (!values.dispatch_id) {
              errors.dispatch_id = "Select a dispatch";
            }
            return errors;
          }}
          onSubmit={(values) => {
            values.dispatch_id = values.dispatch_id.value;
            values.qty = sp.qty;
            props.title === "Blood" && (values.bloodbank = sp.vendor.value);
            props.title === "Oxygen" && (values.vendor = sp.vendor.value);
            // console.log("values::", values);
            handleRemoved(sp.id);
            dispatch(placeOrder(values));
            // setLoading(true);
            if (placedOrder?.status === 300) {
              failedOrder();
            } else if (items.length <= 1) {
              setSuccessModal(true);
              handleRemoved(sp.id);
              dispatch(addNewOrder());
            } else {
              handleRemoved(sp.id);
              dispatch(addNewOrder());
            }
          }}
        >
          {({
            handleChange,
            handleSubmit,
            isSubmitting,
            values,
            resetForm,
            setFieldValue,
            handleBlur,
            errors,
            touched,
          }) => (
            <Form>
              <div className={style.mainContainer} key={sp.id}>
                <>
                  {/* {items.length > 1 && ( */}
                  <div onClick={() => handleRemove(sp)}>
                    <FaMinus
                      size={25}
                      color={props.color}
                      style={{ cursor: "pointer", marginRight: "5px" }}
                    />
                  </div>
                  {/* )} */}

                  <div className={style.container}>
                    <div className={style.innerContainer}>
                      <div>
                        <TextField
                          name='bloodbank'
                          label='Vendor'
                          size='small'
                          type='text'
                          defaultValue={sp?.vendor?.label}
                          disabled
                        />
                      </div>
                      <div>
                        <TextField
                          disabled
                          name='qty'
                          label='Quantity'
                          size='small'
                          // type="text"
                          defaultValue={sp?.qty}
                        />
                      </div>
                      <TbArrowsLeftRight size={30} color={props.color} />
                      <div className={`${style.formInput} shadow-none`}>
                        {errors.dispatch_id && touched.dispatch_id ? (
                          <p className='text-danger mt-1 mx-2'>
                            {errors.dispatch_id}
                          </p>
                        ) : null}
                        <SelectField
                          className={style.input}
                          name='dispatch_id'
                          options={riderDropdown}
                          placeholder='Find Dispatch Rider'
                        />
                      </div>
                    </div>
                  </div>
                </>

                <div className='d-flex justify-content-end align-items-center me-5 mb-3'>
                  <button
                    className={style.button}
                    type='submit'
                    onClick={handleSubmit}
                    style={{
                      border: `1px solid ${props.border}`,
                      color: props.textColor,
                    }}
                  >
                    Process
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      ))}

      {/* create MODAL */}
      <SuccessModal
        open={successModal}
        onClose={navigateFunc}
        bgcolor={colors()}
        width='50vw'
        height='35vh'
        instruction={
          <MessageInstruction
            heading={"SUCCESSFUL!!"}
            content={" Your order has been placed successfully"}
            button='COMPLETE'
            onClickBtn={navigateFunc}
            color={badgeColor()}
          />
        }
      />
    </>
  );
};

export default ProcessCardSplit;
