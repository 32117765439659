import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import style from "./Cylinder.module.scss";

import { IoIosArrowDropleftCircle } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { cylinderCategory } from "../../../data";
import { useDispatch, useSelector } from "react-redux";
import { saveCylinderSize } from "../../../redux/new-order/actions/new-orders.actions";
import CylinderChild from "./CylinderChild";
import { oxygenList } from "../../../redux/orders/actions/orders.actions";
import CircularLoader from "../../../components/Loader/CircularLoader";

const Cylinder = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  let userDetails;
  if (localStorage.getItem("userToken")) {
    userDetails = JSON.parse(localStorage.getItem("userToken"));
  }

  if (!userDetails) {
    navigate("/");
  }

  const processHandler = (size) => {
    // if (reason.reason === "OTHERS") {
    //   navigate("/other-reason");
    //   // console.log("check::");
    //   return;
    // } else {
    //   navigate("/order-details");
    //   window.location.reload();
    // }
    dispatch(saveCylinderSize(size));
    // console.log("size::", size?.id);
    navigate("/quantity");
  };

  const { oxygen } = useSelector((state) => state.orderReducer);

  useEffect(() => {
    setLoading(true);
    dispatch(oxygenList());
    setLoading(false);
  }, [dispatch]);

  const { orderType } = useSelector((state) => state.newOrderReducer);

  const colors = () => {
    if (orderType?.cardText === "BLOOD") return "#B70303";
    if (orderType?.cardText === "OXYGEN") return "#0779F7";
    if (orderType?.cardText === "STOCKBANK") return "#066304";
    return "#DA621B";
  };

  const selectedOption = JSON.parse(localStorage.getItem("cylinderOption"));

  return (
    <div style={{ width: "97%" }}>
      <Row>
        <Col md={1}>
          <IoIosArrowDropleftCircle
            size={50}
            style={{ cursor: "pointer" }}
            onClick={() => navigate(-1)}
            color={colors()}
          />
        </Col>
        <Col md={10}>
          <h3 className={style.header} style={{ color: colors() }}>
            {selectedOption === "yes"
              ? "SELECT CYLINDER SIZE"
              : "SELECT A CATEGORY"}
          </h3>
          <div className={style.cards}>
            {loading && <CircularLoader />}
            {selectedOption === "yes"
              ? oxygen?.map((size) => (
                  <CylinderChild
                    key={size.id}
                    {...size}
                    onClick={() => processHandler(size)}
                  />
                ))
              : cylinderCategory.map((size) => (
                  <CylinderChild
                    key={size.id}
                    {...size}
                    onClick={() => processHandler(size)}
                  />
                ))}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Cylinder;
