import React, { useEffect, useState } from "react";
import Info from "../../components/InfoCard/Info";
import { FaWallet } from "react-icons/fa";
import { CgCircleci } from "react-icons/cg";
import style from "./Customer.module.scss";
import { Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { loadTickets } from "../../redux/admin/actions/admin.actions";
import { constructOrderDate } from "../../helper/DateFormatter";
import { LinearLoader } from "../../components/Loader/LinearLoader";
// import { ConfirmationModal } from "../../components/Modal/ConfirmationModal";
import Pagination from "../../components/paginationCopy/Pagination";

const Customer = () => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const [ticketModal, setTicketModal] = useState(false);
  const [dataMatch, setDataMatch] = useState();
  const { ticket } = useSelector((state) => state.adminReducer);
  let tickets = ticket?.data;

  useEffect(() => {
    setLoading(true);
    dispatch(loadTickets());
    setLoading(false);
  }, []);

  const handleRow = (data) => {
    setTicketModal(true);
    // let complain;
    let match = tickets?.find((t) => t?.id === data?.id);
    if (match) {
      setDataMatch(match);
    }
    // return complain;
  };

  // pagination
  const [pageNumber, setPageNumber] = useState(0);
  const size = 10;
  const pagesVisited = pageNumber * size;
  const pageCount = Math.ceil(tickets?.length / size);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  // const badgeColor = () => {
  //   if (tickets?.title === "Blood") return "#FDE0E0";
  //   if (tickets?.title === "oxygen") return "#E0EFFD";
  //   return "#F0FDE0";
  // };

  // const colors = () => {
  //   if (tickets?.title === "Blood") return "#B70303";
  //   if (tickets?.title === "oxygen") return "#2457C5";
  //   return "#78A55A";
  // };

  return (
    <>
      <div className={style.container}>
        <Info
          bgColor={"#FDE0E0"}
          color={"#B70303"}
          // percentage={"41%"}
          icon={<FaWallet />}
          value={ticket?.total}
          cardText={"Total Tickets"}
        />
        <Info
          bgColor={"#F0FDE0"}
          color={"#78A55A"}
          // percentage={"11%"}
          icon={<FaWallet />}
          value={ticket?.open}
          cardText={ticket?.open > 1 ? "Open Tickets" : "Open Ticket"}
        />
        <Info
          bgColor={"#FFEFE8"}
          color={"#FBA243"}
          // percentage={"08%"}
          icon={<FaWallet />}
          value={ticket?.pending}
          cardText={ticket?.pending > 1 ? "Pending Tickets" : "Pending Ticket"}
        />
        {/* <Info2 /> */}
      </div>
      <section style={{ maxWidth: "95%", margin: "auto" }}>
        {loading && <LinearLoader />}
        <Table borderless responsive className="mt-4" hover>
          <thead>
            <tr className={style.TableHeadRow}>
              <th>Id</th>
              <th>
                <div className="d-flex align-items-center align-items-center">
                  hospital Name
                </div>
              </th>
              <th>
                <div className="d-flex align-items-center align-items-center">
                  subjects
                </div>
              </th>
              <th>
                <div className="d-flex align-items-center align-items-center">
                  status
                </div>
              </th>

              <th>
                <div className="text-center">Priority</div>
              </th>
              <th>
                <div>Assignee</div>
              </th>
              <th>
                <div>Date</div>
              </th>
            </tr>
          </thead>
          <tbody>
            {tickets
              ?.sort((a, b) => (a.tym > b.tym ? -1 : 1))
              ?.slice(pagesVisited, pagesVisited + size)
              ?.map((tick, index) => (
                <tr
                  className={style.tableBodyRow}
                  key={index}
                  onClick={() => handleRow(tick)}
                >
                  <td>{pageNumber * size + index + 1}</td>
                  <td style={{ width: "20vw" }}>{tick?.name}</td>
                  <td style={{ width: "30vw" }}>{tick?.description}</td>
                  <td style={{ width: "10vw" }}>
                    {" "}
                    <div
                      className={`${style.status} ${style.close}`}
                      style={
                        tick?.status === "open"
                          ? { color: "#3BC34E" }
                          : tick?.status === "pending"
                          ? { color: "#EBAF16" }
                          : { color: "#DD3D43" }
                      }
                    >
                      <span className="me-2">
                        <CgCircleci />
                      </span>
                      {tick?.status === "good" || tick?.status === "Close"
                        ? "closed"
                        : tick?.status}
                    </div>
                  </td>
                  <td>
                    <div className={style.priority}> {tick?.priority}</div>
                  </td>
                  <td>{tick?.assign}</td>
                  <td>{constructOrderDate(tick?.tym)}</td>
                </tr>
              ))}
          </tbody>
        </Table>
        {tickets?.length > size && (
          <Pagination pageCount={pageCount} onPageChange={changePage} />
        )}
      </section>

      {/* DIALOG MODAL */}
      {/* <ConfirmationModal
        open={ticketModal}
        handleClose={() => setTicketModal(false)}
        closemodal={() => setTicketModal(false)}
        bgcolor="white"
        width="85vw"
        // instruction={
        // <UpdateTicket
        //   onClick={() => setTicketModal(false)}
        //   id={dataMatch?.id}
        //   hospital={dataMatch?.name}
        //   description={dataMatch?.description}
        //   priority={dataMatch?.priority}
        //   assign={dataMatch?.assign}
        //   reason={dataMatch?.reason}
        // />
        // }
      /> */}
    </>
  );
};

export default Customer;
