import React from "react";
import styled from "./Instruction.module.scss";

const BloodInstruction = (props) => {
  return (
    <div>
      <div className='d-flex flex-column justify-content-center'>
        <div className='d-flex justify-content-between mt-3 mb-3'>
          <p className={styled.header} style={{ color: props.headerColor }}>
            ORDER DETAILS{" | "}
            <span>{props.orderId}</span>
          </p>

          <p
            className={styled.name}
            style={{
              background: props.categoryBg,
              color: props.categoryColor,
              textTransform: "uppercase",
            }}
          >
            {props.category ? props.category : "oxygen"}
          </p>
        </div>

        <div className={styled.modalContainer1}>
          <p className={styled.title}>product name</p>
          <div className={styled.line}></div>
          <p className={styled.content}>{props.product}</p>
        </div>
        {props.bloodGroup && (
          <div className={styled.modalContainer1}>
            <p className={styled.title}>blood group</p>
            <div className={styled.line}></div>
            <p className={styled.content}>{props.bloodGroup}</p>
          </div>
        )}
        {props.patient && (
          <div className={styled.modalContainer1}>
            <p className={styled.title}>patient name</p>
            <div className={styled.line}></div>
            <p className={styled.content}>{props.patient}</p>
          </div>
        )}
        {props.doctor && (
          <div className={styled.modalContainer1}>
            <p className={styled.title}>doctor name</p>
            <div className={styled.line}></div>
            <p className={styled.content}>{props.doctor}</p>
          </div>
        )}
        {props.payment && (
          <div className={styled.modalContainer1}>
            <p className={styled.title}>Payment Type</p>
            <div className={styled.line}></div>
            <p className={styled.content}>{props.payment}</p>
          </div>
        )}
        <div className={styled.modalContainer1}>
          <p className={styled.title}>quantity</p>
          <div className={styled.line}></div>
          <p className={styled.content}>{props.quantity}</p>
        </div>
        {props.vendor && (
          <div className={styled.modalContainer1}>
            <p className={styled.title}>Hospital name</p>
            <div className={styled.line}></div>
            <p className={styled.content}>{props.vendor}</p>
          </div>
        )}
        <div className={styled.modalContainer1}>
          <p className={styled.title}>reason for order</p>
          <div className={styled.line}></div>
          <p className={styled.content}>{props.reason}</p>
        </div>
        {props.request && (
          <div className={styled.modalContainer1}>
            <p className={styled.title}>additional request(s)</p>
            <div className={styled.line}></div>
            <p className={styled.content}>{props.request}</p>
          </div>
        )}
        {props.hospital && (
          <div className={styled.modalContainer1}>
            <p className={styled.title}>hospital</p>
            <div className={styled.line}></div>
            <p className={styled.content}>{props.hospital}</p>
          </div>
        )}
        {props.address && (
          <div className={styled.modalContainer1}>
            <p className={styled.title}>hospital address</p>
            <div className={styled.line}></div>
            <p className={styled.content}>{props.address}</p>
          </div>
        )}
        {props.phone && (
          <div className={styled.modalContainer1}>
            <p className={styled.title}>hospital phone number</p>
            <div className={styled.line}></div>
            <p className={styled.content}>{props.phone}</p>
          </div>
        )}
        <div className={styled.modalContainer1}>
          <p className={styled.title}>order time</p>
          <div className={styled.line}></div>
          <p className={styled.content}>{props.time}</p>
        </div>
        {props.order_state && (
          <div className={styled.modalContainer1}>
            <p className={styled.title}>order state</p>
            <div className={styled.line}></div>
            <p className={styled.content}>{props.order_state}</p>
          </div>
        )}
        <div className='d-flex justify-content-center'>
          <button
            className={` shadow-none ${styled.actionBtn} ${styled.reject}`}
            style={{ background: props.btnColor }}
            onClick={() => props.onClickYes()}
          >
            {props.button2}
          </button>
        </div>
      </div>
    </div>
  );
};

export default BloodInstruction;
