import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { completedOrders } from "../../../redux/orders/actions/orders.actions";
import Pagination from "../../paginationCopy/Pagination";
import Completed from "./Completed";
import CircularLoader from "../../Loader/CircularLoader";
import Search from "../../search";

const CompletedOrder = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [filterData, setFilterData] = useState([]);

  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState("");

  const { completed } = useSelector((state) => state.orderReducer);

  // console.log("completed:::", completed);
  const dispatch = useDispatch();

  const fetchOngoingOrders = () => {
    dispatch(completedOrders());
  };

  useEffect(() => {
    setLoading(true);
    fetchOngoingOrders();
    const myInterval = setInterval(fetchOngoingOrders, 30000);
    setLoading(false);

    return () => {
      clearInterval(myInterval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Search handler
  useEffect(() => {
    setLoading(true);
    setData(
      completed?.filter((id) => {
        return Object.values(id).some((name) =>
          String(name).toLowerCase().includes(search.toLowerCase())
        );
      })
    );
    setLoading(false);
  }, [completed, search]);

  useEffect(() => {
    setFilterData(data?.filter((id) => filter === id.status));
  }, [data, filter]);

  const handleChange = (e) => {
    setSearch(e.target.value);
  };

  // pagination
  const [pageNumber, setPageNumber] = useState(0);
  const size = 5;
  const pagesVisited = pageNumber * size;
  const pageCount = Math.ceil(data?.length / size);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  return (
    <div>
      <div className='d-flex justify-content-between m-3'>
        <div></div>
        <Search placeholder='Find any order here' onChange={handleChange} />
      </div>
      {/* {completedOrders.map((item) => (
        <Completed {...item} />
      ))} */}
      {loading && (
        <div className='d-flex justify-content-center align-items-center'>
          <CircularLoader />
        </div>
      )}
      {data?.length > 0 ? (
        <>
          {
            // search
            // ? data?.map((item, index) => <Completed {...item} key={index} />)
            data
              ?.sort((a, b) => (a.tym > b.tym ? -1 : 1))
              ?.slice(pagesVisited, pagesVisited + size)
              ?.map((item, index) => (
                <Completed {...item} key={index} />
              ))
          }
        </>
      ) : data?.length === 0 && !loading ? (
        <p className='text-center'>No record found</p>
      ) : (
        <div className='d-flex justify-content-center align-items-center'>
          <CircularLoader />
        </div>
      )}
      {data?.length > size && (
        <Pagination pageCount={pageCount} onPageChange={changePage} />
      )}
    </div>
  );
};

export default CompletedOrder;
