import { Formik, Form, Field } from "formik";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { RiTestTubeFill } from "react-icons/ri";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import style from "./Card.module.scss";

const Card = ({ question, text, name, value }) => {
  const navigate = useNavigate();
  // let picked;

  const { bloodGroup } = useSelector((state) => state.newOrderReducer);

  return (
    <div>
      <Formik
        initialValues={{
          cross: bloodGroup?.bloodGroup === "UNSURE" ? "Yes" : "No",
          test: "No",
          temp: "No",
          genotype: "No",
        }}
        // validationSchema={informationSchema}
        validate={(values) => {
          const errors = {};
          if (!values.cross) {
            errors.cross = "Mandatory";
          } else if (!values.temp) {
            errors.temp = "Mandatory";
          } else if (!values.test) {
            errors.test = "Mandatory";
          } else if (!values.genotype) {
            errors.genotype = "Mandatory";
          }

          return errors;
        }}
        onSubmit={(values) => {
          localStorage.setItem("orderInfo", JSON.stringify(values, null, 2));
          navigate("/quantity");
          // dispatch(addContactPerson(values, actions));
        }}
      >
        {({
          handleChange,
          handleSubmit,
          isSubmitting,
          values,
          event,
          resetForm,
          setFieldValue,
          handleBlur,
          errors,
          touched,
        }) => (
          <>
            <Form onSubmit={handleSubmit}>
              <Row className={style.container}>
                <Col md={1} className={style.tube}>
                  <RiTestTubeFill size={40} color="rgb(170, 167, 167)" />
                </Col>
                <Col md={11}>
                  <>
                    <p className={style.question}>CROSS MATCH?</p>
                    <p className={style.text}>
                      Would you like us to cross-match?
                    </p>
                    {bloodGroup?.bloodGroup === "UNSURE" ? (
                      <p className={style.text2}>
                        Highlighted because you selected the unsure blood group
                        option
                      </p>
                    ) : (
                      ""
                    )}
                    <div className={style.line}></div>
                    <div className="mt-3 d-flex float-end">
                      <label className={style.down}>
                        <p className="me-3 text-muted">Yes</p>
                        <Field type="radio" value="Yes" name="cross" />{" "}
                      </label>
                      {bloodGroup?.bloodGroup === "UNSURE" ? (
                        ""
                      ) : (
                        <label className={style.down}>
                          <p className="me-3 text-muted">No</p>
                          <Field type="radio" value="No" name="cross" />{" "}
                        </label>
                      )}
                      {errors.cross && touched.cross ? (
                        <p className="text-danger mt-1 mx-2">{errors.cross}</p>
                      ) : null}
                    </div>
                  </>
                </Col>
              </Row>
              <Row className={style.container}>
                <Col md={1} className={style.tube}>
                  <RiTestTubeFill size={40} color="rgb(170, 167, 167)" />
                </Col>
                <Col md={11}>
                  <>
                    <p className={style.question}>GENOTYPE</p>
                    <p className={style.text}>
                      Would you like us to conduct genotype test?
                    </p>
                    <div className={style.line}></div>
                    <div className="mt-3 d-flex float-end">
                      <label className={style.down}>
                        <p className="me-3 text-muted">Yes</p>
                        <Field type="radio" value="Yes" name="genotype" />{" "}
                      </label>
                      <label className={style.down}>
                        <p className="me-3 text-muted">No</p>
                        <Field type="radio" value="No" name="genotype" />{" "}
                      </label>
                      {errors.genotype && touched.genotype ? (
                        <p className="text-danger mt-1 mx-2">
                          {errors.genotype}
                        </p>
                      ) : null}
                    </div>
                  </>
                </Col>
              </Row>
              <Row className={style.container}>
                <Col md={1} className={style.tube}>
                  <RiTestTubeFill size={40} color="rgb(170, 167, 167)" />
                </Col>
                <Col md={11}>
                  <>
                    <p className={style.question}>
                      DO YOU NEED TEMPERATURE STRIPS?
                    </p>
                    <p className={style.text}>
                      This helps you monitor blood temperature
                    </p>
                    <div className={style.line}></div>
                    <div className="mt-3 d-flex float-end">
                      <label className={style.down}>
                        <p className="me-3 text-muted">Yes</p>
                        <Field type="radio" value="Yes" name="temp" />{" "}
                      </label>
                      <label className={style.down}>
                        <p className="me-3 text-muted">No</p>
                        <Field type="radio" value="No" name="temp" />{" "}
                      </label>
                      {errors.temp && touched.temp ? (
                        <p className="text-danger mt-1 mx-2">{errors.temp}</p>
                      ) : null}
                    </div>
                  </>
                </Col>
              </Row>
              <Row className={style.container}>
                <Col md={1} className={style.tube}>
                  <RiTestTubeFill size={40} color="rgb(170, 167, 167)" />
                </Col>
                <Col md={11}>
                  <>
                    <p className={style.question}>CONDUCT EXTRA TESTS</p>
                    <p className={style.text}>
                      Would you like us to conduct Malaria and PCV tests?
                    </p>
                    <div className={style.line}></div>
                    <div className="mt-3 d-flex float-end">
                      <label className={style.down}>
                        <p className="me-3 text-muted">Yes</p>
                        <Field type="radio" value="Yes" name="test" />{" "}
                      </label>
                      <label className={style.down}>
                        <p className="me-3 text-muted">No</p>
                        <Field type="radio" value="No" name="test" />{" "}
                      </label>
                      {errors.test && touched.test ? (
                        <p className="text-danger mt-1 mx-2">{errors.test}</p>
                      ) : null}
                    </div>
                  </>
                </Col>
              </Row>

              <Row>
                <button
                  type="submit"
                  className={style.btn}
                  disabled={isSubmitting}
                >
                  Proceed
                </button>
              </Row>
            </Form>
          </>
        )}
      </Formik>
    </div>
  );
};

export default Card;
