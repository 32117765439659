import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import style from "./QuotationModal.module.css";
import { SyncOutlined } from "@ant-design/icons";
import { sendQuotation } from "../../redux/admin/actions/admin.actions";
import axiosClient from "../../service/axios";
import { useNavigate } from "react-router-dom";

const Modal = ({
  setModal,
  dataItem,
  handleSuccessPost,
  updatedProducts,
  id,
}) => {
  const [showSpinner, setShowSpinner] = useState(false);
  const [validityPeriod, setValidityPeriod] = useState(1);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // console.log("updatesmodal", updatedProducts);
  const handleYesButton = async () => {
    setShowSpinner(true);
    const response = await axiosClient.post(
      `/lite/v2/api/admin/quotations/send/${id}`,
      {
        validity: validityPeriod,
        product: updatedProducts,
      }
    );

    // console.log("response", response);

    setTimeout(() => {
      setShowSpinner(false);
      setModal(false);
    }, 1500);
    navigate(`/see-quotation`);
  };
  const handleNoButton = () => {
    setModal(false);
  };
  const handleOverlayClick = () => {};
  return (
    <div className={style.overlay} onClick={handleOverlayClick}>
      <div className={style.modalContent}>
        <h4 className={style.headerModal}>
          Please enter the validity period and submit
        </h4>
        <div className={style.inputContainer}>
          <input
            type="text"
            value={validityPeriod}
            className={style.input}
            placeholder="Validity Period"
            onChange={(e) => setValidityPeriod(e.target.value)}
          />
        </div>
        <div className={style.btnContainer}>
          <button className={style.btn} onClick={handleYesButton}>
            {showSpinner ? <SyncOutlined spin /> : "Yes"}
          </button>
          <button className={style.btn} onClick={handleNoButton}>
            No
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
