import React from "react";

import { useJsApiLoader, GoogleMap, Marker } from "@react-google-maps/api";
import CircularLoader from "../../components/Loader/CircularLoader";
import { useState } from "react";

import style from "./map.module.scss";

const libraries = ["places"];

const RiderMap = ({ lat, long, riderName, riderLabel }) => {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyAg7-YYR9clrIdYVCILJ0zZvL6qDquZLYw",
    libraries,
  });

  const center = {
    lat: lat ? lat : 6.5156,
    lng: long ? long : 3.37402,
  };
  const [map, setMap] = useState(/** @type google.maps.Map */ (null));

  if (!isLoaded) {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <CircularLoader />
      </div>
    );
  }

  return (
    <div className={style.map}>
      <div className={style.googleMap}>
        <GoogleMap
          center={center}
          zoom={15}
          mapContainerStyle={{ width: "100%", height: "100%" }}
          options={{
            streetViewControl: false,
            mapTypeControl: false,
            fullscreenControl: false,
          }}
          onLoad={(map) => setMap(map)}
        >
          {/* <Marker position={center} /> */}
          <Marker
            position={{ lat: lat, lng: long }}
            title={riderName}
            label={riderLabel}
          />
        </GoogleMap>
      </div>
    </div>
  );
};

export default RiderMap;
