import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Maps from "../../components/Map/Map";
import Orders from "../../components/orders";
import Process from "../../components/processflow";
import Search from "../../components/search";
import {
  ongoingOrders,
  overdueOrders,
} from "../../redux/orders/actions/orders.actions";
import style from "./Dashboard.module.scss";

const Dashboard = () => {
  const [dataOverdue, setDataOverdue] = useState([]);
  const [dataOngoing, setDataOngoing] = useState([]);
  const [search, setSearch] = useState("");

  const dispatch = useDispatch();
  const { ongoing, overdue } = useSelector((state) => state.orderReducer);

  // console.log("ongoing", ongoing?.name);

  useEffect(() => {
    dispatch(overdueOrders()).then(() => {
      dispatch(ongoingOrders());
    });
  }, [dispatch]);

  // Search handler
  useEffect(() => {
    setDataOverdue(
      overdue?.filter((id) => {
        return Object.values(id).some((name) =>
          String(name).toLowerCase().includes(search.toLowerCase())
        );
      })
    );
  }, [overdue, search]);

  // Search handler
  useEffect(() => {
    setDataOngoing(
      ongoing?.filter((id) => {
        return Object.values(id).some((name) =>
          String(name).toLowerCase().includes(search.toLowerCase())
        );
      })
    );
  }, [ongoing, search]);

  const handleChange = (e) => {
    setSearch(e.target.value);
  };

  return (
    <div>
      <section className={style.container}>
        <div className={style.textContainer1}>
          <div className={style.textContainer}>
            User save as many lives as quickly as you can | Not an Emergency
            order? tell your customer about Nerve
          </div>
        </div>
        <div className={style.container2}>
          <Search placeholder="Find Any Order..." onChange={handleChange} />
        </div>
      </section>

      <section className={style.menu}>
        <Row md={12}>
          <Col md={8} className={style.column1}>
            <Process className={style.process} />
            <Maps className={style.map} />
          </Col>
          <Col md={4} className={style.orders}>
            <Orders dataOverdue={dataOverdue} dataOngoing={dataOngoing} />
          </Col>
        </Row>
      </section>
    </div>
  );
};

export default Dashboard;
