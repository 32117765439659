import { somethingWentWrongToast } from "../../../components/NotificationToast";
import {
  SAVE_BLOOD_COMPONENT,
  SAVE_BLOOD_GROUP,
  SAVE_HOSPITAL_DETAILS,
  SAVE_ORDER_INFO,
  SAVE_ORDER_QUANTITY,
  SAVE_ORDER_REASON,
  SAVE_ORDER_TYPE,
  SAVE_PAYMENT_METHOD,
  SAVE_REQUEST_TYPE,
  SAVE_CYLINDER_SIZE,
  SAVE_OXYGEN_TYPE,
  SAVE_PATIENT_NAME,
  SAVE_STOCKBANK_DETAILS,
  FETCH_PRODUCTS,
  PLACE_ORDER,
  EXISTING_ORDER,
  SAVE_GENOTYPE,
  BOAT_ORDER,
  SAVE_AGENT_TYPE,
} from "../../types";
import NewOrderService from "../services/new-orders.service";

// @orderType
export const saveOrderType = (data) => (dispatch, getState) => {
  dispatch({
    type: SAVE_ORDER_TYPE,
    payload: data,
  });

  localStorage.setItem("orderType", JSON.stringify(data));
};

// @agentType
export const saveAgentType = (data) => (dispatch) => {
  dispatch({
    type: SAVE_AGENT_TYPE,
    payload: data,
  });

  localStorage.setItem("agentType", JSON.stringify(data));
};

// @hospitalDetails
export const saveHospitalDetails = (data) => (dispatch, getState) => {
  console.log("data", data);
  dispatch({
    type: SAVE_HOSPITAL_DETAILS,
    payload: data,
  });

  localStorage.setItem("hospitalDetails", JSON.stringify(data));
};

// @Request Type
export const saveRequestType = (data) => (dispatch, getState) => {
  dispatch({
    type: SAVE_REQUEST_TYPE,
    payload: data,
  });

  localStorage.setItem("requestType", JSON.stringify(data));
};

// @Blood Component
export const saveBloodComponent = (data) => (dispatch, getState) => {
  dispatch({
    type: SAVE_BLOOD_COMPONENT,
    payload: data,
  });

  localStorage.setItem("bloodComponent", JSON.stringify(data));
};

// @blood group
export const saveBloodGroup = (data) => (dispatch, getState) => {
  dispatch({
    type: SAVE_BLOOD_GROUP,
    payload: data,
  });

  localStorage.setItem("bloodGroup", JSON.stringify(data));
};

// @genotype
export const saveGenotype = (data) => (dispatch, getState) => {
  dispatch({
    type: SAVE_GENOTYPE,
    payload: data,
  });

  localStorage.setItem("genotype", JSON.stringify(data));
};

// @order Info
export const saveOrderInfo = (data) => (dispatch, getState) => {
  dispatch({
    type: SAVE_ORDER_INFO,
    payload: data,
  });

  localStorage.setItem("orderInfo", JSON.stringify(data));
};

// @quantity
export const saveOrderQuantity = (data) => (dispatch, getState) => {
  dispatch({
    type: SAVE_ORDER_QUANTITY,
    payload: data,
  });

  localStorage.setItem("orderQuantity", JSON.stringify(data));
};

// @Payment Method
export const savePaymentMethod = (data) => (dispatch, getState) => {
  dispatch({
    type: SAVE_PAYMENT_METHOD,
    payload: data,
  });

  localStorage.setItem("paymentMethod", JSON.stringify(data));
};

//  @Order Reason
export const saveOrderReason = (data) => (dispatch, getState) => {
  dispatch({
    type: SAVE_ORDER_REASON,
    payload: data,
  });

  localStorage.setItem("orderReason", JSON.stringify(data));
};

//  @Patient name
export const savePatientName = (data) => (dispatch, getState) => {
  dispatch({
    type: SAVE_PATIENT_NAME,
    payload: data,
  });

  localStorage.setItem("patient", JSON.stringify(data));
};

//  @Cylinder Size
export const saveCylinderSize = (data) => (dispatch, getState) => {
  dispatch({
    type: SAVE_CYLINDER_SIZE,
    payload: data,
  });

  localStorage.setItem("cylinderSize", JSON.stringify(data));
};

//  @Oxygen Type
export const saveOxygenType = (data) => (dispatch, getState) => {
  dispatch({
    type: SAVE_OXYGEN_TYPE,
    payload: data,
  });

  localStorage.setItem("oxygenType", JSON.stringify(data));
};

//  @Stockbank
export const saveStockbank = (data) => (dispatch, getState) => {
  dispatch({
    type: SAVE_STOCKBANK_DETAILS,
    payload: data,
  });

  localStorage.setItem("stockbank", JSON.stringify(data));
};

// Create new order
export const addNewOrder = (details, actions) => async (dispatch) => {
  localStorage.removeItem("orderType");
  localStorage.removeItem("orderReason");
  localStorage.removeItem("paymentMethod");
  localStorage.removeItem("orderQuantity");
  localStorage.removeItem("orderInfo");
  localStorage.removeItem("bloodGroup");
  localStorage.removeItem("bloodComponent");
  localStorage.removeItem("hospitalDetails");
  localStorage.removeItem("requestType");
  localStorage.removeItem("stockbank");
  localStorage.removeItem("oxygenType");
  localStorage.removeItem("cylinderSize");
  localStorage.removeItem("orderPrice");
  localStorage.removeItem("patient");
  localStorage.removeItem("hmo");
  localStorage.removeItem("genotype");
};

// @complain order
export const placeOrder = (details, actions) => async (dispatch) => {
  console.log("actions", actions);
  try {
    const response = await NewOrderService.createNewOrder(details);

    // something went wrong
    if (response?.response?.status === 400) {
      actions.setSubmitting(false);
      somethingWentWrongToast();
      return;
    }

    // complained successfull
    if (response.status === 200) {
      dispatch({
        type: PLACE_ORDER,
        payload: response?.data,
      });
      actions?.setSubmitting(false);
      actions.resetForm();
    }
    return response;
  } catch (error) {
    // actions.setSubmitting(false);
    // somethingWentWrongToast();
    return error;
  }
};

// @Process Nerve order
export const processExistingOrder = (details, actions) => async (dispatch) => {
  console.log("actionss12", actions);
  try {
    const response = await NewOrderService.processExistingOrder(details);
    console.log("response", response);

    // something went wrong
    if (response?.response?.status === 400) {
      // actions?.setSubmitting(false);
      somethingWentWrongToast();
      return;
    }

    // complained successfull
    if (response.status === 200) {
      dispatch({
        type: EXISTING_ORDER,
        payload: response?.data,
      });
      // actions?.setSubmitting(false);
      // actions.resetForm();
    }
    return response;
  } catch (error) {
    console.log("error", error);
    // actions.setSubmitting(false);
    somethingWentWrongToast();
    return error;
  }
};

// @Process Boat order
export const processBoatOrder = (details, actions) => async (dispatch) => {
  console.log("actionss1", actions);
  try {
    const response = await NewOrderService.processBoatOrder(details);

    // something went wrong
    if (response?.response?.status === 400) {
      actions?.setSubmitting(false);
      somethingWentWrongToast();
      return;
    }

    // complained successfull
    if (response.status === 200) {
      dispatch({
        type: BOAT_ORDER,
        payload: response?.data,
      });
      actions?.setSubmitting(false);
      actions.resetForm();
    }
    return response;
  } catch (error) {
    actions.setSubmitting(false);
    somethingWentWrongToast();
    return error;
  }
};

//@Ongoing orders
export const fetchProducts = () => async (dispatch) => {
  try {
    const response = await NewOrderService.fetchProducts();

    dispatch({
      type: FETCH_PRODUCTS,
      payload: response?.data,
    });

    return response;
  } catch (error) {
    somethingWentWrongToast();
  }
};
