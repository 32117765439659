import React, { useEffect, useState } from "react";
import ProcessCard from "../../../components/orders/process/ProcessCard";
import style from "./Process.module.scss";
import { IoIosArrowDropleftCircle } from "react-icons/io";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import { useRadioGroup } from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
// import Radio from "@mui/material/Radio";
// import { makeStyles } from "@mui/material/styles";
// import ProcessCardSplit from "../../../components/orders/process/ProcessCardSplit";
// import ProcessCardSingle from "../../../components/orders/process/ProcessCardSingle";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  labXOrders,
  // precheckOrder,
  // processOrder,
} from "../../../redux/orders/actions/orders.actions";
// import { placeOrder } from "../../../redux/new-order/actions/new-orders.actions";
import { SuccessModal } from "../../../components/Modal/SuccessModal";
import MessageInstruction from "../../../components/modalInstruction/MessageInstruction";
// import dateFormat from "../../../helper/DateFormatter";
// import SplitStockBank from "../../../components/orders/process/SplitStockbank";
import axios from "axios";
import {
  failedOrder,
  // somethingWentWrongToast,
} from "../../../components/NotificationToast";
import { Col, Row } from "react-bootstrap";

// const useStyles = makeStyles((theme) => ({
//   root: {},
// }));

const ProcessNewStockbank = () => {
  const [successModal, setSuccessModal] = useState(false);
  // const classes = useStyles();text={"Ksh " +total}
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  let userDetails;
  if (localStorage.getItem("userToken")) {
    userDetails = JSON.parse(localStorage.getItem("userToken"));
  }

  if (!userDetails) {
    navigate("/");
  }

  useEffect(() => {
    dispatch(labXOrders(id));
  }, [id]);

  let precheck;
  if (localStorage.getItem("precheck"))
    precheck = JSON.parse(localStorage.getItem("precheck"));

  let vendor = precheck?.vendor;
  let rider = precheck?.Dispatch;
  let status = precheck?.status;

  let neworder;
  if (localStorage.getItem("newOrder"))
    neworder = JSON.parse(localStorage.getItem("newOrder"));

  // const response1 = {
  //   order_by: neworder?.order_by,
  //   agent: userDetails.id,
  //   bloodbank: vendor[0].ref_id,
  //   dispatch_id: rider[0].dispatch_id,
  //   blood: "Blood",
  //   bloodtype: neworder?.b_type,
  //   crossmatch: neworder?.Cross_matching,
  //   payment: neworder?.payment_type,
  //   usage: neworder?.usage_info,
  //   schedule_date: dateFormat.DateYearMonthDay(neworder?.schedule_date),
  //   schedule_time: dateFormat.TimeOnly(neworder?.schedule_tym),
  //   qty: neworder?.order_amt,
  //   temperature: "",
  //   security: "",
  //   title: neworder?.title === "BLOOD" ? "Blood" : neworder?.title,
  // };

  // const response2 = {
  //   order_by: neworder?.order_by,
  //   agent: userDetails.id,
  //   dispatch_id: rider[1].dispatch_id,
  //   payment_type: neworder?.payment_type,
  //   qty: neworder?.order_amt,

  //   products: neworder?.products?.map((p) => ({
  //     product_id: p?.id,
  //     quantity: p?.qty,
  //   })),
  //   title: neworder?.title === "STOCKBANK" ? "Stockbank" : neworder?.title,
  // };

  // const handleProcess1 = async () => {
  //   dispatch(placeOrder(response1));
  //   setSuccessModal(true);
  // };

  // const baseUrl = "https://stockbank.lifebank.ng/v1/manage/labx/neworder/";
  const baseUrl = "https://stockbank.lifebank.ke/v1/manage/labx/neworder/lite/";

  const handleProcess2 = async () => {
    // dispatch(placeOrder(response2));
    setLoading(true);
    dispatch(
      axios
        .post(baseUrl + neworder?.order_by, {
          requester: "",
          agentType: neworder?.agentType,
          hospital_id: neworder?.order_by,
          payment: neworder?.payment_type,
          hmo: neworder?.hmo,
          products: neworder?.products?.map((p) => ({
            product_id: p?.id,
            quantity: p?.qty,
          })),
        })
        .then(
          (response) => {
            if (response?.status === 200) {
              setSuccessModal(true);
              setLoading(false);
            } else {
              failedOrder();
              setLoading(false);
            }
          },
          (error) => {
            // console.log(error);
            failedOrder();
            setLoading(false);
          }
        )
    );
  };

  // useEffect(() => {
  //   dispatch(labXOrders(id));
  // }, [id]);

  let checked;
  const StyledFormControlLabel = styled((props) => (
    <FormControlLabel {...props} />
  ))(({ _theme, checked }) => ({
    ".MuiFormControlLabel-label": checked && {
      color: "black",
    },
  }));

  function MyFormControlLabel(props) {
    const radioGroup = useRadioGroup();

    checked = false;

    if (radioGroup) {
      checked = radioGroup.value === props.value;
    }

    return <StyledFormControlLabel checked={checked} {...props} />;
  }

  MyFormControlLabel.propTypes = {
    /**
     * The value of the component.
     */
    value: PropTypes.any,
  };

  const navigateFunc = () => {
    navigate("/ongoing-orders");
  };

  const colors = () => {
    if (neworder?.title === "BLOOD") return "#B70303";
    if (neworder?.title === "OXYGEN") return "#0779F7";
    if (neworder?.title === "STOCKBANK") return "#066304";
    return "#DA621B";
  };

  const badgeColor = () => {
    if (neworder?.title === "BLOOD") return "#FDE0E0";
    if (neworder?.title === "OXYGEN") return "#E0EFFD";
    return "#F0FDE0";
  };

  return (
    <div style={{ width: "97%" }}>
      {status === 300 ? (
        <Row className='d-flex align-items-center mb-4'>
          <Col md={1}>
            <IoIosArrowDropleftCircle
              size={50}
              style={{ cursor: "pointer" }}
              onClick={() => navigate(-1)}
              color={colors()}
            />
          </Col>
          <Col md={8}>
            <p className={style.warning}>
              No Match Available. Please try again
            </p>
          </Col>
        </Row>
      ) : (
        <>
          <Row className='d-flex align-items-center mb-4'>
            <Col md={1}>
              <IoIosArrowDropleftCircle
                size={50}
                style={{ cursor: "pointer" }}
                onClick={() => navigate(-1)}
                color={colors()}
              />
            </Col>
            <Col md={8}>
              <p className={style.header}>BEST MATCH</p>
            </Col>
          </Row>
          {/* <ProcessCard
        id="1"
        name={vendor[0]?.name}
        address={vendor[0]?.address}
        dispatch_name={rider[0]?.dispatch_name}
        dispatchAddress=""
        // distance={vendor?.distance[0] * (65 / 60)}
        onClick={handleProcess1}
        color={colors()}
        borderColor={colors()}
        bgColor={colors()}
      /> */}
          {vendor || rider[1] ? (
            <ProcessCard
              id={"1"}
              name={vendor?.map((v) => v?.name)}
              address={vendor?.map((v) => v?.address)}
              dispatch_name={rider[1]?.dispatch_name}
              dispatchAddress=''
              distance={`${vendor?.map((v) =>
                parseInt(v?.distance * (65 / 60))
              )} Mins`}
              onClick={handleProcess2}
              color={colors()}
              borderColor={colors()}
              bgColor={colors()}
              loading={loading}
            />
          ) : (
            <p>No match at the moment</p>
          )}

          {/* <p className={style.header}>CUSTOM MATCH</p> */}

          {/* <RadioGroup name="use-radio-group" defaultValue="single">
        <MyFormControlLabel
          value="split"
          label="SPLIT DELIVERY"
          control={
            <Radio
              sx={{
                color: "gray",
                "&.Mui-checked": {
                  color: colors(),
                },
              }}
            />
          }
        />

        <SplitStockBank />
      </RadioGroup> */}
        </>
      )}

      {/* create MODAL */}
      <SuccessModal
        open={successModal}
        onClose={navigateFunc}
        bgcolor={colors()}
        width='55vw'
        height='60vh'
        instruction={
          <MessageInstruction
            heading={"SUCCESSFUL!!"}
            content={" Your order has been placed successfully"}
            button='COMPLETE'
            onClickBtn={navigateFunc}
            color={badgeColor()}
          />
        }
      />
    </div>
  );
};

export default ProcessNewStockbank;
