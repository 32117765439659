/* 
@example 
    
    export const ENDPOINT_ACTION_NAME = "/endpoint-url"
*/

// @labX URL
export const NEW_ORDER_URL = "/lite/v2/api/v3/placeorder";

export const EXISTING_URL = "/lite/v2/api/v3/process";

export const BOAT_URL = "/lite/v2/api/v3/boat/process";

// stockbank products
export const PRODUCTS_URL = "/lite/v2/api/v3/stockbank/product";
