import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { Formik } from "formik";
import { useEffect } from "react";
import Styles from "./Payment.module.scss";
import {
  closeOrder,
  complainOrder,
} from "../../redux/orders/actions/orders.actions";
import { useDispatch } from "react-redux";
import { Form } from "react-bootstrap";
import { SelectField } from "../Form/SelectField";

const paymentMode = [
  {
    value: "pos",
    label: "POS",
  },
  {
    value: "cash",
    label: "Cash on Delivery",
  },
  {
    value: "transfer",
    label: "Bank Transfer",
  },
  {
    value: "cheque",
    label: "Cheque",
  },
  {
    value: "boat",
    label: "BOAT",
  },
  {
    value: "hmo",
    label: "HMO",
  },
];

export default function PaymentDetails(props) {
  const [payments, setPayment] = React.useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    let options = paymentMode?.map((reason) => ({
      label: reason?.label,
      value: reason?.value,
    }));
    setPayment(options);
  }, []);

  return (
    <>
      {/* <h4 className="mb-3">Are you sure?</h4>
      <div className="d-flex justify-content-center mt-3">
        <button
          type="submit"
          className={` shadow-none ${Styles.actionBtn} ${Styles.confirm} `}
          style={{ background: props.btnColor }}
          onClick={() => props.onClickNo()}
        >
          No
        </button>

        <button
          type="submit"
          className={` shadow-none  ${Styles.actionBtn} ${Styles.reject}`}
          style={{ background: props.btnColor }}
          onClick={() => props.onClickYes()}
        >
          Yes
        </button>
      </div> */}
      <Formik
        initialValues={{
          title: "",
          order_id: "",
          details: "",
          payment: "",
        }}
        validate={(values) => {
          const errors = {};
          if (!values.details) {
            errors.details = "Kindly fill in payment details";
          }
          if (!values.payment) {
            errors.payment = "Payment mode is mandatory";
          }
          return errors;
        }}
        onSubmit={(values) => {
          values.title = props.title;
          values.order_id = props.order_id;
          values.payment = values.payment.value;

          dispatch(closeOrder(values));
          props.onClickYes();
          //   console.log(values);
        }}
      >
        {({
          handleChange,
          handleSubmit,
          isSubmitting,
          values,
          event,
          resetForm,
          setFieldValue,
          handleBlur,
          errors,
          touched,
        }) => (
          <>
            <h4 className="mb-3">
              {props.title === "blood" ? (
                `${props.qty} ${props.qty > 1 ? "pints" : "pint"} of ${
                  props.product
                } for ${props.name}`
              ) : props.title === "oxygen" ? (
                `${props.qty} quantities of ${props.product} for ${props.name}`
              ) : (
                <></>
              )}
            </h4>
            <Box
              component="form"
              sx={{
                "& .MuiTextField-root": { m: 1, minWidth: "50ch" },
              }}
              noValidate
              autoComplete="off"
            >
              <div className="d-flex  mb-1">
                <div className="w-100">
                  <SelectField
                    name="payment"
                    options={payments}
                    onChange={setFieldValue}
                    // value={values?.hospital}
                    placeholder="Add Extra Payment Option"
                    onBlur={handleBlur}
                  />
                </div>{" "}
              </div>
              {errors.payment && touched.payment ? (
                <p className="text-danger mb-3 mx-2">{errors.payment}</p>
              ) : null}

              <div>
                <Form.Control
                  as="textarea"
                  onChange={handleChange}
                  name="details"
                  placeholder="Payment Details"
                  style={{ height: "100px" }}
                />
                {errors.details && touched.details ? (
                  <p className="text-danger mt-1 mx-2">{errors.details}</p>
                ) : null}
              </div>

              <div className="d-flex justify-content-center mt-3">
                <button
                  type="submit"
                  className={` shadow-none ${Styles.actionBtn} ${Styles.confirm} `}
                  style={{ background: props.btnColor }}
                  onClick={() => props.onClickNo()}
                >
                  Cancel
                </button>

                <button
                  type="submit"
                  className={` shadow-none  ${Styles.actionBtn} ${Styles.reject}`}
                  style={{ background: props.btnColor }}
                  onClick={handleSubmit}
                >
                  Close
                </button>
              </div>
            </Box>
          </>
        )}
      </Formik>
    </>
  );
}
