import axiosClient from "../../../service/axios";
import {
  CHANGE_PASSWORD_URL,
  FETCH_USER_PROFILE_URL,
  USER_LOGIN_URL,
} from "./user.endpoints";

//@create login session
const tryLogin = async (loginDetails) => {
  try {
    return await axiosClient.post(USER_LOGIN_URL, loginDetails);
  } catch (error) {
    return error;
  }
};

// @change password
const changePassword = async (details) => {
  try {
    return await axiosClient.post(CHANGE_PASSWORD_URL, details);
  } catch (err) {
    return err;
  }
};

//@get user profile
const fetchUserProfile = async () => {
  try {
    return await axiosClient.post(FETCH_USER_PROFILE_URL);
  } catch (error) {
    return error;
  }
};

const UserService = {
  tryLogin,
  fetchUserProfile,
  changePassword,
};

export default UserService;
