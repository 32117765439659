import React, { useEffect, useState } from "react";
import style from "./Customer.module.scss";
import { Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { LinearLoader } from "../../components/Loader/LinearLoader";
import { ConfirmationModal } from "../../components/Modal/ConfirmationModal";
import Pagination from "../../components/paginationCopy/Pagination";
import { ridersList } from "../../redux/orders/actions/orders.actions";
import RiderMap from "./RiderMap";
import Search from "../../components/search";
import dateFormat from "../../helper/DateFormatter";

const Rider = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [ticketModal, setTicketModal] = useState(false);
  const [dataMatch, setDataMatch] = useState();
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");

  const { rider } = useSelector((state) => state.orderReducer);

  useEffect(() => {
    setLoading(true);
    dispatch(ridersList());
    setLoading(false);
  }, [dispatch]);

  const handleRow = (data) => {
    setTicketModal(true);
    // let complain;
    let match = rider?.find((t) => t?.id === data?.id);
    if (match) {
      setDataMatch(match);
    }
    // return complain;
  };

  // Search handler
  useEffect(() => {
    setData(
      rider?.filter((id) => {
        return Object.values(id).some((name) =>
          String(name).toLowerCase().includes(search.toLowerCase())
        );
      })
    );
  }, [rider, search]);

  const handleChange = (e) => {
    setSearch(e.target.value);
  };

  // pagination
  const [pageNumber, setPageNumber] = useState(0);
  const size = 10;
  const pagesVisited = pageNumber * size;
  const pageCount = Math.ceil(data?.length / size);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  return (
    <>
      <div className="d-flex justify-content-between m-3">
        <div></div>
        <Search placeholder="Find a dispatch" onChange={handleChange} />
      </div>
      <section style={{ maxWidth: "95%", margin: "auto" }}>
        {loading && <LinearLoader />}
        <Table borderless responsive className="mt-4">
          <thead>
            <tr className={style.TableHeadRow}>
              <th>Id</th>
              <th>
                <div className="d-flex align-items-center align-items-center">
                  Dispatch
                </div>
              </th>
              <th>
                <div className="d-flex align-items-center align-items-center">
                  BikeID
                </div>
              </th>
              <th>
                <div className="d-flex align-items-center align-items-center">
                  Status
                </div>
              </th>

              <th>
                <div className="d-flex align-items-center align-items-center">
                  Location
                </div>
              </th>
              <th>
                <div className="d-flex align-items-center align-items-center">
                  Last Updated
                </div>
              </th>

              <th>
                <div className="d-flex align-items-center align-items-center">
                  Battery (%)
                </div>
              </th>
            </tr>
          </thead>
          {data?.length > 0 ? (
            <tbody>
              {data
                ?.sort((a, b) => (a.id > b.id ? -1 : 1))
                ?.slice(pagesVisited, pagesVisited + size)
                ?.map((tick, index) => (
                  <tr
                    className={style.tableBodyRow}
                    key={index}
                    // onClick={() => handleRow(tick)}
                  >
                    <td>{pageNumber * size + index + 1}</td>
                    <td style={{ width: "20vw" }}>{tick?.dispatch_name}</td>
                    <td style={{ width: "15vw" }}>{tick?.dispatch_id}</td>
                    <td
                      style={{ width: "10vw" }}
                      className="d-flex align-items-center align-items-center"
                    >
                      {tick?.loca ? (
                        <div
                          style={{
                            background: "lightGreen",
                            padding: "5px",
                            color: "white",
                            borderRadius: "5px",
                            display: "flex",
                            justifyContent: "center",
                            width: "8vw",
                          }}
                        >
                          Available
                        </div>
                      ) : (
                        <div
                          style={{
                            background: "#ff726f",
                            padding: "5px",
                            color: "white",
                            borderRadius: "5px",
                            display: "flex",
                            justifyContent: "center",
                            width: "8vw",
                          }}
                        >
                          Unavailable
                        </div>
                      )}
                    </td>
                    <td>{tick?.State}</td>
                    <td>
                      {tick?.last_update_bike
                        ? `${dateFormat.DateYearMonthDay(
                            tick?.last_update_bike
                          )} | 
                          ${dateFormat.TimeOnly(tick?.last_update_bike)}`
                        : "Not Updated"}
                    </td>
                    <td>{tick?.ba3 ? tick?.ba3 : "0"}</td>
                    <td>
                      <button
                        className={style.track}
                        onClick={() => handleRow(tick)}
                      >
                        Track
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          ) : (
            <div className="d-flex justify-content-center align-items-center">
              No record found
            </div>
          )}
        </Table>
        {data?.length > size && (
          <Pagination pageCount={pageCount} onPageChange={changePage} />
        )}
      </section>

      {/* DIALOG MODAL */}
      <ConfirmationModal
        open={ticketModal}
        handleClose={() => setTicketModal(false)}
        closemodal={() => setTicketModal(false)}
        bgcolor="white"
        width="85vw"
        instruction={
          <RiderMap
            lat={parseFloat(dataMatch?.loca.split(",")[0])}
            long={parseFloat(dataMatch?.loca.split(",")[1])}
            riderName={dataMatch?.dispatch_name}
            riderLabel={dataMatch?.dispatch_name.split("")[0]}
          />
        }
      />
    </>
  );
};

export default Rider;
