import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { SelectField } from "../../../components/Form/SelectField";
import style from "./Detail.module.scss";
import { FaAmbulance } from "react-icons/fa";
import { IoMdCalendar, IoIosArrowDropleftCircle } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ProductCard from "../../../components/InfoCard/ProductCard";
import { hospitalList } from "../../../redux/orders/actions/orders.actions";
import { Formik } from "formik";
import { Button } from "@mui/material";
import { saveHospitalDetails } from "../../../redux/new-order/actions/new-orders.actions";
import { ConfirmationModal } from "../../../components/Modal/ConfirmationModal";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Detail = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [openModal, setopenModal] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [startTime, setStartTime] = useState(new Date());
  // const [value, onChange] = useState();

  useEffect(() => {
    if (!orderType?.cardText) {
      navigate("/place-order");
    }
  }, []);

  const { orderType } = useSelector((state) => state.newOrderReducer);

  const colors = () => {
    if (orderType?.cardText === "BLOOD") return "#B70303";
    if (orderType?.cardText === "OXYGEN") return "#0779F7";
    if (orderType?.cardText === "STOCKBANK") return "#066304";
    return "#DA621B";
  };

  const [hospitalDropDown, setHospitalDropDown] = useState();

  useEffect(() => {
    dispatch(hospitalList()).then((response) => {
      let options = response.data
        .sort((a, b) => a.label - b.label)
        .map((hosp) => ({
          label: `${hosp.Name} (${hosp.State}-${hosp.City})`,
          receivables: hosp.Receivables,
          value: hosp.Hospital_ID,
        }));
      setHospitalDropDown(options);
    });
  }, [dispatch]);

  return (
    <div className={style.container}>
      <Row>
        <Col md={1}>
          <IoIosArrowDropleftCircle
            size={50}
            color={colors()}
            style={{ cursor: "pointer" }}
            onClick={() => navigate(-1)}
          />
        </Col>
        <Col md={10}>
          <h3 className={style.header} style={{ color: colors() }}>
            hospital detail
          </h3>
          <h4 className={style.requestHeader1} style={{ color: colors() }}>
            select hospital
          </h4>
          <Formik
            initialValues={{
              hospital: "",
              hospitalId: "",
              receivables: "",
              requestType: "Emergency",
            }}
            validate={(values) => {
              const errors = {};
              if (!values.hospital) {
                errors.hospital = "This field is mandatory";
              }
              return errors;
            }}
            onSubmit={(values, actions) => {
              // values.hospital = values.hospital.value;

              // dispatch(saveOrderType(values, actions));
              dispatch(saveHospitalDetails(values, actions));
              if (orderType?.cardText === "BLOOD") {
                navigate("/place-order/blood-component");
              } else {
                navigate("/place-order/order-type");
              }
            }}
          >
            {({
              handleChange,
              handleSubmit,
              isSubmitting,
              values,
              resetForm,
              setFieldValue,
              handleBlur,
              errors,
              touched,
            }) => (
              <form>
                <div className={`${style.formInput} shadow-none`}>
                  <SelectField
                    className={style.input}
                    name='hospital'
                    options={hospitalDropDown}
                    onChange={setFieldValue}
                    value={values?.hospital}
                    placeholder='Find a hospital here'
                    onBlur={handleBlur}
                  />
                  {errors.hospital && touched.hospital ? (
                    <p className='text-danger mt-1 mx-2'>{errors.hospital}</p>
                  ) : null}
                </div>
                {orderType?.cardText === "stockbank".toUpperCase() ? (
                  <h4
                    className={style.requestHeader}
                    style={{ color: colors() }}
                  >
                    select products
                  </h4>
                ) : (
                  orderType?.cardText === "blood".toUpperCase() && (
                    <h4
                      className={style.requestHeader}
                      style={{ color: colors() }}
                    >
                      select request type
                    </h4>
                  )
                )}
                {orderType?.cardText === "stockbank".toUpperCase() ? (
                  ""
                ) : (
                  <div>
                    <Button
                      type='submit'
                      name='Emergency'
                      onClick={handleSubmit}
                      disabled={isSubmitting}
                      className={
                        orderType?.cardText === "oxygen".toUpperCase()
                          ? style.request2
                          : style.request
                      }
                      style={{ background: colors() }}
                    >
                      {orderType?.cardText === "blood".toUpperCase() && (
                        <FaAmbulance color='white' size={100} />
                      )}
                      <p className={style.text}>
                        {" "}
                        {orderType?.cardText === "oxygen".toUpperCase()
                          ? "NEXT"
                          : "EMERGENCY"}
                      </p>
                    </Button>
                    {orderType?.cardText === "blood".toUpperCase() && (
                      <div
                        name='Scheduled'
                        onClick={setopenModal}
                        style={{ background: colors() }}
                        className={style.request}
                      >
                        <IoMdCalendar color='white' size={100} />
                        <p className={style.text}>SCHEDULED</p>
                      </div>
                    )}
                  </div>
                )}
                {orderType?.cardText === "stockbank".toUpperCase() && (
                  <ProductCard />
                )}
              </form>
            )}
          </Formik>
        </Col>
      </Row>

      {/* DIALOG MODAL */}
      <ConfirmationModal
        open={openModal}
        bgcolor='background.paper'
        width='40vw'
        handleClose={() => setopenModal(false)}
        closemodal={() => setopenModal(false)}
        instruction={
          <div className={style.schedule}>
            <Formik
              initialValues={{
                hospital: "",
                hospitalId: "",
                date: "",
                time: "",
                requestType: "Scheduled",
              }}
              // validate={(values) => {
              //   const errors = {};
              //   if (!values.time || !values.hospital || !values.date) {
              //     errors.time = "All fields are mandatory";
              //   }
              //   return errors;
              // }}
              onSubmit={(values, actions) => {
                // values.hospital = values.hospital.value;
                values.date = startDate;
                values.time = startTime;

                dispatch(saveHospitalDetails(values, actions));
                if (orderType?.cardText === "BLOOD") {
                  navigate("/place-order/blood-component");
                } else {
                  navigate("/place-order/order-type");
                }
              }}
            >
              {({
                handleChange,
                handleSubmit,
                isSubmitting,
                values,
                resetForm,
                setFieldValue,
                handleBlur,
                errors,
                touched,
              }) => (
                <form>
                  <p className='text-danger mt-1 mx-2 mb-3'>
                    All fields are required
                  </p>

                  <div className={`${style.formInput} shadow-none`}>
                    <SelectField
                      className={style.input}
                      name='hospital'
                      options={hospitalDropDown}
                      onChange={setFieldValue}
                      value={values?.hospital}
                      placeholder='Find a hospital here'
                      onBlur={handleBlur}
                    />
                    {/* {errors.hospital && touched.hospital ? (
                      <p className="text-danger mt-1 mx-2">{errors.hospital}</p>
                    ) : null} */}
                  </div>
                  <div className={style.picker}>
                    <div className={style.inner}>
                      <p>Select a Date</p>
                      {/* <DayJS>2000-01-31T12:59-0500</DayJS> */}
                      <DatePicker
                        className={`${style.datePicker}`}
                        selected={startDate}
                        name='date'
                        dateFormat='dd/MM/yyyy'
                        value={startDate}
                        minDate={new Date()}
                        onChange={(date) => setStartDate(date)}
                      />
                      {/* {errors.date && touched.date ? (
                        <p className="text-danger mt-1 mx-2">{errors.date}</p>
                      ) : null} */}
                    </div>
                    <div className={style.inner}>
                      <p>Select Time</p>
                      {/* <TimePicker
                        onChange={onChange}
                        name="time"
                        value={value}
                      /> */}
                      <DatePicker
                        selected={startTime}
                        onChange={(time) => setStartTime(time)}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={60}
                        name='time'
                        timeCaption='Time'
                        dateFormat='h:mm aa'
                      />
                      {/* {errors.time && touched.time ? (
                        <p className="text-danger mt-1 mx-2">{errors.time}</p>
                      ) : null} */}
                    </div>
                  </div>

                  <Button
                    type='submit'
                    name='Scheduled'
                    onClick={handleSubmit}
                    disabled={isSubmitting || !values.hospital}
                    className={style.btn}
                    style={{
                      background: colors(),
                      color: "white",
                      marginTop: "20px",
                      fontWeight: 600,
                      fontSize: "18px",
                    }}
                  >
                    Proceed
                  </Button>
                </form>
              )}
            </Formik>
          </div>
        }
      />
    </div>
  );
};

export default Detail;
