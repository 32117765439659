import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Form, Button } from "react-bootstrap";
import { Formik } from "formik";
import FormContainer from "../../components/FormContainer";
import style from "./Login.module.scss";
import { SyncOutlined } from "@ant-design/icons";
import { FiLock } from "react-icons/fi";
import { changePassword, logOut } from "../../redux/user/actions/user.actions";
import { changePasswordSchema } from "../../components/validation/Schema";
import { useNavigate } from "react-router-dom";
import { delay } from "../../components/orders/nerve/Nerve";

const Settings = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [passwordType, setPasswordType] = useState("password");

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  let userDetails;
  if (localStorage.getItem("userToken")) {
    userDetails = JSON.parse(localStorage.getItem("userToken"));
  }

  if (!userDetails) {
    navigate("/");
  }

  return (
    <>
      {/* <h1 className="jumbotron text-center bg-danger text-white square p-3">
        Update Password
      </h1> */}
      <FormContainer>
        <div className={style.form}>
          <div className="mb-5 d-flex justify-content-center"></div>

          <Formik
            initialValues={{
              password: "",
              oPassword: "",
              cPassword: "",
            }}
            validationSchema={changePasswordSchema}
            onSubmit={(values, { setSubmitting }) => {
              //-cpassword is not needed
              const newDetails = {
                agent: userDetails?.id,
                oldPass: values.oPassword,
                newPass: values.password,
              };

              dispatch(changePassword(newDetails, setSubmitting));
              // // window.localStorage.removeItem("userToken");
              // delay(2000);
              // dispatch(logOut())
              // navigate("/");
            }}
          >
            {({
              handleChange,
              handleSubmit,
              values,
              isSubmitting,
              setFieldValue,
              handleBlur,
              errors,
              touched,
            }) => (
              <React.Fragment>
                <Form onSubmit={handleSubmit}>
                  <Form.Group
                    controlId="password"
                    className={`${style.group} mb-3`}
                  >
                    <Form.Control
                      type="password"
                      placeholder="Current Password"
                      className={`${style.input} rounded p-2`}
                      name="oPassword"
                      onChange={handleChange}
                      value={values.oPassword || ""}
                    ></Form.Control>
                    <FiLock className={style.icon} />
                  </Form.Group>

                  <Form.Group
                    controlId="password"
                    className={`${style.group} mb-3`}
                  >
                    <Form.Control
                      type="password"
                      placeholder="New Password"
                      className={`${style.input} rounded p-2`}
                      name="password"
                      onChange={handleChange}
                      value={values.password || ""}
                    ></Form.Control>
                    <FiLock className={style.icon} />
                  </Form.Group>
                  {errors.password ? (
                    <p className="text-danger small my-1">{errors.password}</p>
                  ) : null}

                  <Form.Group
                    controlId="password"
                    className={`${style.group} mb-3`}
                  >
                    <Form.Control
                      type="password"
                      placeholder="Confirm Password"
                      className={`${style.input} rounded p-2`}
                      name="cPassword"
                      onChange={handleChange}
                      value={values.cPassword || ""}
                    ></Form.Control>
                    <FiLock className={style.icon} />
                  </Form.Group>
                  {errors.cPassword ? (
                    <p className="text-danger small my-1">{errors.cPassword}</p>
                  ) : null}

                  <Button
                    type="submit"
                    variant="danger"
                    className={`${style.button} btn`}
                    disabled={
                      isSubmitting ||
                      !values.cPassword ||
                      !values.oPassword ||
                      !values.password
                    }
                  >
                    {isSubmitting ? <SyncOutlined spin /> : "Submit"}
                  </Button>
                </Form>
              </React.Fragment>
            )}
          </Formik>

          {/* <Row className='py-3'>
            <Col className='d-flex justify-content-start'>
              <Link className={style.forgot} to='/forgot'>
                Back
              </Link>
            </Col>
          </Row> */}
        </div>
      </FormContainer>
    </>
  );
};

export default Settings;
