import React, { useEffect, useState } from 'react';
import Styles from './index.module.scss';
import { Outlet, useLocation } from 'react-router-dom';
import swapTitle from './swapTitle';
import Sidenav from '../../components/sidenav/Sidenav';
import Navbar from '../../components/Navbar/Navbar';

const Layout = () => {
  const [navTitle, setnavTitle] = useState('Dashboard');
  const path = useLocation();
  const [handleHamburger, sethandleHamburger] = useState(false);

  // Set nav title each time there's a path change
  useEffect(() => {
    setnavTitle(path.pathname);
  }, [path]);

  return (
    <section className={Styles.adminDashboardCont}>
      <div className={Styles.sidebarContOuter}>
        <Sidenav active={Styles.active} />
      </div>

      <div className={Styles.mainContent}>
        <Navbar
          title={swapTitle[navTitle]}
          handleHamburger={handleHamburger}
          sethandleHamburger={sethandleHamburger}
        />
        {/* Component Children */}

        <Outlet />
      </div>
    </section>
  );
};

export default Layout;
