import React from "react";
import style from "./Process.module.scss";
import { SyncOutlined } from "@ant-design/icons";

const ProcessCard = ({
  id,
  name,
  address,
  dispatch_name,
  distance,
  dispatchAddress,
  time,
  best,
  onClick,
  bgColor,
  borderColor,
  color,
  loading,
}) => {
  return (
    <div className={style.container}>
      <div className={style.innerContainer}>
        {id && (
          <div className={style.badge} style={{ background: bgColor }}>
            {id}
          </div>
        )}
        <div className={style.content} style={{ width: "150px" }}>
          <div className="d-flex flex-column">
            <p className={style.header}>VENDOR NAME</p>
            <p className={style.text}>{name}</p>
          </div>
        </div>

        <div className={`${style.content} ${style.address}`}>
          <div className="d-flex flex-column">
            <p className={style.header}>VENDOR ADDRESS</p>
            <p className={style.text}>{address}</p>
          </div>
        </div>
        <div className={style.content}>
          <div className="d-flex flex-column">
            <p className={style.header}>DISPATCH NAME</p>
            <p className={style.text}>{dispatch_name}</p>
          </div>
        </div>
        {/* <div className={`${style.content} ${style.address}`}>
          <div className="d-flex flex-column">
            <p className={style.header}>DISPATCH ADDRESS</p>
            <p className={style.text}>{dispatchAddress}</p>
          </div>
        </div> */}
        <div className={style.content}>
          <div className="d-flex flex-column">
            <p className={style.header}>EST. TIME OF DELIVERY</p>
            <p className={style.text}>{distance}</p>
          </div>
        </div>
        <div className={style.content}>
          <div className="d-flex justify-content-center align-items-center">
            <button
              className={style.button}
              style={{ border: `1px solid ${borderColor}`, color: color }}
              onClick={() => onClick()}
            >
              {loading ? <SyncOutlined spin /> : "process"}
            </button>
          </div>
        </div>
      </div>

      {best && <div className={style.badge2}>{best}</div>}
    </div>
  );
};

export default ProcessCard;
