import React from 'react';

const Details = ({ icon, label, text }) => {
  return (
    <div
      style={{
        background: '#FEFEFE',
        boxShadow: '1px 2px 10px 5px rgba(112, 144, 176, 0.1)',
        borderRadius: '10px',
      }}
    >
      <div
        className="d-flex justify-content-between p-2 mb-2"
        style={{ fontSize: '18px', color: '#707174', fontWeight: '500' }}
      >
        <div className="d-flex">
          <p className="me-3" style={{ color: '#B70303' }}>
            {icon}
          </p>
          <p>{label}</p>
        </div>
        <p>{text}</p>
      </div>
    </div>
  );
};

export default Details;
