import React, { useEffect, useState } from "react";
import ProcessCard from "../../../components/orders/process/ProcessCard";
import style from "./Process.module.scss";
import { IoIosArrowDropleftCircle } from "react-icons/io";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import RadioGroup, { useRadioGroup } from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import ProcessCardSplit from "../../../components/orders/process/ProcessCardSplit";
import ProcessCardSingle from "../../../components/orders/process/ProcessCardSingle";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { labXOrders } from "../../../redux/orders/actions/orders.actions";
import {
  addNewOrder,
  placeOrder,
} from "../../../redux/new-order/actions/new-orders.actions";
import { SuccessModal } from "../../../components/Modal/SuccessModal";
import MessageInstruction from "../../../components/modalInstruction/MessageInstruction";
import dateFormat from "../../../helper/DateFormatter";
import { failedOrder } from "../../../components/NotificationToast";
import CircularLoader from "../../../components/Loader/CircularLoader";
import { Col, Row } from "react-bootstrap";

const ProcessNewOrder = () => {
  const [successModal, setSuccessModal] = useState(false);
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let userDetails;
  if (localStorage.getItem("userToken")) {
    userDetails = JSON.parse(localStorage.getItem("userToken"));
  }

  if (!userDetails) {
    navigate("/");
  }

  useEffect(() => {
    dispatch(labXOrders(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  const { placedOrder } = useSelector((state) => state.newOrderReducer);

  console.log("placeOrder", placedOrder);

  let precheck;
  if (localStorage.getItem("precheck"))
    precheck = JSON.parse(localStorage.getItem("precheck"));

  let vendor = precheck?.vendor;
  let rider = precheck?.Dispatch;
  let status = precheck?.status;

  let neworder;
  if (localStorage.getItem("newOrder"))
    neworder = JSON.parse(localStorage.getItem("newOrder"));

  const response1 = {
    order_by: neworder?.order_by,
    agentType: neworder?.agentType,
    agent: userDetails?.id,
    bloodbank: vendor && vendor[0]?.ref_id,
    dispatch_id: rider && rider[0]?.dispatch_id,
    blood: neworder?.blood,
    patient: neworder?.patient,
    bloodtype: neworder?.b_type,
    genotype: neworder?.genotype,
    crossmatch: neworder?.Cross_matching === "Yes" ? "true" : "false",
    genotyping: neworder?.genotyping === "Yes" ? "true" : "false",
    payment: neworder?.payment_type,
    usage: neworder?.usage,
    schedule_date: dateFormat.DateYearMonthDay(neworder?.schedule_date),
    schedule_time: dateFormat.TimeOnly(neworder?.schedule_tym),
    qty: neworder?.order_amt,
    temperature: neworder?.temperature === "Yes" ? "true" : "false",
    security: "",
    title: neworder?.title === "BLOOD" ? "Blood" : neworder?.title,
    hmo: neworder?.hmo,
    doctor: neworder?.doctor,
  };

  const response2 = {
    order_by: neworder?.order_by,
    agentType: neworder?.agentType,
    agent: userDetails?.id,
    bloodbank: vendor && vendor[1]?.ref_id,
    dispatch_id: rider && rider[1]?.dispatch_id,
    blood: neworder?.blood,
    patient: neworder?.patient,
    bloodtype: neworder?.b_type,
    genotype: neworder?.genotype,
    crossmatch: neworder?.Cross_matching === "Yes" ? "true" : "false",
    genotyping: neworder?.genotyping === "Yes" ? "true" : "false",
    payment: neworder?.payment_type,
    usage: neworder?.usage,
    schedule_date: dateFormat.DateYearMonthDay(neworder?.schedule_date),
    schedule_time: dateFormat.TimeOnly(neworder?.schedule_tym),
    qty: neworder?.order_amt,
    temperature: neworder?.temperature === "Yes" ? "true" : "false",
    security: "",
    title: neworder?.title === "BLOOD" ? "Blood" : neworder?.title,
    hmo: neworder?.hmo,
    doctor: neworder?.doctor,
  };

  const handleProcess1 = () => {
    setLoading(true);
    dispatch(placeOrder(response1));
    // console.log(response1);
    if (placedOrder?.status === 300) {
      failedOrder();
      setLoading(false);
    } else {
      setSuccessModal(true);
      dispatch(addNewOrder());
      setLoading(false);
    }
  };

  const handleProcess2 = () => {
    setLoading(true);
    dispatch(placeOrder(response2));
    if (placedOrder?.status === 300) {
      failedOrder();
      setLoading(false);
    } else {
      setSuccessModal(true);
      dispatch(addNewOrder());
      setLoading(false);
    }
  };

  let checked;
  const StyledFormControlLabel = styled((props) => (
    <FormControlLabel {...props} />
  ))(({ _theme, checked }) => ({
    ".MuiFormControlLabel-label": checked && {
      color: "black",
    },
  }));

  function MyFormControlLabel(props) {
    const radioGroup = useRadioGroup();

    checked = false;

    if (radioGroup) {
      checked = radioGroup.value === props?.value;
    }

    return <StyledFormControlLabel checked={checked} {...props} />;
  }

  MyFormControlLabel.propTypes = {
    /**
     * The value of the component.
     */
    value: PropTypes.any,
  };

  const navigateFunc = () => {
    navigate("/ongoing-orders");
  };

  const colors = () => {
    if (neworder?.title === "BLOOD") return "#B70303";
    if (neworder?.title === "OXYGEN") return "#0779F7";
    if (neworder?.title === "STOCKBANK") return "#066304";
    return "#DA621B";
  };

  const badgeColor = () => {
    if (neworder?.title === "BLOOD") return "#FDE0E0";
    if (neworder?.title === "OXYGEN") return "#E0EFFD";
    return "#F0FDE0";
  };

  useEffect(() => {
    if (!localStorage.getItem("userToken")) {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={{ width: "97%" }}>
      {loading && (
        <div className="d-flex justify-content-center align-items-center">
          <CircularLoader />
        </div>
      )}
      {status === 300 ? (
        <Row className="d-flex align-items-center mb-4">
          <Col md={1}>
            <IoIosArrowDropleftCircle
              size={50}
              style={{ cursor: "pointer" }}
              onClick={() => navigate(-1)}
              color={colors()}
            />
          </Col>
          <Col md={8}>
            <p className={style.warning}>
              No Match Available. Please try again
            </p>
          </Col>
        </Row>
      ) : (
        <>
          <Row className="d-flex align-items-center mb-4">
            <Col md={1}>
              <IoIosArrowDropleftCircle
                size={50}
                style={{ cursor: "pointer" }}
                onClick={() => navigate(-1)}
                color={colors()}
              />
            </Col>
            <Col md={8}>
              <p className={style.header}>BEST MATCH</p>
            </Col>
          </Row>

          <ProcessCard
            id="1"
            name={vendor[0]?.name}
            address={vendor[0]?.address}
            dispatch_name={rider[0]?.dispatch_name}
            dispatchAddress=""
            distance={`${parseInt(vendor[0]?.distance * (65 / 60))} Mins`}
            onClick={handleProcess1}
            color={colors()}
            borderColor={colors()}
            bgColor={colors()}
            loading={loading}
          />

          <ProcessCard
            id={"2"}
            name={vendor[1]?.name}
            address={vendor[1]?.address}
            dispatch_name={rider[1]?.dispatch_name}
            dispatchAddress=""
            distance={`${parseInt(vendor[1]?.distance * (65 / 60))} Mins`}
            onClick={handleProcess2}
            color={colors()}
            borderColor={colors()}
            bgColor={colors()}
            loading={loading}
          />

          <p className={style.header}>CUSTOM MATCH</p>

          <RadioGroup name="use-radio-group" defaultValue="single">
            <MyFormControlLabel
              value="single"
              label="SINGLE DELIVERY"
              control={
                <Radio
                  sx={{
                    color: "gray",
                    "&.Mui-checked": {
                      color: colors(),
                    },
                  }}
                />
              }
            />
            <ProcessCardSingle
              order_by={neworder?.order_by}
              agent={userDetails?.id}
              agentType={neworder?.agentType}
              blood={neworder?.blood}
              hmo={neworder?.hmo}
              doctor={neworder?.doctor}
              bloodtype={neworder?.b_type}
              genotype={neworder?.genotype}
              patient={neworder?.patient}
              crossmatch={neworder?.Cross_matching === "Yes" ? "true" : "false"}
              genotyping={neworder?.genotyping === "Yes" ? "true" : "false"}
              payment={neworder?.payment_type}
              usage={neworder?.usage}
              schedule_date={dateFormat.DateYearMonthDay(
                neworder?.schedule_date
              )}
              schedule_time={dateFormat.TimeOnly(neworder?.schedule_tym)}
              qty={neworder?.order_amt}
              temperature={neworder?.temperature === "Yes" ? "true" : "false"}
              security={""}
              title={neworder?.title === "BLOOD" ? "Blood" : neworder?.title}
              color={colors()}
              border={colors()}
              textColor={colors()}
              vendor={vendor}
              rider={rider}
            />
            {neworder?.order_amt > 1 && (
              <>
                <MyFormControlLabel
                  value="split"
                  label="SPLIT DELIVERY"
                  control={
                    <Radio
                      sx={{
                        color: "gray",
                        "&.Mui-checked": {
                          color: colors(),
                        },
                      }}
                    />
                  }
                />

                <ProcessCardSplit
                  color={colors()}
                  border={colors()}
                  textColor={colors()}
                  order_by={neworder?.order_by}
                  patient={neworder?.patient}
                  blood={neworder?.blood}
                  agent={userDetails?.id}
                  agentType={neworder?.agentType}
                  hmo={neworder?.hmo}
                  doctor={neworder?.doctor}
                  bloodtype={neworder?.b_type}
                  genotype={neworder?.genotype}
                  crossmatch={
                    neworder?.Cross_matching === "Yes"
                      ? "true"
                      : neworder?.Cross_matching
                  }
                  genotyping={neworder?.genotyping === "Yes" ? "true" : "false"}
                  payment={neworder?.payment_type}
                  usage={neworder?.usage}
                  schedule_date={dateFormat.DateYearMonthDay(
                    neworder?.schedule_date
                  )}
                  schedule_time={dateFormat.TimeOnly(neworder?.schedule_tym)}
                  qty={neworder?.order_amt}
                  temperature={""}
                  security={""}
                  title={
                    neworder?.title === "BLOOD" ? "Blood" : neworder?.title
                  }
                  vendor={vendor}
                  rider={rider}
                />
              </>
            )}
          </RadioGroup>
        </>
      )}

      {/* create MODAL */}
      <SuccessModal
        open={successModal}
        onClose={navigateFunc}
        bgcolor={colors()}
        width="55vw"
        height="60vh"
        instruction={
          <MessageInstruction
            heading={"SUCCESSFUL!!"}
            content={" Your order has been placed successfully"}
            button="COMPLETE"
            onClickBtn={navigateFunc}
            color={badgeColor()}
          />
        }
      />
    </div>
  );
};

export default ProcessNewOrder;
