import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import style from "./QuotationDetails.module.css";
import { getSingleQuotation } from "../../redux/admin/actions/admin.actions";
import QuotationModal from "./QuotationModal.jsx";

const QuotationDetails = () => {
  const [showModal, setShowModal] = useState(false);
  const { id } = useParams();
  const [updatedProducts, setUpdatedProducts] = useState([]);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const { singleQuotation } = useSelector((state) => state.adminReducer);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getSingleQuotation(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (singleQuotation) {
      const updatedData = singleQuotation?.items?.map((product) => ({
        ...product,
        price: product.price || 0,
      }));
      setUpdatedProducts(updatedData);
    }
  }, [singleQuotation]);

  const handlePriceChange = (productName, newPrice) => {
    const updatedData = updatedProducts.map((updatedProduct) => {
      if (updatedProduct.name === productName) {
        return { ...updatedProduct, price: newPrice };
      }
      return updatedProduct;
    });
    setUpdatedProducts(updatedData);
  };
  const handleProcess = () => {
    // console.log("Updated Products", updatedProducts);
    setShowModal(true);
  };

  useEffect(() => {
    // Check if all prices are greater than zero
    const allPricesValid = updatedProducts?.every(
      (product) => product.price > 0
    );
    setButtonDisabled(!allPricesValid);
  }, [updatedProducts]);

  return (
    <div>
      {showModal && (
        <QuotationModal
          setModal={setShowModal}
          updatedProducts={updatedProducts}
          id={id}
        />
      )}
      <p className={style.header}>
        Quotation for {singleQuotation?.data?.name}
      </p>
      <table className={style.table}>
        <thead>
          <tr className={style.headerrow}>
            <th className={style.headeritem}>Product</th>
            <th className={style.headeritem}>Quantity</th>
            <th className={style.headeritem}>Price</th>
          </tr>
        </thead>
        <tbody>
          {singleQuotation?.items?.map((product, index) => (
            <tr key={index} className={style.container}>
              <td className={style.card}>
                <p className={style.cardText}>{product.name}</p>
              </td>
              <td className={style.card}>
                <p className={style.cardText}>{product.qty}</p>
              </td>
              <td className={style.card}>
                <p className={style.cardText}>
                  {/* <input
                    type="text"
                    value={product.price || ""}
                    onChange={(e) => {
                      const newPrice = e.target.value;
                      console.log("New Price:", newPrice); // Add this line for debugging
                      handlePriceChange(product.name, newPrice);
                    }}
                    className={style.inputField}
                  /> */}
                  <input
                    type="text"
                    defaultValue={product.price}
                    onChange={(e) => {
                      const newPrice = e.target.value;
                      handlePriceChange(product.name, newPrice);
                    }}
                    className={style.inputField}
                  />
                </p>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {console.log(buttonDisabled)}
      <div className={style.centeredButtonContainer}>
        <button
          className={`${style.button} ${
            buttonDisabled ? style.disabledButton : style.buttonclick
          }`}
          onClick={handleProcess}
          disabled={buttonDisabled}
        >
          <p
            className={`${style.buttonText} ${
              buttonDisabled ? "" : style.buttonTextdisable
            }`}
            // className={style.buttonText}
          >
            Continue
          </p>
        </button>

        {/* <div className={style.button} onClick={handleProcess}>
          <p className={style.buttonText}>Continue</p>
        </div> */}
      </div>
    </div>
  );
};
export default QuotationDetails;
