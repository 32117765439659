import React from "react";
import {
  BsArrowsExpand,
  BsBank2,
  BsCreditCardFill,
  BsDropletFill,
  BsFilePost,
} from "react-icons/bs";
import {
  FaMoneyBill,
  FaCarSide,
  FaHandshake,
  FaClinicMedical,
  FaHandHoldingMedical,
} from "react-icons/fa";
import { TbCurrencyNaira, TbVaccineBottle } from "react-icons/tb";
import { RiEBike2Line } from "react-icons/ri";
import style from "./Card.module.scss";

const Card = ({ onClick, icon, label, bgColor }) => {
  const background = () => {
    if (label === "POS") return "#292929";
    if (label === "HMO") return "green";
    if (label === "CASH ON DELIVERY") return "#0958CE";
    if (label === "CHEQUE") return "#AC1B1B";
    if (label === "REFILL") return "lightBlue";
    if (label === "EXCHANGE") return "lightGreen";
    if (label === "DROP OFF") return "lightRed";
    if (label === "DISPATCH") return "#B70303";
    if (label === "VENDOR") return "blue";
    if (label === "PAYMENT") return "green";
    if (label === "QUANTITY") return "#b9f0d1";
    if (label === "COMPONENT") return "#de6868";
    if (label === "RECEIVABLES") return "#de6868";
  };

  const icons = () => {
    if (label === "POS") return <BsFilePost size={70} color='#fff' />;
    if (label === "DISPATCH") return <RiEBike2Line size={70} color='#fff' />;
    if (label === "PAYMENT") return <TbCurrencyNaira size={70} color='#fff' />;
    if (label === "COMPONENT") return <BsDropletFill size={70} color='#fff' />;
    if (label === "QUANTITY") return <BsArrowsExpand size={70} color='#fff' />;
    if (label === "VENDOR")
      return <FaHandHoldingMedical size={70} color='#fff' />;
    if (label === "CASH ON DELIVERY")
      return <FaMoneyBill size={70} color='#fff' />;
    if (label === "CHEQUE") return <BsBank2 size={70} color='#fff' />;
    if (label === "RECEIVABLES")
      return <BsCreditCardFill size={70} color='#fff' />;
    if (label === "HMO") return <FaClinicMedical size={70} color='#fff' />;
    if (label === "REFILL")
      return <TbVaccineBottle size={70} color='#0779F7' />;
    if (label === "EXCHANGE")
      return (
        <FaHandshake
          size={70}
          color='green'
          style={{ transform: "rotate(-45deg)" }}
        />
      );
    if (label === "DROP OFF") return <FaCarSide size={70} color='#B70303' />;
  };
  return (
    <div>
      <div
        className='d-flex text-center flex-column'
        style={{ textDecoration: "none", cursor: "pointer" }}
        onClick={() => onClick()}
      >
        <div
          className={style.card}
          style={{ backgroundColor: (bgColor = background()) }}
        >
          {" "}
          {(icon = icons())}
        </div>
        <p className={style.label}>{label}</p>
      </div>
    </div>
  );
};

export default Card;
