import React, { useState } from "react";
import style from "./Orders.module.scss";
import OverdueOrder from "./OverdueOrder";
import OngoingOrder from "./OngoingOrder";
import { Link } from "react-router-dom";

const Orders = ({ dataOverdue, dataOngoing }) => {
  const [activeTab, setactiveTab] = useState("overdue");

  return (
    <div className={style.orderContainer}>
      <div className={style.tab}>
        <div
          onClick={() => setactiveTab("overdue")}
          className={
            activeTab === "overdue" ? `${style.btnActive}` : `${style.btn}`
          }
        >
          Overdue Orders
        </div>

        <div
          onClick={() => setactiveTab("ongoing")}
          className={
            activeTab === "ongoing" ? `${style.btnActive}` : `${style.btn}`
          }
        >
          Ongoing Orders
        </div>

        <Link to="new-orders" className={style.link}>
          View All
        </Link>
      </div>

      {activeTab === "overdue" && (
        <div className={style.scroll}>
          {dataOverdue?.length > 0 ? (
            dataOverdue?.map((item) => (
              <OverdueOrder key={item.order_id} {...item} />
            ))
          ) : (
            <div className="d-flex justify-content-center align-items-center">
              No Record Found
            </div>
          )}
        </div>
      )}
      {activeTab === "ongoing" && (
        <div className={style.scroll}>
          {dataOngoing?.length > 0 ? (
            dataOngoing?.map((item) => (
              <OngoingOrder key={item.order_id} {...item} />
            ))
          ) : (
            <div className="d-flex justify-content-center align-items-center">
              No Record Found
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Orders;
