import React from "react";
import Place from "../../components/InfoCard/Place";
import style from "./PlaceOrder.module.scss";

import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { saveOrderType } from "../../redux/new-order/actions/new-orders.actions";
import { productType } from "../../data";

const PlaceOrder = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const processHandler = (product) => {
    if (product.cardText === "OTHERS") {
      navigate("/not-available");
      return;
      // }
      //  else if (product.cardText === "STOCKBANK") {
      //   dispatch(saveOrderType(product));
      //   navigate("/place-order/select-products");
      //   return;
    } else {
      dispatch(saveOrderType(product));
      navigate("/place-order/select-agent");
      return;
    }
    // else {
    //   dispatch(saveOrderType(product));
    //   navigate("/place-order/hospital-detail");
    // }
  };

  return (
    <div className={style.container}>
      {productType.map((product) => (
        <Place
          {...product}
          key={product.id}
          onClick={() => processHandler(product)}
        />
      ))}
    </div>
  );
};

export default PlaceOrder;
