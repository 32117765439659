import React from 'react';
import style from './Process.module.scss';

const Process = () => {
  return (
    <div className={style.mainContainer}>
      <h3>Process Flow</h3>
      <div className={style.container}>
        <div className={style.circles}>
          <div>Take Orders</div>
        </div>
        <div className={style.connect} />
        <div className={style.circles}>
          <div>Process Orders</div>
        </div>
        <div className={style.connect} />
        <div className={style.circles}>
          <div>Pass to dispatch app</div>
        </div>
        <div className={style.connect} />
        <div className={style.circles}>
          <div>Alert the hospital</div>
        </div>
        <div className={style.connect} />
        <div className={style.circles}>
          <div>Update Hospital</div>
        </div>
        <div className={style.connect} />
        <div className={style.circles}>
          <div>Complete Orders</div>
        </div>
      </div>
    </div>
  );
};

export default Process;
