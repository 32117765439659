import React from "react";
import { Col, Row } from "react-bootstrap";
import { IoIosArrowDropleftCircle } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { MdLock } from "react-icons/md";
import style from "./Other.module.scss";

const OtherScreen = () => {
  const navigate = useNavigate();

  const handleYes = () => {
    navigate("/");
  };

  return (
    <div style={{ width: "97%" }}>
      <Row>
        <Col md={1}>
          <IoIosArrowDropleftCircle
            size={50}
            style={{ cursor: "pointer" }}
            onClick={() => navigate(-1)}
            color="#DA621B"
          />
        </Col>
        <Col md={10}>
          <div className={style.form}>
            <div className={style.outerContainer}>
              <div className={style.iconContainer}>
                <MdLock color="black" size={50} />
              </div>
              <div className={style.container}>
                <p className={style.size}>Watch this space!...</p>
              </div>
              <p className={style.question}>
                We'll let you know when more products are added!
              </p>
              <div className={style.button} onClick={handleYes}>
                Take Me Home
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default OtherScreen;
