import {
  invalidCredentialToast,
  loginSuccessToast,
  logoutSuccessToast,
  somethingWentWrongToast,
  passwordChangedSuccessToast,
  passwordChangedWarningToast,
} from "../../../components/NotificationToast";
import { LOAD_USER_PROFILE, LOGIN_SUCCESS } from "../../types";
import UserService from "../services/user.service";

//@login
export const login = (loginDetails, setSubmitting) => async (dispatch) => {
  try {
    const response = await UserService.tryLogin(loginDetails);
    // user not found
    if (
      response?.data === "failed" ||
      response?.data === "Incorrect Email / Password!" ||
      response?.response?.status === 404 ||
      response?.response?.status === 401
    ) {
      invalidCredentialToast();
      setSubmitting(false);
      return;
    }

    // If user found
    if (response?.status === 200 || response?.status === true) {
      // update state
      dispatch({
        type: LOGIN_SUCCESS,
        payload: response?.data,
      });

      // Store token
      localStorage.setItem("userToken", JSON.stringify(response?.data));

      // update ui
      if (localStorage.getItem("userToken")) {
        loginSuccessToast();

        setTimeout(() => {
          window.location.href = "/";
        }, 2000);
      }

      // stop submitting form
      setSubmitting(false);
    }

    setSubmitting(false);

    return response;
  } catch (error) {
    somethingWentWrongToast();
    setSubmitting(false);
    return error;
  }
};

//@load user profile
export const loadUserProfile = () => async (dispatch) => {
  try {
    const response = await UserService.fetchUserProfile();
    dispatch({
      type: LOAD_USER_PROFILE,
      payload: response.data.data,
    });
    return response;
  } catch (error) {
    somethingWentWrongToast();
  }
};

//@logout
export const logOut = () => (_dispatch) => {
  localStorage.removeItem("userToken");

  // _dispatch action to remove current user

  logoutSuccessToast();

  setTimeout(() => {
    window.location.href = "/login";
  }, 2000);
};

//@change password
export const changePassword = (details, setSubmitting) => async (dispatch) => {
  try {
    const response = await UserService.changePassword(details);
    console.log("resss::", response?.data?.description);
    if (
      response.status === 200 &&
      response?.data?.description === "Password has been Updated"
    ) {
      passwordChangedSuccessToast();
      setSubmitting(false);
      setTimeout(() => {
        window.location.href = "/login";
      }, 3000);
    } else if (response?.data?.description === "please provide old password") {
      passwordChangedWarningToast();
      setSubmitting(false);
    }
    return response;
  } catch (error) {
    somethingWentWrongToast();
    setSubmitting(false);

    return error;
  }
};
