import React, { useEffect, useState } from "react";
import Map from "../../components/newMap/Map";
import { BsInfoCircle } from "react-icons/bs";
import style from "./Track.module.scss";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  completedOrders,
  overdueOrders,
} from "../../redux/orders/actions/orders.actions";
import axios from "axios";

const Track = (props) => {
  const { id } = useParams();
  const [loading, setloading] = useState(true);
  const [rider, setRider] = useState();
  const [newRider, setNewRider] = useState();
  const [riderAddress, setRiderAddress] = useState();
  const [riderName, setRiderName] = useState();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  let userDetails;
  if (localStorage.getItem("userToken")) {
    userDetails = JSON.parse(localStorage.getItem("userToken"));
  }

  if (!userDetails) {
    navigate("/");
  }

  const baseUrl = "https://lab.lifebank.ke/labx/api/v1/dispatch/location/";

  const { completed, track } = useSelector((state) => state.orderReducer);

  useEffect(() => {
    dispatch(overdueOrders(id))
      .catch(() => {
        setloading(false);
      })
      .then(() => {
        dispatch(completedOrders(id));
      })
      .then(() => {
        const fetchData = async () => {
          await delay(3000);
          axios
            .get(
              `https://maps.googleapis.com/maps/api/geocode/json?latlng=${
                track?.loca.split(",")[0]
              },${track?.loca.split(",")[1]}&key=${
                process.env.REACT_APP_GOOGLE_MAPS_API_KEY
              }`
            )
            .then((response) => {
              setRiderAddress(response?.data);
            });
        };
        fetchData();
      })

      .catch(() => {
        setloading(false);
      });
  }, [dispatch, id, track?.loca]);

  let newAddress = riderAddress?.results?.[0]?.formatted_address;

  const getOrderFromID = () => {
    let order;

    let match = completed?.find((o) => o?.order_id === id);
    if (match) {
      order = match;
    }

    return order;
  };

  let detail = getOrderFromID();

  let dispatchId = detail?.dispatch;

  function delay(ms) {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  }

  useEffect(() => {
    const fetchData = () => {
      axios.get(baseUrl + dispatchId).then((response) => {
        setRider(response?.data);
      });
    };
    fetchData();
  }, [dispatchId]);

  useEffect(() => {
    const fetchLocation = async () => {
      await delay(3000);
      let result = rider?.map(({ loca }) => loca).toString();
      setNewRider(result);
    };

    fetchLocation();
  }, [rider]);

  // let tracker;
  // if (localStorage.getItem("trackOrder")) {
  //   tracker = JSON.parse(localStorage.getItem("trackOrder"));
  // }

  useEffect(() => {
    const getBikeName = async () => {
      await delay(3000);
      let match = rider?.find((o) => o?.dispatch_id === track?.dispatch);

      setRiderName(match);
    };
    getBikeName();
  }, [rider, track?.dispatch]);

  return (
    <div>
      <div className="d-flex justify-content-between ps-3 pe-3">
        <div>
          <div className={style.orderId}>
            <BsInfoCircle size={25} className="me-2" />
            ORDER ID: <span className={style.text}> {detail?.order_id} </span>
          </div>
        </div>
        <div className={style.container2}>
          {/* <Search placeholder="Track Another Order" /> */}
        </div>
      </div>
      <Map
        className={style.map}
        orderDestination={track?.geo_hospital}
        orderOrigin={track?.geo_vendor}
        vendorName={track?.name_vendor}
        dispatch={track?.dispatch}
        arrived={track?.name_hospital}
        delivered={track?.deliever_stamp}
        riderAddress={newAddress}
        title={track?.Cross_matching}
        riderName={riderName?.dispatch_name}
      />
    </div>
  );
};

export default Track;
