import React from "react";
import { Col, Row } from "react-bootstrap";
import { IoIosArrowDropleftCircle } from "react-icons/io";

import style from "./OrderType.module.scss";
import { useNavigate } from "react-router-dom";
import { oxygenOrder } from "../../../../data";
import { saveOxygenType } from "../../../../redux/new-order/actions/new-orders.actions";
import { useDispatch, useSelector } from "react-redux";
import Card from "../../../../components/paymentCard/Card";
import swal from "sweetalert";

const OrderType = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  let userDetails;
  if (localStorage.getItem("userToken")) {
    userDetails = JSON.parse(localStorage.getItem("userToken"));
  }

  if (!userDetails) {
    navigate("/");
  }

  const processHandler = (type) => {
    if (type === "REFILL") {
      swal("Please note that refills could take between 3-5 days");
    } else if (type === "EXCHANGE") {
      swal(
        "Your empty cylinder will be exchanged for a full LifeBank cylinder"
      );
    } else if (type === "DROP OFF") {
      swal("This means you do not have a cylinder and require one of ours.");
    }
    // dispatch(saveOrderType(payment));
    dispatch(saveOxygenType(type));
    navigate("/place-order/size");
  };

  const { orderType } = useSelector((state) => state.newOrderReducer);

  const colors = () => {
    if (orderType?.cardText === "BLOOD") return "#B70303";
    if (orderType?.cardText === "OXYGEN") return "#0779F7";
    if (orderType?.cardText === "STOCKBANK") return "#066304";
    return "#DA621B";
  };

  return (
    <div style={{ width: "97%" }}>
      <Row>
        <Col md={1}>
          <IoIosArrowDropleftCircle
            size={50}
            style={{ cursor: "pointer" }}
            onClick={() => navigate(-1)}
            color={colors()}
          />
        </Col>
        <Col md={10}>
          <h3 className={style.header} style={{ color: colors() }}>
            SELECT ORDER TYPE
          </h3>
          <div className={style.cards}>
            {oxygenOrder.map((type) => (
              <Card {...type} onClick={() => processHandler(type.label)} />
            ))}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default OrderType;
