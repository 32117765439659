import { combineReducers } from "redux";
// import { combineReducers } from '@reduxjs/toolkit';
import userReducer from "./user/reducers/user.reducers";
import orderReducer from "./orders/reducers/orders.reducers";
import newOrderReducer from "./new-order/reducers/new-orders.reducers";
import adminReducer from "./admin/reducers/admin.reducers";
import chatReducer from "./chat/reducers/chat.reducers";

const rootReducer = combineReducers({
  userReducer,
  orderReducer,
  newOrderReducer,
  adminReducer,
  chatReducer,
});

export default rootReducer;
