import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import style from "./Patient.module.scss";

import { IoIosArrowDropleftCircle } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { savePatientName } from "../../../redux/new-order/actions/new-orders.actions";
import { Formik } from "formik";
import { SyncOutlined } from "@ant-design/icons";

const Patient = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { orderType } = useSelector((state) => state.newOrderReducer);

  const colors = () => {
    if (orderType?.cardText === "BLOOD") return "#B70303";
    if (orderType?.cardText === "OXYGEN") return "#0779F7";
    if (orderType?.cardText === "STOCKBANK") return "#066304";
    return "#DA621B";
  };

  return (
    <div style={{ width: "97%" }}>
      <Row>
        <Col md={1}>
          <IoIosArrowDropleftCircle
            size={50}
            style={{ cursor: "pointer" }}
            onClick={() => navigate(-1)}
            color={colors()}
          />
        </Col>
        <Col md={10}>
          <h3 className={style.header} style={{ color: colors() }}>
            PATIENT NAME
          </h3>

          <Formik
            initialValues={{ patient: "" }}
            // validate={(values) => {
            //   const errors = {};
            //   if (!values.patient) {
            //     errors.patient = "Patient's name is mandatory";
            //   }
            //   return errors;
            // }}
            onSubmit={(values, { setSubmitting }) => {
              dispatch(savePatientName(values, setSubmitting));

              navigate("/reason");
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleSubmit,
              isSubmitting,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Form.Group
                  controlId="patient"
                  className={`${style.group} mb-3`}
                >
                  <p className={style.optional}>(optional)</p>
                  <Form.Control
                    type="text"
                    placeholder="Enter Patient's Name"
                    name="patient"
                    onChange={handleChange}
                    className={`${style.input} rounded p-2`}
                  ></Form.Control>
                  {/* {errors.patient && touched.patient ? (
                    <p className="text-danger mt-1 mx-2">{errors.patient}</p>
                  ) : null} */}
                </Form.Group>

                <button
                  type="submit"
                  variant="primary"
                  className={`${style.button} btn`}
                  style={{
                    background: colors(),
                    border: `1px solid ${colors()}`,
                  }}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? <SyncOutlined spin /> : "Proceed"}
                </button>
              </Form>
            )}
          </Formik>
        </Col>
      </Row>
    </div>
  );
};

export default Patient;
