import React from "react";
import styled from "./Instruction.module.scss";

const OxygenInstruction = (props) => {
  return (
    <div>
      <div className='d-flex flex-column justify-content-center'>
        <div className='d-flex justify-content-between mt-3 mb-3'>
          <p className={styled.header} style={{ color: props.headerColor }}>
            ORDER DETAILS{" | "}LBO
            <span>{props.orderId}</span>
          </p>

          {/* <p
            className={styled.name}
            style={{
              background: props.categoryBg,
              color: props.categoryColor,
              textTransform: "uppercase",
            }}
          >
            {props.category ? props.category : "oxygen"}
          </p> */}
        </div>

        <div className={styled.modalContainer1}>
          <p className={styled.title}>product name</p>
          <div className={styled.line}></div>
          <p className={styled.content}>{props?.product}</p>
        </div>
        {/* <div className={styled.modalContainer1}>
          <p className={styled.title}>size</p>
          <div className={styled.line}></div>
          <p className={styled.content}>{props.size}</p>
        </div> */}
        <div className={styled.modalContainer1}>
          <p className={styled.title}>quantity</p>
          <div className={styled.line}></div>
          <p className={styled.content}>{props.quantity}</p>
        </div>
        {props.payment && (
          <div className={styled.modalContainer1}>
            <p className={styled.title}>Payment Type</p>
            <div className={styled.line}></div>
            <p className={styled.content}>{props.payment}</p>
          </div>
        )}
        <div className={styled.modalContainer1}>
          <p className={styled.title}>hospital name</p>
          <div className={styled.line}></div>
          <p className={styled.content}>{props.hospital}</p>
        </div>

        <div className={styled.modalContainer1}>
          <p className={styled.title}>hospital address</p>
          <div className={styled.line}></div>
          <p className={styled.content}>{props.address}</p>
        </div>
        <div className={styled.modalContainer1}>
          <p className={styled.title}>hospital phone number</p>
          <div className={styled.line}></div>
          <p className={styled.content}>{props.phone}</p>
        </div>
        <div className={styled.modalContainer1}>
          <p className={styled.title}>order time</p>
          <div className={styled.line}></div>
          <p className={styled.content}>{props.time}</p>
        </div>
        <div className='d-flex justify-content-center'>
          <button
            className={` shadow-none ${styled.actionBtn} ${styled.reject}`}
            style={{ background: props.btnColor }}
            onClick={() => props.onClickYes()}
          >
            {props.button2}
          </button>
        </div>
      </div>
    </div>
  );
};

export default OxygenInstruction;
