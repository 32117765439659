import React from "react";
import styles from "./Info.module.scss";

import { BsStarFill, BsBoxSeam, BsTabletLandscape } from "react-icons/bs";
import { MdWaterDrop, MdCoronavirus } from "react-icons/md";
import { RiFridgeFill } from "react-icons/ri";
import { GiLungs } from "react-icons/gi";
import {
  FaPlusSquare,
  FaMeteor,
  FaHeart,
  FaUserMd,
  FaUserNurse,
  FaToolbox,
  FaBone,
  FaStethoscope,
  FaBaby,
  FaBed,
} from "react-icons/fa";

const Blood = ({
  bgColor,
  color,
  bloodComponent,
  icon,
  onClick,
  bloodGroup,
  genotype,
  reason,
}) => {
  const icons = () => {
    if (bloodComponent === "PLATELET") return <BsStarFill size={90} />;
    if (bloodComponent === "CRYOPRECIPITATE") return <MdWaterDrop size={90} />;
    if (bloodComponent === "WHOLE BLOOD") return <BsStarFill size={90} />;
    if (bloodComponent === "FRESH FROZEN PLASMA")
      return <RiFridgeFill size={90} />;
    if (bloodComponent === "PACKED RED BLOOD CELLS")
      return <BsBoxSeam size={90} />;
    if (reason === "GENERAL SURGERY") return <FaPlusSquare size={90} />;
    if (reason === "BLOOD LOSS FROM ACCIDENT") return <FaMeteor size={90} />;
    if (reason === "WHOLE BLOOD") return <FaHeart size={90} />;
    if (reason === "INTENSIVE CARE TREATMENT") return <FaUserMd size={90} />;
    if (reason === "TREATMENT OF NEWBORN BABIES & CHiLDREN")
      return <FaBaby size={90} />;
    if (reason === "ORTHOPAEDIC") return <FaBone size={90} />;
    if (reason === "RENAL SURGERY") return <FaStethoscope size={90} />;
    if (reason === "POST PARTUM HEMORRAGE") return <FaUserNurse size={90} />;
    if (reason === "PNEUMONIA") return <GiLungs size={90} />;
    if (reason === "COVID") return <MdCoronavirus size={90} />;
    if (reason === "ASTHMA") return <FaUserMd size={90} />;
    if (reason === "UPPER RESPITORY TRACT INFECTION")
      return <FaBed size={90} />;
    if (reason === "LOWER RESPITORY TRACT INFECTION")
      return <FaBed size={90} />;
    if (reason === "OTHERS") return <FaToolbox size={90} />;
    return <BsTabletLandscape size={90} />;
  };

  const background = () => {
    if (bloodComponent === "PLATELETS") return "#FFE49F";
    if (bloodComponent === "CRYOPRECIPITATE") return "#FFD569";
    if (bloodComponent === "WHOLE BLOOD") return "#950303";
    if (bloodComponent === "FRESH FROZEN PLASMA") return "#FFD569";
    if (bloodComponent === "PACKED RED BLOOD CELLS") return "#950303";
    if (bloodGroup === "A+") return "#950303";
    if (bloodGroup === "O+") return "#950303";
    if (bloodGroup === "A-") return "#FFD569";
    if (bloodGroup === "B+") return "#950303";
    if (bloodGroup === "B-") return "#FFD569";
    if (bloodGroup === "O-") return "#FFD569";
    if (bloodGroup === "AB-") return "#FFD569";
    if (bloodGroup === "AB+") return "#950303";

    if (genotype === "AA") return "#950303";
    if (genotype === "AS") return "orange";
    if (genotype === "AC") return "blue";
    if (genotype === "CC") return "darkGreen";
    if (genotype === "SC") return "purple";
    if (genotype === "SS") return "#FF7373";
    if (reason === "GENERAL SURGERY") return "#DE4515";
    if (reason === "BLOOD LOSS FROM ACCIDENT") return "#950303";
    if (reason === "WHOLE BLOOD") return "#2D2103";
    if (reason === "INTENSIVE CARE TREATMENT") return "#f5b240";
    if (reason === "TREATMENT OF NEWBORN BABIES & CHILDREN") return "#000080";
    if (reason === "ORTHOPAEDIC") return "#3BC34E";
    if (reason === "RENAL SURGERY") return "#710883";
    if (reason === "POST PARTUM HEMORRAGE") return "#FF7373";
    if (reason === "PNEUMONIA") return "orange";
    if (reason === "COVID") return "blue";
    if (reason === "ASTHMA") return "darkGreen";
    if (reason === "UPPER RESPITORY TRACT INFECTION") return "green";
    if (reason === "LOWER RESPITORY TRACT INFECTION") return "purple";
    if (reason === "OTHERS") return "#360872";
    return "#443C3C";
  };

  const colors = () => {
    return "#FFF";
  };
  return (
    <>
      <div className={styles.Container} onClick={() => onClick()}>
        <div
          style={{
            backgroundColor: (bgColor = background()),
            color: (color = colors()),
            width: "100%",
            textAlign: "center",
          }}
          className={styles.content}
        >
          {icon && <p>{(icon = icons())}</p>}
          {bloodComponent && (
            <p
              className={styles.name}
              style={{ fontSize: "20px", margin: "10px" }}
            >
              {bloodComponent}
            </p>
          )}
          {bloodGroup && <p className={styles.name}>{bloodGroup}</p>}
          {genotype && <p className={styles.name}>{genotype}</p>}
          {reason && (
            <p
              className={styles.name}
              style={{ fontSize: "20px", margin: "10px" }}
            >
              {reason}
            </p>
          )}
        </div>
      </div>
    </>
  );
};

export default Blood;
