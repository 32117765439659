import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Podetailmodal from "./PoModal.jsx";
import axiosClient from "../../../service/axios";
import styles from "./podetail.module.scss";
import pdf from "../../../assets/images/pdf.png";
import CircularLoader from "../../Loader/CircularLoader";
import {
  somethingWentWrongToast,
  orderCreateSuccessToast,
  addInventoryToast,
} from "../../NotificationToast/index.js";
import { IoChevronBackCircle } from "react-icons/io5";
import Modal from "../../../screens/UploadPage/Modal.jsx";

const Podetails = () => {
  let { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [data, setData] = useState([]);
  const [selectedItem, setSelectedItem] = useState({});
  const [selectedBrand, setSelectedBrand] = useState("");
  const [selectedCartonSize, setSelectedCartonSize] = useState("");
  const [pricePerUnit, setPricePerUnit] = useState("");
  const [qttyUnit, setQttyPerUnit] = useState("");
  const [newList, setNewList] = useState([]);
  const [product, setProduct] = useState([]);
  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalImage, setModalImage] = useState("");

  const openModal = (image) => {
    setModalImage(image);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const goBack = () => {
    navigate(`/po`);
  };

  useEffect(() => {
    try {
      setLoading(true);
      const fetchData = async () => {
        const response = await axiosClient.get(
          `/lite/v2/api/admin/po/list/${id}`
        );
        setData(response.data.data);
        setLoading(false);
        setProduct(response.data.product);
      };
      fetchData();
    } catch (error) {
      console.error("An error occurred:", error);
    }
  }, [id]);

  const handleAdd = () => {
    if (selectedItem.name && qttyUnit && selectedBrand && selectedCartonSize) {
      const newItem = {
        id: selectedItem.id,
        name: selectedItem.name,
        price: selectedItem.price,
        brand: selectedBrand,
        cartonsize: selectedCartonSize,
        qty: qttyUnit,
      };

      setNewList([...newList, newItem]);

      setSelectedItem({});
      setSelectedBrand("");
      setSelectedCartonSize("");
      setQttyPerUnit("");
    } else {
      addInventoryToast();
    }
  };

  const handleDelete = (index) => {
    const updatedItemsList = [...newList];
    updatedItemsList.splice(index, 1);
    setNewList(updatedItemsList);
  };

  const handleSubmit = async () => {
    try {
      if (newList.length === 0) {
        somethingWentWrongToast();
        return;
      }

      console.log("newList", newList);
      const response =  await axiosClient.post(`/lite/v2/api/admin/po/set/${id}`, {
        selectedItems: newList,
      });

      if (response.status === 200) {
        orderCreateSuccessToast();

        setTimeout(() => {
          navigate(`/po`);
        }, 5000);
      } else {
        somethingWentWrongToast();
      }

      // Clear the newList array
      setNewList([]);
    } catch (error) {
      console.error("Error submitting data:", error);
      somethingWentWrongToast();
    }
  };

  const isPDF = data.po?.toLowerCase().endsWith(".pdf");

  const imageLink = () => {
    window.open(data.po, "_blank");
  };

  return (
    <>
      <div className={styles.purchaseOrderText} onClick={goBack}>
        <IoChevronBackCircle />
      </div>

      <div className={styles.uploadCon}>
        <div className={styles.imagecontainer}>
          <div className={styles.boxShadow}>
            {isPDF ? (
              <img src={pdf} alt="po_image" className={styles.imagedetails} />
            ) : (
              <img
                src={data.po}
                alt="po_image"
                className={styles.imagedetails}
              />
            )}
          </div>

          <button onClick={imageLink} className={styles.imgBtn}>
            VIEW IMAGE
          </button>
        </div>

        <div className={styles.updateCon}>
          <div className={styles.productContainer}>
            <div className={styles.header}>
              <h1>PURCHASE ORDER</h1>
            </div>
            <div className={styles.productList}>
              <div className={styles.select}>
                <select
                  value={selectedItem.name || ""}
                  onChange={(e) => {
                    const selected = product.find(
                      (item) => item.name === e.target.value
                    );
                    setSelectedItem(selected || {});
                    setSelectedBrand(""); 
                    setSelectedCartonSize(""); 
                  }}
                >
                  <option value="">Select Item</option>
                  {product?.map((item, index) => (
                    <option key={index} value={item.name}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>

              {selectedItem.name && (
                <>
                  <div className={styles.select}>
                    <select
                      value={selectedBrand}
                      onChange={(e) => setSelectedBrand(e.target.value)}
                    >
                      <option value="">Select Brand</option>
                      {selectedItem.brand?.split(",").map((brand, index) => (
                        <option key={index} value={brand.trim()}>
                          {brand.trim()}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className={styles.select}>
                    <select
                      value={selectedCartonSize}
                      onChange={(e) => setSelectedCartonSize(e.target.value)}
                    >
                      <option value="">Select Carton Size</option>
                      {selectedItem.cartonsize
                        ?.split(",")
                        .map((size, index) => (
                          <option key={index} value={size.trim()}>
                            {size.trim()}
                          </option>
                        ))}
                    </select>
                  </div>
                </>
              )}

              <div className={styles.quantityControl}>
                <div className={styles.plusMinus}>
                  <input
                    type="text"
                    placeholder="Quantity"
                    value={qttyUnit}
                    onChange={(e) => setQttyPerUnit(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className={styles.addButton} onClick={handleAdd}>
              Add
            </div>
          </div>

          <div className={styles.submit}>
            <div className={styles.scrollableContainer} hover>
              <table>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Brand</th>
                    <th>Carton Size</th>
                    <th>Quantity</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {newList.map((item, index) => (
                    <tr key={index}>
                      <td>{item.name}</td>
                      <td>{item.brand}</td>
                      <td>{item.cartonsize}</td>
                      <td>{item.quantity}</td>
                      <td>
                        <button
                          className={styles.button}
                          onClick={() => handleDelete(index)}
                        >
                          Remove
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div className={styles.btnCon} onClick={handleSubmit}>
              <div className={styles.buttonText}>Update</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Podetails;
