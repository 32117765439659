import React, { useEffect, useState } from "react";
import style from "./ProductCard.module.scss";
import { FaHandPaper } from "react-icons/fa";
import { RiErrorWarningFill } from "react-icons/ri";
import { IoIosArrowDropleftCircle } from "react-icons/io";
import Checkbox from "@mui/material/Checkbox";
import Naira from "react-naira";
import {
  fetchProducts,
  saveStockbank,
} from "../../redux/new-order/actions/new-orders.actions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ConfirmationModal } from "../Modal/ConfirmationModal";
import { hospitalList } from "../../redux/orders/actions/orders.actions";
import { SelectField } from "../Form/SelectField";
import { Formik } from "formik";
import { Col, Row } from "react-bootstrap";
import CircularLoader from "../Loader/CircularLoader";

const ProductCard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [lowOrder, setLowOrder] = useState(false);

  let userDetails;
  if (localStorage.getItem("userToken")) {
    userDetails = JSON.parse(localStorage.getItem("userToken"));
  }

  if (!userDetails) {
    navigate("/");
  }

  const [loading, setLoading] = useState(true);
  const [checked, setChecked] = useState([]);
  const [quantities, setQuantities] = useState({});

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
      setQuantities({
        ...quantities,
        [value.id]: 1, // Initialize quantity to 1
      });
    } else {
      newChecked.splice(currentIndex, 1);
      const { [value.id]: removed, ...rest } = quantities;
      setQuantities(rest);
    }

    setChecked(newChecked);
  };

  let total = checked
    .reduce((acc, item) => acc + (quantities[item.id] || 0) * item.cost, 0)
    .toFixed(2);

  // Increase counter
  const increase = (card) => {
    setQuantities({
      ...quantities,
      [card.id]: (quantities[card.id] || 1) + 1,
    });
  };

  // Decrease counter
  const decrease = (card) => {
    setQuantities({
      ...quantities,
      [card.id]: quantities[card.id] > 1 ? quantities[card.id] - 1 : 1,
    });
  };

  const { orderType, products } = useSelector((state) => state.newOrderReducer);
  const productss = products?.products;

  const colors = () => {
    if (orderType?.cardText === "BLOOD") return "#B70303";
    if (orderType?.cardText === "OXYGEN") return "#0779F7";
    if (orderType?.cardText === "STOCKBANK") return "#066304";
    return "#DA621B";
  };

  const [hospitalDropDown, setHospitalDropDown] = useState();

  useEffect(() => {
    dispatch(fetchProducts())
      .then((res) => {
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
    dispatch(hospitalList()).then((response) => {
      let options = response.data
        .sort((a, b) => a.label - b.label)
        .map((hosp) => ({
          label: `${hosp.Name} (${hosp.State}-${hosp.City})`,
          value: hosp.Hospital_ID,
        }));
      setHospitalDropDown(options);
      // console.log("name:", response.data[0].Name);
    });
  }, [dispatch]);

  const formatCurrency = (amount, currency) => {
    return new Intl.NumberFormat("en-KE", {
      style: "currency",
      currency: currency,
    }).format(amount);
  };

  return (
    <div className={style.container}>
      <Row>
        <Col md={1}>
          <IoIosArrowDropleftCircle
            size={50}
            color={colors()}
            style={{ cursor: "pointer" }}
            onClick={() => navigate(-1)}
          />
        </Col>
        <Col md={10}>
          <h3 className={style.header} style={{ color: colors() }}>
            hospital detail
          </h3>
          <h4 className={style.requestHeader1} style={{ color: colors() }}>
            select hospital
          </h4>
          <Formik
            initialValues={{
              hospital: "",
              checked: "",
            }}
            validate={(values) => {
              const errors = {};
              if (!values.hospital) {
                errors.hospital = "This field is mandatory";
              }
              return errors;
            }}
            onSubmit={(values, actions) => {
              values.hospital = values.hospital.value;
              values.checked = checked.map((item) => ({
                ...item,
                qty: quantities[item.id],
              }));

              if (checked?.length < 1) {
                setLowOrder(true);
              } else if (total > 5000) {
                dispatch(saveStockbank(values));
                navigate("/payment");
                return;
              } else {
                setOpenModal(true);
              }
            }}
          >
            {({
              handleChange,
              handleSubmit,
              isSubmitting,
              values,
              resetForm,
              setFieldValue,
              handleBlur,
              errors,
              touched,
            }) => (
              <form>
                <div className={`${style.formInput} shadow-none`}>
                  <SelectField
                    className={style.input}
                    name="hospital"
                    options={hospitalDropDown}
                    onChange={setFieldValue}
                    value={values?.hospital}
                    placeholder="Find a hospital here"
                    onBlur={handleBlur}
                  />
                  {errors.hospital && touched.hospital ? (
                    <p className="text-danger mt-1 mx-2">{errors.hospital}</p>
                  ) : null}
                </div>

                <h4 className={style.requestHeader} style={{ color: colors() }}>
                  select products
                </h4>

                <div>
                  {loading && (
                    <div className="d-flex justify-content-center align-items-center">
                      <CircularLoader />
                    </div>
                  )}
                  <div
                    className={style.allProduct}
                    style={{ maxHeight: "50vh", overflow: "scroll" }}
                  >
                    {productss?.map((product) => {
                      // const labelId = `${product.id}`;
                      return (
                        <div className={style.eachProduct} key={product.id}>
                          <div className={style.top}>
                            <div className={style.each}>
                              <div className={style.icon}>
                                <FaHandPaper size={30} color="white" />
                              </div>
                              <div className={style.title}>
                                <p className={style.name}>{product.name}</p>
                                <p className={style.desc}>
                                  {product.description}
                                </p>
                              </div>
                            </div>
                            <Checkbox
                              edge="end"
                              onChange={handleToggle(product)}
                              checked={checked.indexOf(product) !== -1}
                              // inputProps={labelId}
                            />
                          </div>

                          <div className={style.each}>
                            {checked.indexOf(product) !== -1 && (
                              <div>
                                <div className={style.control}>
                                  <div
                                    className={style.controlBtn}
                                    onClick={() => decrease(product)}
                                  >
                                    -
                                  </div>

                                  <div className={style.qty}>
                                    {quantities[product.id] || 1}
                                  </div>
                                  <div
                                    className={style.controlBtn}
                                    onClick={() => increase(product)}
                                  >
                                    +
                                  </div>
                                </div>
                              </div>
                            )}
                            <div className={style.line}></div>
                            <p className={style.price}>
                              {formatCurrency(
                                (quantities[product.id] || 1) * product.cost,
                                "KES"
                              )}
                            </p>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div
                    type="submit"
                    name="Scheduled"
                    onClick={handleSubmit}
                    disabled={isSubmitting}
                    className={style.next}
                  >
                    {"NEXT"}
                  </div>
                  {/* DIALOG MODAL */}
                  <ConfirmationModal
                    open={openModal}
                    handleClose={() => setOpenModal(false)}
                    bgcolor="background.paper"
                    width="40vw"
                    closemodal={() => setOpenModal(false)}
                    instruction={
                      <div className={style.instructionContainer}>
                        <RiErrorWarningFill size={80} color="#B70303" />
                        <div className={style.total}>
                          Cart Total -{" "}
                          {/* <Naira className={style.naira}>{total}</Naira> */}
                          {formatCurrency(total, "KES")}
                        </div>
                        <p className={style.warning}>
                          Order total must be at least{" "}
                          <span className={style.naira}>
                            {/* <Naira>25300</Naira> */}
                            {formatCurrency("5000", "KES")}
                          </span>
                          . Please select some additional items.
                        </p>

                        <button
                          className={style.okay}
                          onClick={() => setOpenModal(false)}
                        >
                          Got it
                        </button>
                      </div>
                    }
                  />

                  {/* DIALOG MODAL */}
                  <ConfirmationModal
                    open={lowOrder}
                    handleClose={() => setLowOrder(false)}
                    bgcolor="background.paper"
                    width="40vw"
                    closemodal={() => setLowOrder(false)}
                    instruction={
                      <div className={style.instructionContainer}>
                        <RiErrorWarningFill size={80} color="#B70303" />

                        <p className={style.warning}>
                          Please select some products
                        </p>

                        <button
                          className={style.okay}
                          onClick={() => setLowOrder(false)}
                        >
                          Got it
                        </button>
                      </div>
                    }
                  />
                </div>
              </form>
            )}
          </Formik>
        </Col>
      </Row>
    </div>
  );
};

export default ProductCard;
