import React from "react";
import styled from "./Instruction.module.scss";

const OngoingInstruction = (props) => {
  return (
    <div>
      <div className="d-flex flex-column justify-content-center">
        <div className="d-flex justify-content-between mt-3 mb-3">
          <p className={styled.breadcumb}>
            Order{" > "}
            <span>{props.orderId}</span>{" "}
          </p>
          <p className={styled.header} style={{ color: props.headerColor }}>
            ORDER DETAILS
          </p>
          <p
            className={styled.name}
            style={{
              background: props.categoryBg,
              color: props.categoryColor,
              textTransform: "uppercase",
            }}
          >
            {props.category ? props.category : "oxygen"}
          </p>
        </div>
        {/* <div className={styled.modalContainer}>
          <p className={styled.head}>order status</p>
          <div className={styled.line}></div>
          <p className={styled.head2} style={{ color: props.textColor }}>
            {props.orderStatus}
          </p>
        </div> */}
        <div className={styled.modalContainer1}>
          <p className={styled.title}>product name</p>
          <div className={styled.line}></div>
          <p className={styled.content}>{props.productName}</p>
        </div>
        {props.title === "oxygen" && (
          <div className={styled.modalContainer1}>
            <p className={styled.title}>size</p>
            <div className={styled.line}></div>
            <p className={styled.content}>{props.size}</p>
          </div>
        )}
        <div className={styled.modalContainer1}>
          <p className={styled.title}>quantity</p>
          <div className={styled.line}></div>
          <p className={styled.content}>{props.qty}</p>
        </div>
        <div className={styled.modalContainer1}>
          <p className={styled.title}>vendor name</p>
          <div className={styled.line}></div>
          <p className={styled.content}>{props.vendor}</p>
        </div>
        <div className={styled.modalContainer1}>
          <p className={styled.title}>vendor address</p>
          <div className={styled.line}></div>
          <p className={styled.content}>{props.address}</p>
        </div>
        <div className={styled.modalContainer1}>
          <p className={styled.title}>order time</p>
          <div className={styled.line}></div>
          <p className={styled.content}>{props.orderTime}</p>
        </div>
        {/* <div className={styled.modalContainer1}>
          <p className={styled.title}>e.t.a</p>
          <div className={styled.line}></div>
          <p className={styled.content}>{props.eta}</p>
        </div> */}
        <div className="d-flex justify-content-center">
          {props.button1 && (
            <button
              className={` shadow-none ${styled.actionBtn} ${styled.confirm}`}
              style={{ background: props.btnColor }}
              onClick={() => props.onClickNo()}
            >
              {props.button1}
            </button>
          )}
          {props.button2 && (
            <button
              className={` shadow-none ${styled.actionBtn} ${styled.reject}`}
              style={{ background: props.btnColor }}
              onClick={() => props.onClickYes()}
            >
              {props.button2}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default OngoingInstruction;
