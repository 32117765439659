import React, { useState, useEffect, useCallback } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Styles from "./ChatModal.module.scss";
import { IoIosSend } from "react-icons/io";

import axios from "axios";

const ChatModal = ({
  open,
  handleClose,
  closemodal,
  width,
  bgcolor,
  order_id,
  dispatch,
  title,
}) => {
  const [text, setText] = useState([{}]);
  const [newMessage, setNewMessage] = useState("");


  let agentId;
  if (localStorage.getItem("userToken")) {
    agentId = JSON.parse(localStorage.getItem("userToken"));
  }

  let username;
  if (localStorage.getItem("userToken")) {
    username = JSON.parse(localStorage.getItem("userToken")).username;
  }

  const handleSendMessage = async () => {
    if (newMessage.trim() !== "") {
      const newDate = new Date().getTime();
      // console.log("newDatass", newDate);

      try {
        const url = `https://plus.lifebank.ke/lite/v2/api/chat/${title}/${dispatch}/${order_id}`;
        const response = await axios.post(url, {
          message: newMessage,
          agentId: agentId?.id,
          username: username,
          createdAt: newDate,
        });
        setText((prev) => [
          ...prev,
          {
            text: newMessage,
            user: { agent: agentId?.id },
            createdAt: newDate,
          },
        ]);
        setNewMessage("");
      } catch (error) {
        console.error("POST Error:", error);
      }
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  const fetchData = useCallback(async () => {
    try {
      const url = `https://plus.lifebank.ke/lite/v2/api/chat/${title}/${dispatch}/${order_id}`;
      const response = await axios.get(url);

      const texts = response?.data?.data
        ? Object.values(response.data.data).map((d) => {
            const createdAtTimestamp = d?.createdAt
              ? new Date(d.createdAt)
              : null;

            const createdAtUnixTime =
              createdAtTimestamp instanceof Date && !isNaN(createdAtTimestamp)
                ? createdAtTimestamp.getTime() 
                : null;

            return {
              text: d?.text || "",
              user: d?.user || {},
              createdAt: createdAtUnixTime,
            };
          })
        : [];
      const reversedTexts = texts
      setText(reversedTexts);
    } catch (error) {
      console.error("API Error:", error);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      fetchData();
    }, 10000);

    return () => {
      clearTimeout(timeout);
    };
  });

  


  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: width,
    zIndex: 19000000,
    bgcolor: bgcolor,
    borderRadius: "15px",
    boxShadow: "1px 2px 10px 5px rgba(112, 144, 176, 0.1)",
    p: 4,
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className={Styles.modal}
    >
      <Box sx={style}>
        <>
          <div onClick={closemodal}>
            <button className={Styles.close}>X</button>
            <section className="d-flex justify-content-center mt-3">
              <h1 className={Styles.header}>CHAT</h1>
            </section>
          </div>

          {/* Chat */}
          <div className={Styles.chatContainer}>
            {text.map((text, index, arr) => (
              <div key={index} className={Styles.messageContainer}>
                {text?.user?.agent === agentId?.id ? (
                  <div className={Styles.rp}>
                    <div>
                      <div className={Styles.replyText}>{text.text}</div>
                      <div>
                        {new Date(Number(text.createdAt)).toLocaleString()}
                      </div>
                    </div>
                    <div className={Styles.imgP}>
                      <img className={Styles.img} />
                      <p>AD</p>
                    </div>
                  </div>
                ) : (
                  <div className={Styles.rc}>
                    <div className={Styles.imgP}>
                      <img className={Styles.img2}  />
                      <p>DP</p>
                    </div>
                    <div>
                      <div className={Styles.receivedText}>{text.text}</div>
                      <div>
                        {new Date(Number(text.createdAt)).toLocaleString()}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>

          {/* New message input */}
          <div className={Styles.inputContainer}>
            <textarea
              id="messageTextarea"
              type="text"
              placeholder="Type a message..."
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              onKeyDown={handleKeyDown}
            />
            <div className={Styles.icon} onClick={handleSendMessage}>
              <IoIosSend />
              <span className={Styles.tooltip}>Send Message</span>
            </div>
          </div>
        </>
      </Box>
    </Modal>
  );
};

export default ChatModal;
