import React, { useState } from "react";
import { FaDotCircle } from "react-icons/fa";
import { ConfirmationModal } from "../Modal/ConfirmationModal";
import style from "./Orders.module.scss";
import OngoingInstruction from "../modalInstruction/OngoingInstruction";
import { constructOrderDate } from "../../helper/DateFormatter";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { trackOrder } from "../../redux/orders/actions/orders.actions";

const OngoingOrder = ({
  closed,
  order_id,
  name,
  order_amt,
  tym,
  address,
  city,
  state,
  b_type,
  hospital,
  title,
  blood,
  nameTime,
  hospitalTime,
  order_state,
  product,
  qty,
}) => {
  const [openModal, setopenModal] = useState(false);

  const bgColors = () => {
    if (title === "blood") return "#FDE0E0";
    if (title === "oxygen") return "#E0EFFD";
    return "#F0FDE0";
  };

  const colors = () => {
    if (title === "blood") return "#B70303";
    if (title === "oxygen") return "#2457C5";
    return "#78A55A";
  };

  const bgColor = () => {
    if (closed === "closed") return "#FAAC8A";
    if (closed === "opens") return "#FFF8D3";
    return "";
  };

  const color = () => {
    if (closed === "closed") return "#A71616";
    if (closed === "opens") return "#E7C40C";
    return "";
  };

  const dispatch = useDispatch();

  const navigate = useNavigate();
  const track = () => {
    dispatch(
      trackOrder(order_id, { title: title === "blood" ? "Blood" : title })
    );
    navigate(`/track-order/${order_id}`);
  };

  const closeModal = () => {
    setopenModal(false);
  };

  return (
    <div className={style.container}>
      <div onClick={() => setopenModal(true)}>
        <div className="d-flex justify-content-between">
          <p className={style.header}>Tracking Number</p>
          <p className={style.header}>Status</p>
        </div>
        <div className="d-flex justify-content-between">
          <p className={style.content1}>
            {title === "blood"
              ? `LB-${order_id}`
              : title === "oxygen"
              ? `LBO-${order_id}`
              : title === "Stockbank"
              ? `SBO-${order_id}`
              : ``}
          </p>
          <p
            className={style.status2}
            style={{ background: bgColor(), color: color() }}
          >
            Ongoing
          </p>
        </div>
        {/* <div className="d-flex justify-content-between">
        <p className={style.content}>
          {" "}
          <span>
            <FaDotCircle color="red" />
          </span>{" "}
          {b_type}
        </p>
        <p className={style.content2}>{nameTime}</p>
      </div> */}
        <div className="d-flex justify-content-between">
          <p className={style.content}>
            {" "}
            <span>
              <FaDotCircle color="blue" />
            </span>{" "}
            {name}
          </p>
          <p className={style.content2}>{hospitalTime}</p>
        </div>
      </div>

      {/* DIALOG MODAL */}
      <ConfirmationModal
        open={openModal}
        bgcolor="background.paper"
        width="75vw"
        handleClose={() => setopenModal(false)}
        closemodal={closeModal}
        instruction={
          <OngoingInstruction
            status={closed}
            button1="CANCEL"
            button2="TRACK ORDER"
            onClickNo={() => setopenModal(false)}
            onClickYes={track}
            headerColor={colors()}
            categoryBg={bgColors()}
            categoryColor={colors()}
            textColor={colors()}
            orderId={
              title === "blood"
                ? `LB-${order_id}`
                : title === "oxygen"
                ? `LBO-${order_id}`
                : title === "stockbank"
                ? `SBO-${order_id}`
                : ``
            }
            hospitalName={name}
            productName={product}
            orderStatus={order_state}
            category={title}
            qty={qty}
            vendor={name}
            address={address}
            orderTime={`${constructOrderDate(tym)} `}
          />
        }
      />
    </div>
  );
};

export default OngoingOrder;
