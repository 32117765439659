import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { labXOrders } from "../../redux/orders/actions/orders.actions";
import CircularLoader from "../Loader/CircularLoader";
import Pagination from "../paginationCopy/Pagination";
import Search from "../search";
import Boat from "./boat/Boat";
import Nerve from "./nerve/Nerve";
import style from "./Orders.module.scss";
import Ussd from "./ussd/Ussd";
// import axios from "axios";
// import Quip from "./quip/Quip";

const NewOrders = () => {
  const [activeTab, setactiveTab] = useState("nerve");
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [ussdData, setUssdData] = useState([]);
  const [boatData, setBoatData] = useState([]);
  // const [quip, setQuip] = useState([]);
  // const [quipData, setQuipData] = useState([]);

  const dispatch = useDispatch();

  const { labxOrders } = useSelector((state) => state.orderReducer);

  let nerve = labxOrders?.nerve;
  let ussd = labxOrders?.ussd;
  let boat = labxOrders?.boat;

  // const url = "https://quip.lifebank.ng/api/admin/get_all_buy";

  // useEffect(() => {
  //   const fetchQuip = () => {
  //     axios.get(url).then((response) => {
  //       setQuip(response?.data);
  //     });
  //   };
  //   fetchQuip();
  // }, []);

  useEffect(() => {
    setLoading(true);
    fetchOrders();
    const myInterval = setInterval(fetchOrders, 30000);
    setLoading(false);
    return () => {
      // should clear the interval when the component unmounts
      clearInterval(myInterval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchOrders = () => {
    dispatch(labXOrders());
  };

  // NERVE
  // Search handler
  useEffect(() => {
    setData(
      nerve?.filter((id) => {
        return Object.values(id).some((name) =>
          String(name).toLowerCase().includes(search.toLowerCase())
        );
      })
    );
  }, [nerve, search]);

  const handleChange = (e) => {
    setSearch(e.target.value);
  };

  // USSD
  // Search handler
  useEffect(() => {
    setUssdData(
      ussd?.filter((id) => {
        return Object.values(id).some((name) =>
          String(name).toLowerCase().includes(search.toLowerCase())
        );
      })
    );
  }, [search, ussd]);

  // // Quip
  // // Search handler
  // useEffect(() => {
  //   setQuipData(
  //     quip?.filter((id) => {
  //       return Object.values(id).some((name) =>
  //         String(name).toLowerCase().includes(search.toLowerCase())
  //       );
  //     })
  //   );
  // }, [quip, search]);

  // BOAT
  // Search handler
  useEffect(() => {
    setBoatData(
      boat?.filter((id) => {
        return Object.values(id).some((name) =>
          String(name).toLowerCase().includes(search.toLowerCase())
        );
      })
    );
  }, [boat, search]);

  // pagination
  const [pageNumber, setPageNumber] = useState(0);
  const size = 5;
  const pagesVisited = pageNumber * size;
  const pageCountNerve = Math.ceil(data?.length / size);
  const pageCountUssd = Math.ceil(ussdData?.length / size);
  const pageCountBoat = Math.ceil(boatData?.length / size);
  // const pageCountQuip = Math.ceil(quipData?.length / size);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  return (
    <div>
      <Row className={style.tabContainer}>
        <Col md={8} className={style.tab2}>
          <div
            onClick={() => setactiveTab("nerve")}
            className={
              activeTab === "nerve" ? `${style.btnActive2}` : `${style.btn2}`
            }
          >
            Nerve Orders
          </div>
          <div className={style.line}></div>
          <div
            onClick={() => setactiveTab("ussd")}
            className={
              activeTab === "ussd" ? `${style.btnActive2}` : `${style.btn2}`
            }
          >
            Call back Request
          </div>
          <div className={style.line}></div>

          <div
            onClick={() => setactiveTab("boat")}
            className={
              activeTab === "boat" ? `${style.btnActive2}` : `${style.btn2}`
            }
          >
            BOAT
          </div>

          {/* <div className={style.line}></div>
          <div
            onClick={() => setactiveTab("quip")}
            className={
              activeTab === "quip" ? `${style.btnActive2}` : `${style.btn2}`
            }
          >
            QUIP
          </div> */}

          {/* <div className={style.line}></div> */}
        </Col>

        <Col md={4}>
          <Search placeholder='Find any request here' onChange={handleChange} />
        </Col>
      </Row>

      {loading && (
        <div className='d-flex justify-content-center align-items-center'>
          <CircularLoader />
        </div>
      )}

      {activeTab === "nerve" && (
        <>
          {data?.length > 0 ? (
            <>
              {data
                ?.sort((a, b) => (a.tym > b.tym ? -1 : 1))
                ?.slice(pagesVisited, pagesVisited + size)
                ?.map((item) => (
                  <Nerve {...item} key={item.id} />
                ))}
            </>
          ) : data?.length === 0 && !loading ? (
            <p className='text-center'>No record found</p>
          ) : (
            <div className='d-flex justify-content-center align-items-center'>
              <CircularLoader />
            </div>
          )}

          {data?.length > size && (
            <Pagination pageCount={pageCountNerve} onPageChange={changePage} />
          )}
        </>
      )}

      {activeTab === "boat" &&
        (boatData?.length > 0 ? (
          <div>
            {boatData
              ?.sort((a, b) => (a.tym > b.tym ? -1 : 1))
              ?.slice(pagesVisited, pagesVisited + size)
              .map((item) => (
                <Boat {...item} key={item.order_id} />
              ))}

            {boatData?.length > size && (
              <Pagination pageCount={pageCountBoat} onPageChange={changePage} />
            )}
          </div>
        ) : data?.length === 0 && !loading ? (
          <p className='text-center'>No record found</p>
        ) : (
          <div className='d-flex justify-content-center align-items-center'>
            <CircularLoader />
          </div>
        ))}

      {activeTab === "ussd" &&
        (ussdData?.length > 0 ? (
          <>
            {ussdData
              ?.sort((a, b) => (a.id > b.id ? -1 : 1))
              ?.slice(pagesVisited, pagesVisited + size)
              ?.map((item) => (
                <Ussd {...item} key={item.id} />
              ))}

            {ussdData?.length > size && (
              <Pagination pageCount={pageCountUssd} onPageChange={changePage} />
            )}
          </>
        ) : data?.length === 0 && !loading ? (
          <p className='text-center'>No record found</p>
        ) : (
          <div className='d-flex justify-content-center align-items-center'>
            <CircularLoader />
          </div>
        ))}

      {/* {activeTab === "quip" &&
        (quipData?.length > 0 ? (
          <>
            {quipData
              ?.sort((a, b) => (a.id < b.id ? -1 : 1))
              ?.slice(pagesVisited, pagesVisited + size)
              ?.map((item) => (
                <Quip {...item} key={item.id} url={url} />
              ))}

            {quipData?.length > size && (
              <Pagination pageCount={pageCountQuip} onPageChange={changePage} />
            )}
          </>
        ) : data?.length === 0 && !loading ? (
          <p className="text-center">No record found</p>
        ) : (
          <div className="d-flex justify-content-center align-items-center">
            <CircularLoader />
          </div>
        ))} */}
    </div>
  );
};

export default NewOrders;
