// yup validation
import * as Yup from "yup";

// regex for email
// export const phoneRegExp =
// /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

// //change password
// export const passwordSchema = Yup.object().shape({
//   password: Yup.string()
//     .min(7, "Password must be at least 7 characters")
//     .required("Password is required"),
//   cPassword: Yup.string()
//     .required("Please confirm password")
//     .oneOf([Yup.ref("password")], "Password Mismatch"),
// });

//change password
export const changePasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(7, "Password must be at least 7 characters")
    .required("Password is required"),
  cPassword: Yup.string()
    .required("Please confirm password")
    .oneOf([Yup.ref("password")], "Password Mismatch"),
});

//new contact person
export const informationSchema = Yup.object().shape({
  partnerorganisationId: Yup.object().shape({
    label: Yup.string().required("This field is required").nullable(),
    value: Yup.string().required("This field is required").nullable(),
  }),
});

export const loginSchema = Yup.object({
  email: Yup.string()
    .email("please enter a valid email")
    .required("Email is required"),
  password: Yup.string().required("Password is required"),
});

export const hmoSchema = Yup.object({
  hospital: Yup.string().required("Hospital name is mandatory"),
  doctor: Yup.string().required("Doctor's name is mandatory"),
});

export const newOrderSchema = Yup.object({
  reason: Yup.string().required("Please state a reason"),
  bloodComponent: Yup.string().required("This field is required"),
});
