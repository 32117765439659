import React, { useEffect, useState } from "react";
import ProcessCard from "../../../components/orders/process/ProcessCard";
import style from "./Process.module.scss";
import { IoIosArrowDropleftCircle } from "react-icons/io";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import RadioGroup, { useRadioGroup } from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
// import { makeStyles } from "@mui/material/styles";
// import ProcessCardSplit from "../../../components/orders/process/ProcessCardSplit";
// import ProcessCardSingle from "../../../components/orders/process/ProcessCardSingle";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  labXOrders,
  // precheckOrder,
  // processOrder,
} from "../../../redux/orders/actions/orders.actions";
import {
  // placeOrder,
  processExistingOrder,
} from "../../../redux/new-order/actions/new-orders.actions";
import { SuccessModal } from "../../../components/Modal/SuccessModal";
import MessageInstruction from "../../../components/modalInstruction/MessageInstruction";
import { failedOrder } from "../../../components/NotificationToast";
import { Col, Row } from "react-bootstrap";
import ProcessCardSplit from "../../../components/orders/process/ProcessCardSplit";
import ProcessCardSingle from "../../../components/orders/process/ProcessCardSingle";
import ProcessOrderSingle from "../../../components/orders/process/ProcessOrderSingle";
// import ProcessOrderSingle from "../../../components/orders/process/ProcessOrderSingle";

// const useStyles = makeStyles((theme) => ({
//   root: {},
// }));

const ProcessStockBank = () => {
  const [successModal, setSuccessModal] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  // const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  let userDetails;
  if (localStorage.getItem("userToken")) {
    userDetails = JSON.parse(localStorage.getItem("userToken"));
  }

  if (!userDetails) {
    navigate("/");
  }

  useEffect(() => {
    dispatch(labXOrders());
  }, [id]);
  const { labxOrders } = useSelector((state) => state.orderReducer);

  console.log("labxOrders", labxOrders);

  const { existingOrder } = useSelector((state) => state.newOrderReducer);
  console.log("existingOrder", existingOrder);

  // let nerve = labxOrders?.nerve;

  const getOrderFromID = () => {
    let order;

    for (let category in labxOrders) {
      let match = labxOrders[category].find(
        (o) => o?.order_id === id || o?.id === id
      );
      if (match) {
        order = match;
        break;
      }
    }

    return order;
  };

  let detail = getOrderFromID();

  console.log("detaa::", detail);

  let precheck;
  if (localStorage.getItem("precheck"))
    precheck = JSON.parse(localStorage.getItem("precheck"));

  let vendor = precheck?.vendor;
  let rider = precheck?.Dispatch;
  let status = precheck?.status;

  const response1 = {
    agent: userDetails?.id,
    vendor_id: vendor && vendor[0]?.ref_id,
    dispatch_id: rider && rider[0]?.dispatch_id,
    order_id: id,
    title: "stockbank",
  };

  const response2 = {
    agent: userDetails?.id,
    vendor_id: vendor && vendor[1]?.ref_id,
    dispatch_id: rider && rider[1]?.dispatch_id,
    order_id: id,
    title: "stockbank",
  };

  const handleProcess1 = () => {
    dispatch(processExistingOrder(response1));
    if (existingOrder?.status === 300) {
      failedOrder();
    } else {
      setSuccessModal(true);
    }
  };

  const handleProcess2 = () => {
    dispatch(processExistingOrder(response2));
    if (existingOrder?.status === 300) {
      failedOrder();
    } else {
      setSuccessModal(true);
    }
  };

  // useEffect(() => {
  //   dispatch(labXOrders(id));
  // }, [id]);

  let checked;
  const StyledFormControlLabel = styled((props) => (
    <FormControlLabel {...props} />
  ))(({ _theme, checked }) => ({
    ".MuiFormControlLabel-label": checked && {
      color: "black",
    },
  }));

  function MyFormControlLabel(props) {
    const radioGroup = useRadioGroup();

    checked = false;

    if (radioGroup) {
      checked = radioGroup.value === props?.value;
    }

    return <StyledFormControlLabel checked={checked} {...props} />;
  }

  MyFormControlLabel.propTypes = {
    /**
     * The value of the component.
     */
    value: PropTypes.any,
  };

  const navigateFunc = () => {
    navigate("/ongoing-orders");
  };

  const colors = () => {
    if (detail?.title === "Blood") return "#B70303";
    if (detail?.title === "oxygen") return "#0779F7";
    if (detail?.title === "StockBank") return "#066304";
    return "#B70303";
  };

  const badgeColor = () => {
    if (detail?.title === "Blood") return "#FDE0E0";
    if (detail?.title === "oxygen") return "#E0EFFD";
    return "#F0FDE0";
  };

  return (
    <div style={{ width: "97%" }}>
      {status === 300 ? (
        <Row className="d-flex align-items-center mb-4">
          <Col md={1}>
            <IoIosArrowDropleftCircle
              size={50}
              style={{ cursor: "pointer" }}
              onClick={() => navigate(-1)}
              color={colors()}
            />
          </Col>
          <Col md={8}>
            <p className={style.warning}>
              No Match Available. Please try again
            </p>
          </Col>
        </Row>
      ) : (
        <>
          <p className={style.header}>BEST MATCH</p>
          {vendor[0] || rider[0] ? (
            <ProcessCard
              id="1"
              name={vendor[0]?.name}
              address={vendor[0]?.address}
              dispatch_name={rider[0]?.dispatch_name}
              dispatchAddress=""
              distance={`${parseInt(vendor[0]?.distance * (65 / 60))} Mins`}
              onClick={handleProcess1}
              color={colors()}
              borderColor={colors()}
              bgColor={colors()}
            />
          ) : (
            <p>No match at the moment</p>
          )}

          {vendor[1] && rider[1] ? (
            <ProcessCard
              id={"2"}
              name={vendor[1]?.name}
              address={vendor[1]?.address}
              dispatch_name={rider[1]?.dispatch_name}
              dispatchAddress=""
              distance={`${parseInt(vendor[1]?.distance * (65 / 60))} Mins`}
              onClick={handleProcess2}
              color={colors()}
              borderColor={colors()}
              bgColor={colors()}
            />
          ) : (
            <></>
          )}
        </>
      )}

      <RadioGroup name="use-radio-group" defaultValue="single">
        <MyFormControlLabel
          value="single"
          label="SINGLE DELIVERY"
          control={
            <Radio
              sx={{
                color: "gray",
                "&.Mui-checked": {
                  color: colors(),
                },
              }}
            />
          }
        />
        <ProcessOrderSingle
          title={detail?.title}
          order_id={detail?.order_id}
          agent={userDetails?.id}
          color={colors()}
          border={colors()}
          textColor={colors()}
          badgeColor={badgeColor()}
          qty={detail?.qty}
          vendor={vendor}
          rider={rider}
        />
        {/* <ProcessCardSingle vendor={vendor} rider={rider}/>  */}
        {/* order_by={neworder?.order_by}
          agent={userDetails?.id}
          agentType={neworder?.agentType}
          blood={neworder?.blood}
          hmo={neworder?.hmo}
          doctor={neworder?.doctor}
          bloodtype={neworder?.b_type}
          genotype={neworder?.genotype}
          patient={neworder?.patient}
          crossmatch={neworder?.Cross_matching === "Yes" ? "true" : "false"}
          genotyping={neworder?.genotyping === "Yes" ? "true" : "false"}
          payment={neworder?.payment_type}
          usage={neworder?.usage}
          schedule_date={dateFormat.DateYearMonthDay(
            neworder?.schedule_date
          )}
          schedule_time={dateFormat.TimeOnly(neworder?.schedule_tym)}
          qty={neworder?.order_amt}
          temperature={neworder?.temperature === "Yes" ? "true" : "false"}
          security={""}
          title={neworder?.title === "BLOOD" ? "Blood" : neworder?.title}
          color={colors()}
          border={colors()}
          textColor={colors()}
          vendor={vendor}
          rider={rider}
        /> */}
      </RadioGroup>

      {/*  */}

      {/* create MODAL */}
      <SuccessModal
        open={successModal}
        onClose={navigateFunc}
        bgcolor={colors()}
        width="55vw"
        height="60vh"
        instruction={
          <MessageInstruction
            heading={"SUCCESSFUL!!!"}
            hide={"hide"}
            content={" Your order has been placed successfully"}
            button="COMPLETE"
            onClickBtn={navigateFunc}
            color={badgeColor()}
          />
        }
      />
    </div>
  );
};

export default ProcessStockBank;
