/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import Box from "@mui/material/Box";
import { Formik } from "formik";
import { useEffect } from "react";
import Styles from "./UpdateOrder.module.scss";
import { updateOrderDetails } from "../../redux/orders/actions/orders.actions";
import { useDispatch } from "react-redux";
import { Form } from "react-bootstrap";
import { SelectField } from "../Form/SelectField";
import { useState } from "react";
import { paymentMode, updateBloodComponent, bloodGroup } from "../../data";

export default function UpdateForm(props) {
  const [payments, setPayment] = React.useState("");
  const [riderDropdown, setRiderDropDown] = useState();
  const [lab, setLabDropDown] = useState();
  const [bloodComponent, setBloodComponent] = useState("");
  const [bloodType, setBloodType] = useState("");

  const dispatch = useDispatch();

  let editType;

  if (window.localStorage.getItem("editType"))
    editType = window.localStorage.getItem("editType");

  useEffect(() => {
    let options = paymentMode?.map((reason) => ({
      label: reason?.label,
      value: reason?.value,
    }));
    setPayment(options);
  }, []);

  useEffect(() => {
    let options = updateBloodComponent?.map((comp) => ({
      label: comp?.bloodComponent,
      value: comp?.bloodComponent,
    }));
    setBloodComponent(options);
  }, []);

  useEffect(() => {
    let options = bloodGroup?.map((comp) => ({
      label: comp?.bloodGroup,
      value: comp?.bloodGroup,
    }));
    setBloodType(options);
  }, []);

  let precheck;
  if (localStorage.getItem("precheck"))
    precheck = JSON.parse(localStorage.getItem("precheck"));

  let vendor = precheck?.vendor;
  let rider = precheck?.Dispatch;

  useEffect(() => {
    let options = vendor?.map((lab) => ({
      label: lab?.name,
      value: lab?.ref_id,
    }));
    setLabDropDown(options);
  }, []);

  useEffect(() => {
    let options = rider?.map((ride) => ({
      label: ride?.dispatch_name,
      value: ride?.dispatch_id,
    }));
    setRiderDropDown(options);
  }, []);

  return (
    <>
      <Formik
        initialValues={{
          title: "",
          editkey: "",
          order_id: "",
        }}
        validate={(values) => {
          const errors = {};
          if (!values.vendor_id && editType === "VENDOR") {
            errors.vendor_id = "This field is required";
          }
          if (!values.payment_type && editType === "PAYMENT") {
            errors.payment_type = "This field is required";
          }
          if (!values.dispatch_id && editType === "DISPATCH") {
            errors.dispatch_id = "This field is required";
          }
          if (!values.component && editType === "COMPONENT") {
            errors.component = "This field is required";
          }
          if (!values.qty && editType === "QUANTITY") {
            errors.qty = "This field is required";
          }
          return errors;
        }}
        onSubmit={(values) => {
          values.title = props.title;
          values.editkey =
            editType === "DISPATCH"
              ? "Dispatch"
              : editType === "VENDOR"
              ? "vendor"
              : editType === "COMPONENT"
              ? "Component"
              : editType === "QUANTITY"
              ? "Unit"
              : "Payment";
          values.order_id = props.order_id;
          editType === "PAYMENT" &&
            (values.payment_type = values.payment_type.value);
          editType === "DISPATCH" &&
            (values.dispatch_id = values.dispatch_id.value);
          editType === "VENDOR" && (values.vendor_id = values.vendor_id.value);
          editType === "COMPONENT" &&
            (values.component = values.component.value);

          dispatch(updateOrderDetails(values));
          props.onClickYes();
        }}
      >
        {({
          handleChange,
          handleSubmit,
          isSubmitting,
          values,
          event,
          resetForm,
          setFieldValue,
          handleBlur,
          errors,
          touched,
        }) => (
          <>
            <Box
              component='form'
              sx={{
                "& .MuiTextField-root": { m: 1, minWidth: "50ch" },
              }}
              noValidate
              autoComplete='off'
            >
              {editType === "PAYMENT" && (
                <>
                  {errors.payment_type && touched.payment_type ? (
                    <p className='text-danger mt-1 mx-2'>
                      {errors.payment_type}
                    </p>
                  ) : null}
                  <div className='d-flex flex-column mb-3'>
                    <p className={Styles.label}>Payment Mode</p>
                    <div className='w-100 mt-2'>
                      <SelectField
                        name='payment_type'
                        options={payments}
                        onChange={setFieldValue}
                        // value={values?.hospital}
                        placeholder={props.payment}
                        onBlur={handleBlur}
                      />
                    </div>{" "}
                  </div>
                  <div>
                    <Form.Control
                      as='textarea'
                      onChange={handleChange}
                      name='payment_details'
                      placeholder='Payment Details'
                      style={{ height: "100px" }}
                    />
                  </div>
                </>
              )}

              {editType === "QUANTITY" && (
                <>
                  {errors.qty && touched.qty ? (
                    <p className='text-danger mt-1 mx-2'>{errors.qty}</p>
                  ) : null}
                  <div className='d-flex flex-column mb-3'>
                    <p className={Styles.label}>Quantity</p>
                    <div>
                      <Form.Control
                        type='number'
                        onChange={handleChange}
                        name='qty'
                        placeholder={props.qty}
                      />
                    </div>
                  </div>
                </>
              )}

              {editType === "VENDOR" && (
                <>
                  {errors.vendor_id && touched.vendor_id ? (
                    <p className='text-danger mt-1 mx-2'>{errors.vendor_id}</p>
                  ) : null}
                  <div className='d-flex flex-column mb-3'>
                    <p className={Styles.label}>Vendor</p>
                    <div className='w-100 mt-2'>
                      <SelectField
                        name='vendor_id'
                        options={lab}
                        onChange={setFieldValue}
                        placeholder={props.vendor}
                        onBlur={handleBlur}
                      />
                    </div>{" "}
                  </div>
                </>
              )}

              {editType === "COMPONENT" && (
                <>
                  {errors.b_type && touched.b_type ? (
                    <p className='text-danger mt-1 mx-2'>{errors.b_type}</p>
                  ) : null}
                  <div className='d-flex flex-column mb-3'>
                    <p className={Styles.label}>Blood Type</p>
                    <div className='w-100 mt-2 text-uppercase'>
                      <SelectField
                        name='b_type'
                        options={bloodType}
                        onChange={setFieldValue}
                        placeholder={props.b_type}
                        onBlur={handleBlur}
                      />
                    </div>{" "}
                  </div>

                  {errors.component && touched.component ? (
                    <p className='text-danger mt-1 mx-2'>{errors.component}</p>
                  ) : null}
                  <div className='d-flex flex-column mb-3'>
                    <p className={Styles.label}>Blood Component</p>
                    <div className='w-100 mt-2 text-uppercase'>
                      <SelectField
                        name='component'
                        options={bloodComponent}
                        onChange={setFieldValue}
                        placeholder={props.bloodComponent}
                        onBlur={handleBlur}
                      />
                    </div>{" "}
                  </div>
                </>
              )}

              {editType === "DISPATCH" && (
                <>
                  {errors.dispatch_id && touched.dispatch_id ? (
                    <p className='text-danger mt-1 mx-2'>
                      {errors.dispatch_id}
                    </p>
                  ) : null}
                  <div className='d-flex flex-column mb-3'>
                    <p className={Styles.label}>Dispatch</p>
                    <div className='w-100 mt-2'>
                      <SelectField
                        name='dispatch_id'
                        options={riderDropdown}
                        onChange={setFieldValue}
                        placeholder={props.dispatch}
                        onBlur={handleBlur}
                      />
                    </div>{" "}
                  </div>
                </>
              )}

              <div className='d-flex justify-content-center mt-3'>
                <button
                  type='submit'
                  className={` shadow-none ${Styles.actionBtn} ${Styles.confirm} `}
                  style={{ background: props.btnColor }}
                  onClick={() => props.onClickNo()}
                >
                  Cancel
                </button>

                <button
                  type='submit'
                  className={` shadow-none  ${Styles.actionBtn} ${Styles.reject}`}
                  style={{ background: props.btnColor }}
                  onClick={handleSubmit}
                >
                  Update
                </button>
              </div>
            </Box>
          </>
        )}
      </Formik>
    </>
  );
}
