import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getSingleQuotation } from "../../redux/admin/actions/admin.actions";
import { SyncOutlined } from "@ant-design/icons";
import axiosClient from "../../service/axios";
import {
  orderCreateSuccessToast,
  financeNotApproved,
  failedOrder,
  sendFinance,
} from "../../components/NotificationToast";
import style from "./Quote.module.css";

const Quote = ({ setIsOpen, id, onProceed, data }) => {
  const [showSpinner, setShowSpinner] = useState(false);
  const [loading, setLoading] = useState(true);
  const { singleQuotation } = useSelector((state) => state.adminReducer);

  console.log("seeee", singleQuotation)


  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getSingleQuotation(id))
      // setLoading(false);
      .then(() => setLoading(false)) 
      .catch(() => setLoading(false));
  }, [dispatch, id]);

  const handleYesButton = async () => {
    setShowSpinner(true);
    try {
      const response = await axiosClient.post(
        `/lite/v2/api/admin/quotations/send/` + id,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response && response.data.status === 400) {
        financeNotApproved(response.data.message);
        setTimeout(() => {
          setIsOpen(false);
        }, 2000);
      } else {
        onProceed(id);
        setShowSpinner(false);
        setIsOpen(false);
        orderCreateSuccessToast();
      }
    } catch (error) {
      setShowSpinner(false);
      failedOrder();
    }
  };

  return (
    <>
      <div className={style.darkBG} onClick={() => setIsOpen(false)} />
      <div className={style.centered}>
        <div className={style.modal}>
          <div>
            <table className={style.table} style={{ borderRadius: "10px" }}>
              <thead>
                <tr className={style.headerrow}>
                  <th className={style.headeritem}>Product</th>
                  <th className={style.headeritem}>Quantity</th>
                  <th className={style.headeritem}>Brand</th>
                  <th className={style.headeritem}>Qunatity type</th>

                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td colSpan="2">
                      <div className={style.loadingCell}>
                        <SyncOutlined spin />
                      </div>
                    </td>
                  </tr>
                ) : (
                  singleQuotation?.items?.map((items, index) => (
                    <tr key={index} className={style.container}>
                      <td className={style.card}>
                        <div className={style.cardText}>{items.name}</div>
                      </td>
                      <td className={style.card}>
                        <div className={style.cardText}>{items.qty}</div>
                      </td>
                      <td className={style.card}>
                        <div className={style.cardText}>{items.brand}</div>
                      </td>
                      <td className={style.card}>
                        <div className={style.cardText}>{items.quantitytype}</div>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
        <div className={style.proceedContainer} onClick={handleYesButton}>
          {showSpinner ? <SyncOutlined spin /> : "Proceed"}
        </div>
      </div>
    </>
  );
};

export default Quote;

// <div style={{ display: "flex", justifyContent: "center" }}>
//           {/* Render the static button */}
//           {loading === false ? (
//             <div className={style.proceedContainer} onClick={handleYesButton}>
//               {showSpinner ? <SyncOutlined spin /> : "Proceed"}
//             </div>
//           ) : (
//             <div className={style.proceedContainer}>
//               <FcCancel style={{color: "white"}} />
//             </div>
//           )}

//           {/* Render the dynamic button */}
//           {loading === true ? (
//             <div className={style.proceedFin} onClick={sendToFinance}>
//               {showSpinner ? <SyncOutlined spin /> : "Send To Finance"}
//             </div>
//           ) : (
//             <div className={style.proceedFin}>
//               <FcCancel />
//             </div>
//           )}
//         </div>
