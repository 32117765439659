import React from "react";
import style from "./Size.module.scss";
import { TbVaccineBottle } from "react-icons/tb";

const CylinderChild = ({ icon, size, onClick }) => {
  return (
    <div className={style.container}>
      <div className={style.card} onClick={() => onClick()}>
        <div className={style.iconWrapper}>
          <TbVaccineBottle size={40} color="white" />
        </div>
        <p className={style.text}>{size}</p>
      </div>
    </div>
  );
};

export default CylinderChild;
