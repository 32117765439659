import React, { useState } from "react";
import { FaDotCircle } from "react-icons/fa";
import { MdAccessTimeFilled } from "react-icons/md";
import { ConfirmationModal } from "../Modal/ConfirmationModal";
import style from "./Orders.module.scss";
import ModalInstruction from "../modalInstruction/ModalInstruction";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { trackOrder } from "../../redux/orders/actions/orders.actions";

const OverdueOrder = ({
  closed,
  order_id,
  name,
  b_type,
  order_amt,
  vendor_name,
  vendor_address,
  vendor_state,
  order_time,
  tym,
  delay_time,
  order_state,
  blood,
  title,
}) => {
  const [openModal, setopenModal] = useState(false);

  const bgColors = () => {
    if (title === "blood") return "#FDE0E0";
    if (title === "oxygen") return "#E0EFFD";
    return "#F0FDE0";
  };

  const colors = () => {
    if (title === "blood") return "#B70303";
    if (title === "oxygen") return "#2457C5";
    return "#78A55A";
  };

  const dispatch = useDispatch();

  const navigate = useNavigate();
  const track = () => {
    dispatch(
      trackOrder(order_id, { title: title === "blood" ? "Blood" : title })
    );
    navigate(`/track-order/${order_id}`);
  };

  const closeModal = () => {
    setopenModal(false);
  };

  return (
    <div className={style.container}>
      <div onClick={() => setopenModal(true)}>
        <div className="d-flex justify-content-between">
          <p className={style.header}>Tracking Number</p>
          <p className={style.header}>Status</p>
        </div>
        <div className="d-flex justify-content-between">
          <p className={style.content1}>
            {title === "blood"
              ? `LB-${order_id}`
              : title === "oxygen"
              ? `LBO-${order_id}`
              : title === "stockbank"
              ? `SBO-${order_id}`
              : ``}
          </p>
          <p className={style.status}>overdue</p>
        </div>
        <div className="d-flex justify-content-between">
          <p className={style.content}>
            {" "}
            <span>
              <FaDotCircle color="red" />
            </span>{" "}
            {b_type}
          </p>
          <p className={style.content2}>{order_time}</p>
        </div>
        <div className="d-flex justify-content-between">
          <p className={style.content}>
            {" "}
            <span>
              <FaDotCircle color="blue" />
            </span>{" "}
            {vendor_name}
          </p>
          <p className={style.content2}>{tym}</p>
        </div>
        <div className="d-flex justify-content-between">
          <p className={style.delay}>
            {" "}
            <span>
              <MdAccessTimeFilled color="red" />
            </span>{" "}
            Delay Time
          </p>
          <p className={style.delay}>{delay_time?.split(":")[0]} Hours</p>
        </div>
      </div>

      {/* DIALOG MODAL */}
      <ConfirmationModal
        open={openModal}
        handleClose={() => setopenModal(false)}
        closemodal={closeModal}
        bgcolor="background.paper"
        width="75vw"
        instruction={
          <ModalInstruction
            status="Ongoing"
            button1="CANCEL"
            button2="TRACK ORDER"
            onClickNo={closeModal}
            onClickYes={track}
            headerColor={colors()}
            categoryBg={bgColors()}
            categoryColor={colors()}
            orderId={
              title === "blood"
                ? `LB-${order_id}`
                : title === "oxygen"
                ? `LBO-${order_id}`
                : title === "stockbank"
                ? `SBO-${order_id}`
                : ``
            }
            hospitalName={name}
            productName={b_type}
            orderStatus={order_state}
            category={title}
            qty={order_amt}
            vendor={vendor_name}
            address={`${vendor_address}, ${vendor_state}`}
            orderTime={order_time}
            delay={`${delay_time?.split(":")[0]} Hours, ${
              delay_time?.split(":")[1]
            } Minutes`}
          />
        }
      />
    </div>
  );
};

export default OverdueOrder;
