import React from "react";
import { Col, Row } from "react-bootstrap";
import { IoIosArrowDropleftCircle } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FaHandshake } from "react-icons/fa";
import style from "./Exchange.module.scss";
import { saveOxygenType } from "../../../../redux/new-order/actions/new-orders.actions";

const CylinderSize = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  let userDetails;
  if (localStorage.getItem("userToken")) {
    userDetails = JSON.parse(localStorage.getItem("userToken"));
  }

  if (!userDetails) {
    navigate("/");
  }

  const { orderType } = useSelector((state) => state.newOrderReducer);

  const colors = () => {
    if (orderType?.cardText === "BLOOD") return "#B70303";
    if (orderType?.cardText === "OXYGEN") return "#0779F7";
    if (orderType?.cardText === "STOCKBANK") return "#066304";
    return "#DA621B";
  };

  const handleYes = () => {
    dispatch(saveOxygenType("Exchange"));
    navigate("/place-order/size");
  };

  return (
    <div style={{ width: "97%" }}>
      <Row>
        <Col md={1}>
          <IoIosArrowDropleftCircle
            size={50}
            style={{ cursor: "pointer" }}
            onClick={() => navigate(-1)}
            color={colors()}
          />
        </Col>
        <Col md={10}>
          <div className={style.form}>
            <div className={style.outerContainer}>
              <div className={style.iconContainer}>
                <FaHandshake
                  color="#0779F7"
                  size={60}
                  style={{ transform: "rotate(-45deg)" }}
                />
              </div>
              <div className={style.container}>
                <p className={style.size}>cylinder Exchange</p>
              </div>
              <p className={style.question}>
                Your empty cylinder will be exchanged for a full Lifebank
                cylinder
              </p>
              <div className={style.button} onClick={handleYes}>
                continue
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default CylinderSize;
