import React from "react";
import { Col, Row } from "react-bootstrap";
import style from "./BloodComponent.module.scss";
import { IoIosArrowDropleftCircle } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { genotype } from "../../../data";
import Blood from "../../../components/InfoCard/Blood";
import { saveGenotype } from "../../../redux/new-order/actions/new-orders.actions";
import { useDispatch } from "react-redux";

const Genotype = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  let userDetails;
  if (localStorage.getItem("userToken")) {
    userDetails = JSON.parse(localStorage.getItem("userToken"));
  }

  if (!userDetails) {
    navigate("/");
  }

  const processHandler = (genotype) => {
    // dispatch(saveOrderType(genotype));
    dispatch(saveGenotype(genotype));
    navigate("/order-information");
  };

  return (
    <div style={{ width: "97%" }}>
      <Row>
        <Col md={1}>
          <IoIosArrowDropleftCircle
            size={50}
            color="#b70303"
            style={{ cursor: "pointer" }}
            onClick={() => navigate(-1)}
          />
        </Col>
        <Col md={10}>
          <h3 className={style.header}>SELECT GENOTYPE</h3>
          <div className={style.cards}>
            {genotype.map((group) => (
              <Blood
                {...group}
                onClick={() => processHandler(group)}
                key={group?.id}
              />
            ))}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Genotype;
