import React, { useState } from "react";
import Details from "../../../components/InfoCard/Details";
import { BsInboxesFill } from "react-icons/bs";
import { FaRegMoneyBillAlt, FaMoneyBillWave } from "react-icons/fa";
import { RiDashboardFill, RiBillFill } from "react-icons/ri";
import { ImDroplet } from "react-icons/im";
import { IoIosArrowDropleftCircle } from "react-icons/io";
import { GiScubaTanks, GiFirstAidKit, GiPill } from "react-icons/gi";
import { SyncOutlined } from "@ant-design/icons";
import style from "./Information.module.scss";
import Naira from "react-naira";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { SuccessModal } from "../../../components/Modal/SuccessModal";
import MessageInstruction from "../../../components/modalInstruction/MessageInstruction";
import { processOrder } from "../../../redux/orders/actions/orders.actions";
import { Col, Row } from "react-bootstrap";

const OrderDetail = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [successModal, setSuccessModal] = useState(false);
  const [loading, setLoading] = useState(false);

  let userDetails;
  if (localStorage.getItem("userToken")) {
    userDetails = JSON.parse(localStorage.getItem("userToken"));
  }

  let hmo;
  if (localStorage.getItem("hmo")) {
    hmo = JSON.parse(localStorage.getItem("hmo"));
  }

  if (!userDetails) {
    navigate("/");
  }

  const {
    orderType,
    // hospitalDetails,
    stockbank,
    agentType,
    paymentMethod,
  } = useSelector((state) => state.newOrderReducer);


  const colors = () => {
    if (orderType?.cardText === "BLOOD") return "#B70303";
    if (orderType?.cardText === "OXYGEN") return "#0779F7";
    if (orderType?.cardText === "STOCKBANK") return "#066304";
    return "#DA621B";
  };

  const badgeColor = () => {
    if (orderType?.cardText === "BLOOD") return "#FDE0E0";
    if (orderType?.cardText === "OXYGEN") return "#E0EFFD";
    return "#F0FDE0";
  };

  const icons = () => {
    if (orderType?.cardText === "BLOOD")
      return <ImDroplet size={20} color={colors()} />;
    if (orderType?.cardText === "OXYGEN")
      return <GiScubaTanks size={20} color={colors()} />;
    if (orderType?.cardText === "STOCKBANK")
      return <BsInboxesFill size={20} color={colors()} />;
    return <GiFirstAidKit size={20} color={colors()} />;
  };

  let total = stockbank?.checked
    .reduce((acc, item) => acc + item.qty * item.cost, 0)
    .toFixed(2);

  const data = {
    // id: index + 1,
    agentType: agentType,
    title: orderType?.cardText,
    products: stockbank?.checked,
    payment_type: paymentMethod?.label,
    totalPrice: total,
    order_by: stockbank?.hospital,
    hmo: hmo?.hospital,
  };

  function delay(ms) {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  }

  const processFunc = async () => {
    localStorage.setItem("newOrder", JSON.stringify(data));
    dispatch(
      processOrder({
        title:
          orderType?.cardText === "STOCKBANK"
            ? "Stockbank"
            : orderType?.cardText,
        order_by: stockbank?.hospital,
      })
    );
    setLoading(true);
    await delay(2000);
    setLoading(false);
    navigate("/process-orders");
  };

  return (
    <div style={{ width: "97%" }}>
      <Row className={style.header} style={{ background: colors() }}>
        <Col md={1}>
          <IoIosArrowDropleftCircle
            size={50}
            style={{ cursor: "pointer" }}
            onClick={() => navigate(-1)}
            color="white"
          />
        </Col>
        <Col md={8} className="text-center">
          CONFIRM ORDER
        </Col>
      </Row>
      <Details icon={icons()} label={"PRODUCT"} text={orderType?.cardText} />

      {stockbank?.checked?.map((stock) => (
        <Details
          icon={<GiPill size={20} color={colors()} />}
          label={`${stock?.name} x ${stock?.qty}`}
          // text={<Naira>{stock?.cost * stock?.qty}</Naira>}
          text={"Ksh " + stock?.cost * stock?.qty}
        />
      ))}

      <div className="mt-4 mb-4">
        <Details
          icon={<RiDashboardFill size={20} color={colors()} />}
          label={"PRODUCTS"}
          text={stockbank?.checked?.length}
        />
        <Details
          icon={<FaRegMoneyBillAlt size={20} color={colors()} />}
          label={"PAYMENT METHOD"}
          text={paymentMethod?.label}
        />
      </div>

      <Details
        icon={<FaMoneyBillWave size={20} color={colors()} />}
        label={"PRICE"}
        // text={<Naira>{total}</Naira>}
        text={"Ksh " +total}
      />
      {/* <Details
        icon={<RiCouponFill size={20} color="green" />}
        label={"ENTER PROMO CODE"}
        text={""}
      /> */}
      <Details
        icon={<RiBillFill size={20} color={colors()} />}
        label={"TOTAL"}
        // text={<Naira>{total}</Naira>}
        text={"Ksh " +total}
      />

      <button
        className={style.btn}
        onClick={processFunc}
        style={{ background: colors() }}
      >
        {loading ? <SyncOutlined spin /> : "PLACE ORDER"}
      </button>

      {/* create MODAL */}
      <SuccessModal
        open={successModal}
        onClose={processFunc}
        bgcolor={colors()}
        width="55vw"
        height="60vh"
        instruction={
          <MessageInstruction
            heading={"SUCCESSFUL!!"}
            content={" Your order has been placed successfully"}
            button="COMPLETE"
            onClickBtn={processFunc}
            color={badgeColor()}
          />
        }
      />
    </div>
  );
};

export default OrderDetail;
