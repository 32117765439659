import React, { useEffect, useState } from "react";
import ProcessCard from "../../../components/orders/process/ProcessCard";
import style from "./Process.module.scss";
import { IoIosArrowDropleftCircle } from "react-icons/io";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import RadioGroup, { useRadioGroup } from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import ProcessOrderSplit from "../../../components/orders/process/ProcessOrderSplit";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  labXOrders,
  oxygenList,
} from "../../../redux/orders/actions/orders.actions";
import {
  processBoatOrder,
  processExistingOrder,
} from "../../../redux/new-order/actions/new-orders.actions";
import { SuccessModal } from "../../../components/Modal/SuccessModal";
import MessageInstruction from "../../../components/modalInstruction/MessageInstruction";
import { failedOrder } from "../../../components/NotificationToast";
import ProcessOrderSingle from "../../../components/orders/process/ProcessOrderSingle";
import { Col, Row } from "react-bootstrap";

const ProcessOrder = () => {
  const navigate = useNavigate();
  const [successModal, setSuccessModal] = useState(false);
  const [dataMatch, setDataMatch] = useState();
  const { id } = useParams();
  // const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  let userDetails;
  if (localStorage.getItem("userToken")) {
    userDetails = JSON.parse(localStorage.getItem("userToken"));
  }

  useEffect(() => {
    dispatch(labXOrders());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  const { labxOrders } = useSelector((state) => state.orderReducer);
  const { boatOrder, existingOrder } = useSelector(
    (state) => state.newOrderReducer
  );

  const getOrderFromID = () => {
    let order;

    for (let category in labxOrders) {
      let match = labxOrders[category].find(
        (o) => o?.order_id === id || o?.id === id
      );
      if (match) {
        order = match;
        break;
      }
    }

    return order;
  };

  let detail = getOrderFromID();

  console.log("details:::", detail)

  let boat;
  if (localStorage.getItem("boat"))
    boat = JSON.parse(localStorage.getItem("boat"));

  useEffect(() => {
    dispatch(oxygenList()).then((response) => {
      let match = response?.data?.find((t) => t?.id === boat?.product_name);
      if (match) {
        setDataMatch(match);
      }
    });
  }, [boat?.product_name, dispatch]);
  // console.log(dataMatch?.size);

  let precheck;
  if (localStorage.getItem("precheck"))
    precheck = JSON.parse(localStorage.getItem("precheck"));

  let vendor = precheck?.vendor;
  let rider = precheck?.Dispatch;
  let status = precheck?.status;

  const response1 = {
    agent: userDetails?.id,
    vendor_id: vendor && vendor[0]?.ref_id,
    dispatch_id: rider && rider[0]?.dispatch_id,
    order_id: detail?.title === "oxygen" ? detail?.id : detail?.order_id,
    title:
      detail?.title === "blood"
        ? "Blood"
        : detail?.title === "oxygen"
        ? "Oxygen"
        : detail?.title,
  };

  const response2 = {
    agent: userDetails?.id,
    vendor_id: vendor && vendor[1]?.ref_id,
    dispatch_id: rider && rider[1]?.dispatch_id,
    order_id: detail?.title === "oxygen" ? detail?.id : detail?.order_id,
    title:
      detail?.title === "blood"
        ? "Blood"
        : detail?.title === "oxygen"
        ? "Oxygen"
        : detail?.title,
  };

  const boatResponse1 = {
    agent: userDetails?.id,
    vendor_id: vendor && vendor[0]?.ref_id,
    dispatch_id: rider && rider[0]?.dispatch_id,
    order_id: detail?.order_id,
    title:
      detail?.title === "blood"
        ? "Blood"
        : detail?.title === "oxygen"
        ? "Oxygen"
        : detail?.title,
    order_by: boat?.order_by,
    qty: boat?.qty,
    product: boat?.product_name,
    bloodtype: boat?.bloodtype,
  };

  const boatResponse2 = {
    agent: userDetails?.id,
    vendor_id: vendor && vendor[1]?.ref_id,
    dispatch_id: rider && rider[1]?.dispatch_id,
    order_id: detail?.order_id,
    title:
      detail?.title === "blood"
        ? "Blood"
        : detail?.title === "oxygen"
        ? "Oxygen"
        : detail?.title,
    order_by: boat?.order_by,
    qty: boat?.qty,
    product: boat?.product_name,
    bloodtype: boat?.bloodtype,
  };

  const handleProcess1 = () => {
    if (detail?.channel === "Boat") {
      dispatch(processBoatOrder(boatResponse1));
      if (boatOrder?.status === 300) {
        failedOrder();
      } else {
        setSuccessModal(true);
      }
    } else {
      dispatch(processExistingOrder(response1));
      if (existingOrder?.status === 300) {
        failedOrder();
      } else {
        setSuccessModal(true);
      }
    }
  };

  const handleProcess2 = () => {
    if (detail?.channel === "Boat") {
      dispatch(processBoatOrder(boatResponse2));
      if (boatOrder?.status === 300) {
        failedOrder();
      } else {
        setSuccessModal(true);
      }
    } else {
      dispatch(processExistingOrder(response2));
      if (existingOrder?.status === 300) {
        failedOrder();
      } else {
        setSuccessModal(true);
      }
    }
  };

  let checked;
  const StyledFormControlLabel = styled((props) => (
    <FormControlLabel {...props} />
  ))(({ _theme, checked }) => ({
    ".MuiFormControlLabel-label": checked && {
      color: "black",
    },
  }));

  function MyFormControlLabel(props) {
    const radioGroup = useRadioGroup();

    checked = false;

    if (radioGroup) {
      checked = radioGroup.value === props?.value;
    }

    return <StyledFormControlLabel checked={checked} {...props} />;
  }

  MyFormControlLabel.propTypes = {
    /**
     * The value of the component.
     */
    value: PropTypes.any,
  };

  const navigateFunc = () => {
    navigate("/ongoing-orders");
  };

  const colors = () => {
    if (detail?.title === "Blood") return "#B70303";
    if (detail?.title === "oxygen") return "#0779F7";
    if (detail?.title === "Stockbank") return "#066304";
    return "#B70303";
  };

  const badgeColor = () => {
    if (detail?.title === "Blood") return "#FDE0E0";
    if (detail?.title === "oxygen") return "#E0EFFD";
    return "#B70303";
  };

  return (
    <div style={{ width: "97%" }}>
      {status === 300 ? (
        <Row className="d-flex align-items-center mb-4">
          <Col md={1}>
            <IoIosArrowDropleftCircle
              size={50}
              style={{ cursor: "pointer" }}
              onClick={() => navigate(-1)}
              color={colors()}
            />
          </Col>
          <Col md={8}>
            <p className={style.warning}>
              No Match Available. Please try again
            </p>
          </Col>
        </Row>
      ) : (
        <>
          <p className={style.header}>BEST MATCH</p>
          {vendor[0] ? (
            <ProcessCard
              id="1"
              name={vendor[0]?.name}
              address={vendor[0]?.address}
              dispatch_name={rider[0]?.dispatch_name}
              dispatchAddress=""
              distance={`${parseInt(vendor[0]?.distance * (65 / 60))} Mins`}
              onClick={handleProcess1}
              color={colors()}
              borderColor={colors()}
              bgColor={colors()}
            />
          ) : (
            <></>
          )}

          {vendor[1] && rider[1] ? (
            <ProcessCard
              id={"2"}
              name={vendor[1]?.name}
              address={vendor[1]?.address}
              dispatch_name={rider[1]?.dispatch_name}
              dispatchAddress=""
              distance={`${parseInt(vendor[1]?.distance * (65 / 60))} Mins`}
              onClick={handleProcess2}
              color={colors()}
              borderColor={colors()}
              bgColor={colors()}
            />
          ) : (
            <></>
          )}

          <p className={style.header}>CUSTOM MATCH</p>

          <RadioGroup name="use-radio-group" defaultValue="single">
            <MyFormControlLabel
              value="single"
              label="SINGLE DELIVERY"
              control={
                <Radio
                  sx={{
                    color: "gray",
                    "&.Mui-checked": {
                      color: colors(),
                    },
                  }}
                />
              }
            />
            <ProcessOrderSingle
              title={detail?.title === "blood" ? "Blood" : detail?.title}
              order_id={
                detail?.title === "blood" || detail?.channel === "Boat"
                  ? detail?.order_id
                  : detail?.id
              }
              agent={userDetails?.id}
              color={colors()}
              border={colors()}
              textColor={colors()}
              badgeColor={badgeColor()}
              vendor={vendor}
              rider={rider}
              qty={
                detail?.title === "blood" && detail?.channel !== "Boat"
                  ? detail?.order_amt
                  : detail?.channel === "Boat"
                  ? boat.qty
                  : detail?.qty
              }
              boatType={detail?.channel === "Boat"}
              order_by={boat?.order_by}
              blood={boat?.product_name}
              bloodtype={boat?.bloodtype}
            />

            {
              <>
                <MyFormControlLabel
                  value="split"
                  label="SPLIT DELIVERY"
                  control={
                    <Radio
                      sx={{
                        color: "gray",
                        "&.Mui-checked": {
                          color: colors(),
                        },
                      }}
                    />
                  }
                />

                <ProcessOrderSplit
                  order_by={
                    detail?.channel === "Boat"
                      ? boat?.order_by
                      : detail?.order_by
                  }
                  order_id={
                    detail?.title === "blood" || detail?.channel === "Boat"
                      ? detail?.order_id
                      : detail?.id
                  }
                  agent={userDetails?.id}
                  name={detail?.blood}
                  product={
                    detail?.title === "blood" && detail?.channel !== "Boat"
                      ? detail?.b_type
                      : detail?.channel === "Boat" && detail?.title === "blood"
                      ? boat.bloodtype
                      : detail?.channel === "Boat" && detail?.title === "oxygen"
                      ? dataMatch?.size
                      : detail?.size
                  }
                  bloodtype={
                    detail?.channel === "Boat"
                      ? boat?.bloodtype
                      : detail?.b_type
                  }
                  crossmatch={detail?.Cross_matching}
                  payment={detail?.channel}
                  usage={detail?.usage_info}
                  schedule_date={detail?.schedule_date}
                  schedule_time={detail?.schedule_time}
                  temperature={""}
                  security={""}
                  qty={
                    detail?.title === "blood" && detail?.channel !== "Boat"
                      ? detail?.order_amt
                      : detail?.channel === "Boat"
                      ? boat.qty
                      : detail?.qty
                  }
                  title={
                    detail?.title === "blood"
                      ? "Blood"
                      : detail?.title === "oxygen"
                      ? "Oxygen"
                      : detail?.title
                  }
                  boatType={detail?.channel === "Boat"}
                  blood={boat?.product_name}
                  color={colors()}
                  border={colors()}
                  textColor={colors()}
                  vendor={vendor}
                  rider={rider}
                />
              </>
            }
          </RadioGroup>
        </>
      )}

      {/* create MODAL */}
      <SuccessModal
        open={successModal}
        onClose={navigateFunc}
        bgcolor={colors()}
        width="55vw"
        height="60vh"
        instruction={
          <MessageInstruction
            heading={"SUCCESSFUL!!"}
            content={" Your order has been placed successfully"}
            button="COMPLETE"
            onClickBtn={navigateFunc}
            color={badgeColor()}
          />
        }
      />
    </div>
  );
};

export default ProcessOrder;
