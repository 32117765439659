import { somethingWentWrongToast } from "../../../components/NotificationToast";
import {
  LOAD_DASHBOARD_COUNT,
  TICKET,
  SHOW_ALL_PO,
  GET_ALL_QUOTATION,
  SINGLE_QUOTATION,
  BID_QUOTATION,
} from "../../types";
import AdminService from "../services/admin.service";

//Load dashboard count
export const loadDashboardCount = () => async (dispatch) => {
  try {
    const response = await AdminService.fetchDashboardCount();

    dispatch({
      type: LOAD_DASHBOARD_COUNT,
      payload: response.data.data,
    });

    return response;
  } catch (error) {
    somethingWentWrongToast();
  }
};

//Load tickets
export const loadTickets = () => async (dispatch) => {
  try {
    const response = await AdminService.fetchTickets();

    dispatch({
      type: TICKET,
      payload: response.data,
    });

    return response;
  } catch (error) {
    somethingWentWrongToast();
  }
};

export const showAllPo = () => async (dispatch) => {
  try {
    const response = await AdminService.showAllPo();
    dispatch({
      type: SHOW_ALL_PO,
      payload: response.data.data,
    });
  } catch (error) {
    somethingWentWrongToast();
  }
};

export const seeAllQuotation = () => async (dispatch) => {
  try {
    const response = await AdminService.seeQuotation();
    dispatch({
      type: GET_ALL_QUOTATION,
      payload: response.data.data,
    });
    return response;
  } catch (error) {
    somethingWentWrongToast();
  }
};

export const seeBidQuotation = () => async (dispatch) => {
  try {
    const response = await AdminService.bidQuotation();
    dispatch({
      type: BID_QUOTATION,
      payload: response.data.data,
    });
    return response;
  } catch (error) {
    somethingWentWrongToast();
  }
};

export const getSingleQuotation = (id) => async (dispatch) => {
  try {
    const response = await AdminService.singleQuotation(id);
    // console.log("response", response.data);
    dispatch({
      type: SINGLE_QUOTATION,
      payload: response.data,
    });
    return response;
  } catch (error) {
    somethingWentWrongToast();
  }
};
