import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./po.module.css";
// import PoModal from "./PoModal";
import { useSelector, useDispatch } from "react-redux";
import { showAllPo } from "../../../redux/admin/actions/admin.actions";
import pdf from "../../../assets/images/pdf.png";
import CircularLoader from "../../Loader/CircularLoader";
import { GiGunStock } from "react-icons/gi";
import { FiSettings } from "react-icons/fi";
import { AiFillMedicineBox } from "react-icons/ai";

const Po = () => {
  const navigate = useNavigate();
  // const [showModal, setShowModal] = useState(false);
  // const [selectedItem, setSelectedItem] = useState(null);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const orders = useSelector((state) => state.adminReducer.showPo);

  // console.log("orders", orders);
  useEffect(() => {
    setLoading(true);
    dispatch(showAllPo());
    setLoading(false);
  }, []);

  const openModal = (po) => {
    // console.log("pooooooo", po)
    // setSelectedItem(po);
    // setShowModal(true);
    navigate(`/podetails/${po}`);
  };

  return (
    <div>
      <p className={styles.title}> purchase order</p>
      <div>
        {loading && <CircularLoader />}
        {orders && orders.length > 0 ? (
          orders?.map((po, index) => (
            <Card
              key={index}
              name={po.name}
              id={po.hospitalid}
              product={po.quipOrStock}
              hospitalName={po.hospitalName}
              Image={po.po}
              item={po}
              onClick={() => openModal(po.id)}
            />
          ))
        ) : (
          <div className={styles.emptyMessage}>No Orders For Now!</div>
        )}
      </div>
      <div className={styles.actualmodal}>
        {/* {showModal && (
          <PoModal item={selectedItem} setShowModal={setShowModal} />
        )} */}
      </div>
    </div>
  );
};

export default Po;

const Card = ({ name, product, Image, item, onClick, id }) => {
  const isPDF = Image.toLowerCase().endsWith(".pdf");
  const isImage = /\.(jpeg|jpg|png|gif|bmp)$/i.test(Image);

  const badgeColor = () => {
    if (product === "stock") return "#FDE0E0";
    if (product === "Quip") return "#ffd4c2";
    return "#F0FDE0";
  };

  const icons = () => {
    if (product === "stock") return <GiGunStock size={25} color="#B70303" />;
    if (product === "Quip") return <FiSettings size={25} color="#c7460f" />;
    return <AiFillMedicineBox size={25} color="#78A55A" />;
  };

  return (
    <div onClick={onClick}>
      <div className={styles.cardContainer}>
        <div className={styles.container}>
          <div className={styles.contain}>
            <div className={styles.innerContainer}>
              <div className={styles.content}>
                <div className="d-flex flex-column">
                  <p className={styles.header}>HOSPITAL NAME</p>
                  <p className={styles.text}>{name}</p>
                </div>
              </div>
              <div className={styles.content}>
                <div
                  className={`${styles.icon}`}
                  style={{ background: badgeColor() }}
                >
                  {icons()}
                </div>
                <div className="d-flex flex-column">
                  <p className={styles.header}>Product Name</p>
                  <p className={styles.text}>{product}</p>
                </div>
              </div>
              <div className={styles.content}>
                <div className="d-flex flex-column">
                  <p className={styles.header}>Hospital ID:</p>
                  <p className={styles.text}>{id}</p>
                </div>
              </div>

              <div className={styles.images}>
                {isPDF ? (
                  <img src={pdf} alt="pdfLogo" className={styles.image} />
                ) : (
                  isImage && (
                    <img src={Image} alt="pix" className={styles.image} />
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
