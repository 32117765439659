import React, { useEffect, useState } from "react";
import { FaUserPlus, FaUserMd } from "react-icons/fa";
import { GiMedicalPack } from "react-icons/gi";
import style from "./boat.module.scss";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { constructOrderDate } from "../../../helper/DateFormatter";
import { ConfirmationModal } from "../../Modal/ConfirmationModal";
import { SyncOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import {
  hospitalList,
  oxygenList,
  processOrder,
} from "../../../redux/orders/actions/orders.actions";
import { delay } from "../nerve/Nerve";
import { Formik } from "formik";
import { Form } from "react-bootstrap";
import styled from "../../orderInstruction/Instruction.module.scss";
import { SelectField } from "../../Form/SelectField";

let pType = [
  { id: 1, pt: "Blood" },
  { id: 2, pt: "Platelet" },
  { id: 3, pt: "Cryoprecipitate" },
  { id: 4, pt: "Fresh Frozen Plasma" },
  { id: 5, pt: "Packed Red Blood Cells" },
];

export const bloodGroup = [
  {
    id: 1,
    bloodGroup: "A+",
  },
  {
    id: 2,
    bloodGroup: "A-",
  },
  {
    id: 3,
    bloodGroup: "B+",
  },
  {
    id: 4,
    bloodGroup: "B-",
  },
  {
    id: 5,
    bloodGroup: "O+",
  },
  {
    id: 6,
    bloodGroup: "O-",
  },
  {
    id: 7,
    bloodGroup: "AB+",
  },
  {
    id: 8,
    bloodGroup: "AB-",
  },
];

const Boat = ({
  order_id,
  product,
  order_by,
  payment,
  qty,
  tym,
  order_type,
  unitprice,
  discount,
  created_at,
  updated_at,
  personnel_name,
  order_state,
  b_type,
  title,
  order_amt,
  usage_info,
  address,
  contact,
  name,
  size,
  city,
  Patient_Name,
  Doctor_Name,
  state,
}) => {
  const [openModal, setopenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [hospitalDropDown, setHospitalDropDown] = useState();
  const [oxygenDropdown, setOxygenDropdown] = useState();
  const [productType, setProductType] = useState(order_by);
  const [bloodType, setBloodType] = useState();

  const bgColor = () => {
    if (title === "blood") return "#FDE0E0";
    if (title === "oxygen") return "#E0EFFD";
    return "#F0FDE0";
  };

  const icon = () => {
    if (title === "blood") return <GiMedicalPack size={25} color="#B70303" />;
    if (title === "oxygen") return <GiMedicalPack size={25} color="#2457C5" />;
    return <GiMedicalPack size={25} color="#78A55A" />;
  };

  const color = () => {
    if (title === "blood") return "#B70303";
    if (title === "oxygen") return "#2457C5";
    return "#78A55A";
  };

  const navigate = useNavigate();

  const dispatch = useDispatch();

  // const data = {
  //   title: title === "blood" ? "Blood" : title,
  //   order_by: order_by,
  //   bloodtype: b_type,
  // };
  // const processHandler = async () => {
  //   dispatch(processOrder(data));
  //   setLoading(true);
  //   await delay(3000);
  //   setLoading(false);

  //   navigate(`/process-order/${order_id}`);
  // };

  useEffect(() => {
    let options = pType.map((r) => ({
      label: r?.pt,
      value: r.id,
    }));
    setProductType(options);
  }, []);

  useEffect(() => {
    let options = bloodGroup.map((r) => ({
      label: r?.bloodGroup,
      value: r.id,
    }));
    setBloodType(options);
  }, []);

  useEffect(() => {
    dispatch(hospitalList()).then((response) => {
      let options = response.data
        .sort((a, b) => a.label - b.label)
        .map((hosp) => ({
          label: `${hosp.Name} (${hosp.State}-${hosp.City})`,
          value: hosp.Hospital_ID,
        }));
      setHospitalDropDown(options);
    });
  }, [dispatch]);

  useEffect(() => {
    dispatch(oxygenList()).then((response) => {
      let options = response.data
        .sort((a, b) => a.value - b.value)
        .map((oxy) => ({
          label: `${oxy.size}`,
          value: oxy.id,
        }));
      setOxygenDropdown(options);
    });
  }, [dispatch]);

  const closemodal = () => {
    setopenModal(false);
  };

  return (
    <div className={style.container}>
      <div onClick={() => setopenModal(true)}>
        <div className={style.innerContainer}>
          <div className={style.content}>
            <div className={style.icon} style={{ background: bgColor() }}>
              {icon()}
            </div>
            <div className="d-flex flex-column">
              <p className={style.header}>Hospital Name</p>
              <p className={style.text}>{order_by}</p>
            </div>
          </div>

          <div className={style.content}>
            <div className={style.icon} style={{ background: bgColor() }}>
              <FaUserMd size={25} color={color()} />
            </div>
            <div className="d-flex flex-column">
              <p className={style.header}>Doctor Name</p>
              <p className={style.text}>{Doctor_Name}</p>
            </div>
          </div>

          <div className={style.content}>
            <div className={style.icon} style={{ background: bgColor() }}>
              <FaUserPlus size={25} color={color()} />
            </div>
            <div className="d-flex flex-column">
              <p className={style.header}>Patient Name</p>
              <p className={style.text}>{Patient_Name}</p>
            </div>
          </div>
          <div className={style.content}>
            <div className="d-flex flex-column">
              <p className={style.header}>Usage Info</p>
              <p className={style.text}>{usage_info}</p>
            </div>
          </div>

          <div className={style.content}>
            <div className="d-flex justify-content-center align-items-center">
              <Button
                className={style.button}
                style={{ border: `2px solid ${bgColor()}`, color: color() }}
                onClick={() => setopenModal(true)}
              >
                process
              </Button>
            </div>
          </div>
        </div>
        <div className={style.time}>
          Last update : {constructOrderDate(tym)}
        </div>
      </div>

      {/* DIALOG MODAL */}
      <ConfirmationModal
        open={openModal}
        handleClose={() => setopenModal(false)}
        bgcolor="background.paper"
        width="80%"
        closemodal={closemodal}
        instruction={
          <Formik
            initialValues={{
              order_by: "",
              product_name: "",
              bloodtype: "",
              qty: "",
              title: title,
            }}
            validate={(values) => {
              const errors = {};
              if (!values.order_by) {
                errors.order_by = "Hospital name is mandatory";
              }
              if (!values.product_name) {
                errors.product_name = "Product name is mandatory";
              }
              if (!values.qty) {
                errors.qty = "Quantity is mandatory";
              }
              if (!values.bloodtype && title === "blood") {
                errors.bloodtype = "Blood type is mandatory";
              }
              return errors;
            }}
            onSubmit={async (values, { setSubmitting }) => {
              values.order_by = values.order_by.value;
              title === "blood" &&
                (values.product_name = values.product_name.label);
              title === "oxygen" &&
                (values.product_name = values.product_name.value);

              values.bloodtype = values.bloodtype.label;
              // console.log(values.product_name.label);
              window.localStorage.setItem("boat", JSON.stringify(values));

              dispatch(processOrder(values));
              setLoading(true);
              await delay(3000);
              setLoading(false);

              navigate(`/process-order/${order_id}`);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleSubmit,
              setFieldValue,
              handleBlur,
              isSubmitting,
            }) => (
              <Form onSubmit={handleSubmit}>
                <div className="d-flex flex-column justify-content-center">
                  <div className="d-flex justify-content-between mt-3 mb-3">
                    <p className={styled.header} style={{ color: color() }}>
                      ORDER DETAILS{" | "}
                      <span>{order_id}</span>
                    </p>

                    <p
                      className={styled.name}
                      style={{
                        background: bgColor(),
                        color: color(),
                        textTransform: "uppercase",
                      }}
                    >
                      {title}
                    </p>
                  </div>

                  <div className={styled.modalContainer1}>
                    <p className={styled.title}>product</p>
                    <div className={styled.line}></div>
                    {title === "blood" ? (
                      <div
                        className={`${style.formInput} mt-1 ms-3 shadow-none w-50`}
                      >
                        <SelectField
                          className={styled.select}
                          name="product_name"
                          options={productType}
                          onChange={setFieldValue}
                          onBlur={handleBlur}
                        />
                        {errors.product_name && touched.product_name ? (
                          <p className="text-danger mt-1 mx-2">
                            {errors.product_name}
                          </p>
                        ) : null}
                      </div>
                    ) : (
                      <div
                        className={`${style.formInput} mt-1 ms-3 shadow-none w-50`}
                      >
                        <SelectField
                          className={styled.select}
                          name="product_name"
                          options={oxygenDropdown}
                          onChange={setFieldValue}
                          onBlur={handleBlur}
                        />
                        {errors.product_name && touched.product_name ? (
                          <p className="text-danger mt-1 mx-2">
                            {errors.product_name}
                          </p>
                        ) : null}
                      </div>
                    )}
                  </div>

                  {title === "blood" && (
                    <div className={styled.modalContainer1}>
                      <p className={styled.title}>Blood Type</p>
                      <div className={styled.line}></div>
                      <div
                        className={`${style.formInput} mt-1 ms-3 shadow-none w-50`}
                      >
                        <SelectField
                          className={styled.select}
                          name="bloodtype"
                          options={bloodType}
                          onChange={setFieldValue}
                          onBlur={handleBlur}
                        />
                        {errors.bloodtype && touched.bloodtype ? (
                          <p className="text-danger mt-1 mx-2">
                            {errors.bloodtype}
                          </p>
                        ) : null}
                      </div>
                    </div>
                  )}
                  {b_type && (
                    <div className={styled.modalContainer1}>
                      <p className={styled.title}>blood group</p>
                      <div className={styled.line}></div>
                      <p className={styled.content}>{b_type}</p>
                    </div>
                  )}
                  {Patient_Name && (
                    <div className={styled.modalContainer1}>
                      <p className={styled.title}>patient name</p>
                      <div className={styled.line}></div>
                      <p className={styled.content}>{Patient_Name}</p>
                    </div>
                  )}
                  {Doctor_Name && (
                    <div className={styled.modalContainer1}>
                      <p className={styled.title}>doctor name</p>
                      <div className={styled.line}></div>
                      <p className={styled.content}>{Doctor_Name}</p>
                    </div>
                  )}

                  <div className={styled.modalContainer1}>
                    <p className={styled.title}>Phone Number</p>
                    <div className={styled.line}></div>
                    <p className={styled.content}>{contact}</p>
                  </div>

                  <div className={styled.modalContainer1}>
                    <p className={styled.title}>quantity</p>
                    <div className={styled.line}></div>
                    <Form.Group
                      controlId="qty"
                      className={`${style.group} mt-1 ms-3 shadow-none w-25`}
                    >
                      <Form.Control
                        type="number"
                        placeholder="Enter Quantity"
                        name="qty"
                        onChange={handleChange}
                        className={`${style.input} rounded p-2`}
                      ></Form.Control>
                      {errors.qty && touched.qty ? (
                        <p className="text-danger mt-1 mx-2">{errors.qty}</p>
                      ) : null}
                    </Form.Group>
                  </div>

                  <div className={styled.modalContainer1}>
                    <p className={styled.title}>Usage Info</p>
                    <div className={styled.line}></div>
                    <p className={styled.content}>{usage_info}</p>
                  </div>

                  {order_by && (
                    <div className={styled.modalContainer1}>
                      <p className={styled.title}>hospital</p>
                      <div className={styled.line}></div>
                      <p className={styled.content}>{order_by}</p>
                      <div
                        className={`${style.formInput} mt-1 ms-3 shadow-none w-50`}
                      >
                        <SelectField
                          className={style.input}
                          name="order_by"
                          options={hospitalDropDown}
                          onChange={setFieldValue}
                          value={values?.order_by}
                          placeholder="Select Hospital"
                          onBlur={handleBlur}
                        />
                        {errors.order_by && touched.order_by ? (
                          <p className="text-danger mt-1 mx-2">
                            {errors.order_by}
                          </p>
                        ) : null}
                      </div>
                    </div>
                  )}
                  {address && (
                    <div className={styled.modalContainer1}>
                      <p className={styled.title}>hospital address</p>
                      <div className={styled.line}></div>
                      <p className={styled.content}>{address}</p>
                    </div>
                  )}

                  <div className={styled.modalContainer1}>
                    <p className={styled.title}>order time</p>
                    <div className={styled.line}></div>
                    <p className={styled.content}>{constructOrderDate(tym)}</p>
                  </div>

                  <div className="d-flex justify-content-center">
                    <button
                      className={` shadow-none ${styled.actionBtn} ${styled.reject}`}
                      style={{ background: color() }}
                      type="submit"
                      // onClick={() => props.onClickYes()}
                    >
                      {isSubmitting ? <SyncOutlined spin /> : "Process"}
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        }
      />
    </div>
  );
};

export default Boat;
