import React from "react";
import { Col, Row } from "react-bootstrap";
import style from "./BloodComponent.module.scss";

import { IoIosArrowDropleftCircle } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import Blood from "../../../components/InfoCard/Blood";
import { bloodComponents } from "../../../data";
import { saveBloodComponent } from "../../../redux/new-order/actions/new-orders.actions";
import { useDispatch } from "react-redux";

const BloodComponent = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  let userDetails;
  if (localStorage.getItem("userToken")) {
    userDetails = JSON.parse(localStorage.getItem("userToken"));
  }

  if (!userDetails) {
    navigate("/");
  }

  const processHandler = (component) => {
    // dispatch(saveOrderType(component));
    if (component.bloodComponent === "OTHERS") {
      // navigate("/place-order/other-blood-component");
      navigate("/not-available");
      return;
    } else {
      dispatch(saveBloodComponent(component));
      navigate("/blood-group");
    }
  };

  return (
    <div style={{ width: "97%" }}>
      <Row>
        <Col md={1}>
          <IoIosArrowDropleftCircle
            size={50}
            color="#b70303"
            style={{ cursor: "pointer" }}
            onClick={() => navigate(-1)}
          />
        </Col>
        <Col md={10}>
          <h3 className={style.header}>SELECT BLOOD COMPONENT</h3>
          <div className={style.cards}>
            {bloodComponents.map((component) => (
              <Blood
                {...component}
                key={component.id}
                icon
                onClick={() => processHandler(component)}
              />
            ))}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default BloodComponent;
