import React from "react";
import { MdWaterDrop } from "react-icons/md";
import { HiOutlineViewGridAdd } from "react-icons/hi";
import { GiScubaTanks } from "react-icons/gi";
import { BiPhoneCall } from "react-icons/bi";
import { FaSyringe } from "react-icons/fa";
import style from "./ussd.module.scss";
import { constructOrderDate } from "../../../helper/DateFormatter";
import axiosClient from "../../../service/axios";
import { useNavigate } from "react-router-dom";
import { SyncOutlined } from "@ant-design/icons";

const Ussd = ({
  title,
  id,
  product,
  tele,
  qty,
  tym,
  tel,
  bloodtype,
  State,
  services,
  crossm,
}) => {
  const bgColor = () => {
    if (title === "blood") return "#FDE0E0";
    if (title === "oxygen") return "#E0EFFD";
    return "#F0FDE0";
  };

  const icon = () => {
    if (title === "blood") return <MdWaterDrop size={25} color="#B70303" />;
    if (title === "oxygen") return <GiScubaTanks size={25} color="#2457C5" />;
    return <FaSyringe size={25} color="#78A55A" />;
  };

  const color = () => {
    if (title === "blood") return "#B70303";
    if (title === "oxygen") return "#2457C5";
    return "#78A55A";
  };
  const navigate = useNavigate();
  const handleClick = async () => {
    const response = await axiosClient.post(
      `/lite/v2/api/v3/ussd/called/${id}`
    );
    alert('This has been marked as called')
  };

  return (
    <div className={style.container}>
      <div className={style.innerContainer}>
        <div className={style.content}>
          {/* <div className={style.icon} style={{ background: bgColor() }}>
            {icon()}
          </div> */}
          <div className="d-flex flex-column">
            <p className={style.header}>Phone Number</p>+{tele}
          </div>
        </div>

        <div className={style.content}>
          {/* <div className={style.icon} style={{ background: bgColor() }}>
            <HiOutlineViewGridAdd size={25} color={color()} />
          </div> */}
          {/* <div className="d-flex flex-column">
            <p className={style.header}>QUANTITY</p>
            <p className={style.text}>
              {qty}
            </p>
          </div> */}
        </div>
        {/* <div className={style.content}>
          <div className="d-flex flex-column">
            <p className={style.header}>BLOOD TYPE</p>
            <p className={style.text}>{bloodtype}</p>
          </div>
        </div> */}
        <div className={`${style.content} ${style.address}`}>
          {/* <div className={style.icon} style={{ background: bgColor() }}>
            <HiLocationMarker size={25} color={color()} />
          </div> */}
          <div className="d-flex flex-column">
            <p className={style.header}>Time</p>
            <p className={style.text}>{constructOrderDate(tym)}</p>
          </div>
        </div>
        <div className={`${style.content} ${style.address}`}>
          {/* <div className={style.icon} style={{ background: bgColor() }}>
            <BiPhoneCall size={25} color={color()} />
          </div> */}
          <div className="d-flex flex-column" onClick={() => handleClick(id)}>
            <p className={style.header}>CONTACT</p>
            <p className={style.pushbutton}>Called</p>
          </div>
        </div>
      </div>
      <div className={style.time}>Last update : {constructOrderDate(tym)}</div>
    </div>
  );
};

export default Ussd;
