import React from "react";
import styles from "./Info.module.scss";

const Info = ({
  bgColor,
  color,
  cardText,
  value,
  percentage,
  icon,
  label,
  text,
  padding,
  pint,
  onClick,
}) => {
  return (
    <>
      <div
        className={styles.informationContainer}
        onClick={onClick}
        style={{ backgroundColor: bgColor, color: color, padding: padding }}
      >
        <p className={styles.percent}>{percentage}</p>
        <div className={styles.contents}>
          <p>{icon}</p>
          <p className={styles.value}>{value}</p>
          <p className={styles.text}>{text}</p>
          <p className={styles.title}>{cardText}</p>
          <p className={styles.pint}>{pint}</p>
        </div>
        <p className={styles.label}>{label}</p>
      </div>
    </>
  );
};

export default Info;
