import React from "react";
import { Col, Row } from "react-bootstrap";
import { IoIosArrowDropleftCircle } from "react-icons/io";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RiScales2Fill } from "react-icons/ri";
import style from "./Size.module.scss";

const CylinderSize = () => {
  const navigate = useNavigate();
  const { orderType } = useSelector((state) => state.newOrderReducer);

  let userDetails;
  if (localStorage.getItem("userToken")) {
    userDetails = JSON.parse(localStorage.getItem("userToken"));
  }

  if (!userDetails) {
    navigate("/");
  }

  const colors = () => {
    if (orderType?.cardText === "BLOOD") return "#B70303";
    if (orderType?.cardText === "OXYGEN") return "#0779F7";
    if (orderType?.cardText === "STOCKBANK") return "#066304";
    return "#DA621B";
  };

  const handleYes = () => {
    localStorage.setItem("cylinderOption", JSON.stringify("yes"));
    navigate("/place-order/cylinder-size");
  };

  const handleNo = () => {
    localStorage.setItem("cylinderOption", JSON.stringify("no"));
    navigate("/place-order/cylinder-size");
  };

  return (
    <div style={{ width: "97%" }}>
      <Row>
        <Col md={1}>
          <IoIosArrowDropleftCircle
            size={50}
            style={{ cursor: "pointer" }}
            onClick={() => navigate(-1)}
            color={colors()}
          />
        </Col>
        <Col md={10}>
          <div className={style.form}>
            <div className={style.outerContainer}>
              <div className={style.iconContainer}>
                <RiScales2Fill color="#0779F7" size={50} />
              </div>
              <div className={style.container}>
                <p className={style.size}>cylinder size</p>
              </div>
              <p className={style.question}>
                Do you know the exact size of your cylinder?
              </p>
              <div className={style.button} onClick={handleYes}>
                yes
              </div>
              <div className={style.button} onClick={handleNo}>
                no
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default CylinderSize;
