//@return:: 2/2/2022
const DateYearMonthDay = (date) => {
  if (!date) return;
  return new Date(date).toLocaleDateString();
};

//@return:: 2022
const YearAlone = (date) => {
  if (!date) return;

  return new Date(date).getFullYear();
};

// @return:: 12:00 AM
const TimeOnly = (time) => {
  if (!time) return;
  return new Date(time).toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  });
};

const monthsList = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
];

//@months
const MONTH_NAMES = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

//@helper function to convert date to prefferred format
export const getFormattedDate = (
  milliseconds,
  prefomattedDate = false,
  hideYear = false
) => {
  const date = new Date(milliseconds);
  const day = date.getDate();
  const month = MONTH_NAMES[date.getMonth()];
  const year = date.getFullYear();
  const hours = date.getHours();
  let minutes = date.getMinutes();

  if (minutes < 10) {
    // Adding leading zero to minutes
    minutes = `0${minutes}`;
  }

  if (prefomattedDate) {
    // Today at 10:20
    // Yesterday at 10:20
    return `${prefomattedDate} at ${hours}:${minutes}`;
  }

  if (hideYear) {
    // 10. January at 10:20
    return `${day}. ${month} at ${hours}:${minutes}`;
  }

  // 10. January 2017. at 10:20
  return `${day}. ${month} ${year}. at ${hours}:${minutes}`;
};

//@Time ago function
const TimeAgo = (dateParam) => {
  if (!dateParam) {
    return null;
  }

  const date = typeof dateParam === "object" ? dateParam : new Date(dateParam);
  const DAY_IN_MS = 86400000; // 24 * 60 * 60 * 1000
  const today = new Date();
  const yesterday = new Date(today - DAY_IN_MS);
  const seconds = Math.round((today - date) / 1000);
  const minutes = Math.round(seconds / 60);
  const isToday = today.toDateString() === date.toDateString();
  const isYesterday = yesterday.toDateString() === date.toDateString();
  const isThisYear = today.getFullYear() === date.getFullYear();

  if (seconds < 5) {
    return "now";
  } else if (seconds < 60) {
    return `${seconds} seconds ago`;
  } else if (seconds < 90) {
    return "about a minute ago";
  } else if (minutes < 60) {
    return `${minutes} minutes ago`;
  } else if (isToday) {
    return getFormattedDate(date, "Today"); // Today at 10:20
  } else if (isYesterday) {
    return getFormattedDate(date, "Yesterday"); // Yesterday at 10:20
  } else if (isThisYear) {
    return getFormattedDate(date, false, true); // 10. January at 10:20
  }

  return getFormattedDate(date); // 10. January 2017. at 10:20
};

const dateFormat = { DateYearMonthDay, TimeAgo, YearAlone, TimeOnly };

// export const constructOrderDate = (orderUnixTime) => {
//   const dateObj = new Date(orderUnixTime * 1000);
//   var hrs = ("0" + dateObj.getHours()).slice(-2);
//   var mins = ("0" + dateObj.getMinutes()).slice(-2);
//   var day = ("0" + dateObj.getDate()).slice(-2);

//   return `${
//     monthsList[dateObj.getMonth()]
//   }-${day}-${dateObj.getFullYear()} | ${hrs}:${mins} `;
// };

export const constructOrderDate = (orderUnixTime) => {
  const dateObj = new Date(orderUnixTime * 1000);
  const year = dateObj.getFullYear();
  const month = ("0" + (dateObj.getMonth() + 1)).slice(-2); // Adding 1 because getMonth() returns 0-indexed month
  const day = ("0" + dateObj.getDate()).slice(-2);
  const hrs = ("0" + dateObj.getHours()).slice(-2);
  const mins = ("0" + dateObj.getMinutes()).slice(-2);

  return `${year}-${month}-${day} | ${hrs}:${mins}`;
};
export default dateFormat;

export const secondsToHms = (duration) => {
  duration = Number(duration);
  var h = Math.floor(duration / 3600);
  var m = Math.floor((duration % 3600) / 60);
  // var s = Math.floor((duration % 3600) % 60);

  var hDisplay = h > 0 ? h + (h == 1 ? " hour: " : " hours: ") : "";
  var mDisplay = m > 0 ? m + (m == 1 ? " minute " : " minutes ") : "";
  // var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
  return hDisplay + mDisplay;
};
