import React, { useEffect, useState } from "react";
import styled from "./Instruction.module.scss";
import { TbArrowBack } from "react-icons/tb";
import { Formik } from "formik";
import { SelectField } from "../Form/SelectField";

import _ from "lodash";
import { useDispatch } from "react-redux";
import { returnOrder } from "../../redux/orders/actions/orders.actions";
import { Form } from "react-bootstrap";

let number = [
  { id: 1, count: "1 hour" },
  { id: 2, count: "2 hours" },
  { id: 3, count: "3 hours" },
  { id: 4, count: "4 hours" },
  { id: 5, count: "5 hours" },
  { id: 6, count: "6 hours" },
  { id: 7, count: "7 hours" },
  { id: 8, count: "8 hours" },
  { id: 9, count: "9 hours" },
  { id: 10, count: "10 hours" },
  { id: 11, count: "11 hours" },
  { id: 12, count: "12 hours" },
  { id: 13, count: "13 hours" },
  { id: 14, count: "14 hours" },
  { id: 15, count: "15 hours" },
  { id: 16, count: "16 hours" },
  { id: 17, count: "17 hours" },
  { id: 18, count: "18 hours" },
  { id: 19, count: "19 hours" },
  { id: 20, count: "20 hours" },
  { id: 21, count: "21 hours" },
  { id: 22, count: "22 hours" },
  { id: 23, count: "23 hours" },
];

let reasons = [
  {
    id: 1,
    reason: "testing",
  },
  {
    id: 2,
    reason: "Poor Quality of product",
  },
];
const ReturnInstruction = (props) => {
  const dispatch = useDispatch();
  const [data, setData] = useState();
  const [reasonsDropdown, setReasonsDropdown] = useState();

  useEffect(() => {
    let options = number.map((n) => ({
      label: n.count,
      value: n.id,
    }));
    setData(options);
  }, []);

  useEffect(() => {
    let options = reasons.map((r) => ({
      label: r?.reason,
      value: r.id,
    }));
    setReasonsDropdown(options);
  }, []);
  return (
    <div>
      <Formik
        initialValues={{
          title: "",
          order_id: "",
          return_duration: "",
          return_qty: "",
          return_reason: "",
          order_by: "",
          agent: "",
        }}
        validate={(values) => {
          const errors = {};
          if (!values.return_duration) {
            errors.return_duration = "Mandatory";
          }
          if (!values.return_qty) {
            errors.return_qty = "Mandatory";
          }
          if (!values.return_reason) {
            errors.return_reason = "Mandatory";
          }
          return errors;
        }}
        onSubmit={(values) => {
          values.title = props.title;
          values.order_id = props.id;
          values.return_duration = values.return_duration.value;
          values.return_qty = values.return_qty.value;
          values.order_by = props.order_by;
          values.agent = props.agent;

          dispatch(returnOrder(values));

          props.onClickYes();
        }}
      >
        {({
          handleChange,
          handleSubmit,
          isSubmitting,
          values,
          event,
          resetForm,
          setFieldValue,
          handleBlur,
          errors,
          touched,
        }) => (
          <div className="d-flex flex-column justify-content-center">
            <div className="d-flex justify-content-between mt-3 mb-3">
              <p className={styled.header} style={{ color: props.headerColor }}>
                <TbArrowBack size={40} className="me-3" />
                RETURN ORDER
              </p>
              <p
                className={styled.name}
                style={{
                  background: props.categoryBg,
                  color: props.categoryColor,
                  textTransform: "uppercase",
                }}
              >
                {props.id}
              </p>
            </div>

            <div className={styled.modalContainer1}>
              <p className={styled.title}>product name</p>
              <div className={styled.line}></div>
              <p className={styled.content}>{props.productName}</p>
            </div>

            <div className={styled.modalContainer1}>
              <p className={styled.title}>time since delivered</p>
              <div className={styled.line}></div>
              <div className={styled.select}>
                <SelectField
                  name="return_duration"
                  className="border-0"
                  options={data}
                  onChange={setFieldValue}
                  onBlur={handleBlur}
                />
                {errors.return_duration && touched.return_duration ? (
                  <p className="text-danger mt-1 mx-2">
                    {errors.return_duration}
                  </p>
                ) : null}
              </div>
            </div>
            <div className={styled.modalContainer1}>
              <p className={styled.title}>quantity delivered</p>
              <div className={styled.line}></div>
              <p className={styled.content}>{props.qtyDelivered}</p>
            </div>
            <div className={styled.modalContainer1}>
              <p className={styled.title}>return quantity</p>
              <div className={styled.line}></div>
              <div className={styled.select}>
                <SelectField
                  className={styled.select}
                  name="return_qty"
                  options={_.range(1, +props.qtyDelivered + 1).map((n) => ({
                    label: n,
                    value: n,
                  }))}
                  onChange={setFieldValue}
                  onBlur={handleBlur}
                />
                {errors.return_qty && touched.return_qty ? (
                  <p className="text-danger mt-1 mx-2">{errors.return_qty}</p>
                ) : null}
              </div>
            </div>

            <div className={styled.modalContainer1}>
              <p className={styled.title}>hospital name</p>
              <div className={styled.line}></div>
              <p className={styled.content}>{props.hospital}</p>
            </div>
            <div className={styled.modalContainer1}>
              <p className={styled.title}>reason for return</p>
              <div className={styled.line}></div>
              <div className={styled.select}>
                <Form.Control
                  as="textarea"
                  onChange={handleChange}
                  name="return_reason"
                  placeholder="Reason"
                  style={{ height: "100px" }}
                />
                {errors.return_reason && touched.return_reason ? (
                  <p className="text-danger mt-1 mx-2">
                    {errors.return_reason}
                  </p>
                ) : null}
              </div>
              <p className={styled.content}>{props.reason}</p>
            </div>
            <div className="d-flex justify-content-center">
              <button
                type="submit"
                className={` shadow-none ${styled.actionBtn} ${styled.reject}`}
                style={{ background: props.btnColor }}
                onClick={handleSubmit}
              >
                {props.button2}
              </button>
            </div>
          </div>
        )}
      </Formik>
    </div>
  );
};

export default ReturnInstruction;
