import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axiosClient from "../../service/axios";
import Card from "./Card";
import style from "./ViewBid.module.css";
import {
  bidCreateSuccessToast,
  failedBid,
} from "../../components/NotificationToast";
import { SyncOutlined } from "@ant-design/icons";

const ViewBid = () => {
  let { id } = useParams();
  const [showSpinner, setShowSpinner] = useState(false);
  const [all, setAll] = useState();
  const [fast, setFast] = useState();
  const [cheap, setCheap] = useState();
  const Navigate = useNavigate();
  // console.log("Allfast: ", fast);

  // Get data from server
  const getData = async () => {
    setShowSpinner(true);
    try {
      const response = await axiosClient.get(
        `lite/v2/api/admin/bids/items/${id}`
      );
      const others = response?.data?.data?.All;
      const fastest = response?.data?.data?.fastest;
      const cheapest = response?.data?.data?.cheapest;
      setFast(fastest);
      setCheap(cheapest);
      setAll(others);
      setShowSpinner(false);
    } catch (error) {
      console.log("get Bid error", error);
    }
  };

  useEffect(() => {
    getData();
  }, [id]);

//   const handleAccept = async () => {
//     try {
//       if (all && all.length > 0) {
//         // Use Promise.all to handle multiple asynchronous requests
//         await Promise.all(
//           all.map(async (item) => {
//             try {
//               const response = await axiosClient.post(
//                 "lite/v2/api/admin/bids/accept",
//                 {
//                   bid_id: item.id,
//                   ordertype: item.ordertype,
//                 },
//                 {
//                   headers: {
//                     "Content-Type": "application/json",
//                   },
//                 }
//               );

//               if (response?.data?.status === 200) {
//                 bidCreateSuccessToast();
//               } else {
//                 failedBid();
//                 console.log("error one");
//               }
//             } catch (error) {
//               console.error("Error accepting bid:", error);
//               failedBid();
//             }
//           })
//         );
//       } else {
//         console.log("error two");
//         failedBid();
//       }
//     } catch (error) {
//       console.error("Error in handleAccept:", error);
//       failedBid();
//     }
//   };


const handleAccept = async (item) => { 
    try {
      const response = await axiosClient.post(
        "lite/v2/api/admin/bids/accept",
        {
          bid_id: item.id,
          ordertype: item.ordertype,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
  
      if (response?.data?.status === 200) {
        bidCreateSuccessToast();
        setTimeout(() => {
          Navigate('/bid')
        }, 3000)
      } else {
        failedBid();
        console.log("error one");
      }
    } catch (error) {
      console.error("Error accepting bid:", error);
      failedBid();
    }
  };

  return (
    <>
      {showSpinner ? (
        <div className="d-flex justify-content-center align-items-center">
          <SyncOutlined spin />
        </div>
      ) : (
        <Card  />
      )}

      <div>
        <p className={style.title}>Others</p>
      </div>

      <>
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Vendor name</th>
              <th>Total cost</th>
              <th>Created</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {all && all.length > 0 ? (
              all.map((row, index) => (
                <tr key={index}>
                  <td>{row.id}</td>
                  <td>{row.name}</td>
                  <td>{row.cost_rating}</td>
                  <td>{row.created}</td>
                  <td>
                    <button
                      className={style.button}
                      onClick={() => handleAccept(row)}
                    >
                      Accept
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <div className="d-flex justify-content-center align-items-center">
                {showSpinner ? <SyncOutlined spin /> : "Items Not Available"}
              </div>
            )}
          </tbody>
        </table>
      </>
    </>
  );
};

export default ViewBid;
