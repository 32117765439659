const swapTitle = {
  "/": "dashboard",
  "/settings": "update password",
  "/new-orders": "new orders",
  "/ongoing-orders": "ongoing orders",
  "/customer-care": "complaints",
  "/riders": "Dispatch Riders",
  "/place-order": "place order",
  "/process-order": "process order",
  "/process-orders": "process order",
  "/process-neworder": "process order",
  "/process-order/:id": "process order",
  "/track-order/:id": "ORDER TRACKER",
};

export default swapTitle;
