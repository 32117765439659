import React, { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { IoIosArrowDropleftCircle } from "react-icons/io";
import { constructOrderDate } from "../../../helper/DateFormatter";
import Card from "../../../components/paymentCard/Card";
import style from "./Payment.module.scss";
import { useNavigate } from "react-router-dom";
import { payments, paymentsRecievable } from "../../../data";
import { savePaymentMethod } from "../../../redux/new-order/actions/new-orders.actions";
import { useDispatch, useSelector } from "react-redux";
import { ConfirmationModal } from "../../../components/Modal/ConfirmationModal";
import { Formik } from "formik";
import { SyncOutlined } from "@ant-design/icons";
import { hmoSchema } from "../../../components/validation/Schema";
import swal from "sweetalert";

const Payment = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [openModal, setOpenModal] = useState(false);

  let hospitalDetails = JSON.parse(localStorage.getItem("hospitalDetails"));

  const receivables = hospitalDetails?.hospital?.receivables;

  const d = new Date();
  /* Add nr of days*/
  d.setDate(d.getDate() + Number(receivables));

  let userDetails;
  if (localStorage.getItem("userToken")) {
    userDetails = JSON.parse(localStorage.getItem("userToken"));
  }

  if (!userDetails) {
    navigate("/");
  }

  const { orderType } = useSelector((state) => state.newOrderReducer);

  const paymentHandler = (payment) => {
    if (payment?.label === "HMO") {
      dispatch(savePaymentMethod(payment));
      setOpenModal(true);
    } else if (payment?.label === "RECEIVABLES") {
      dispatch(savePaymentMethod(payment));
      swal(
        `You selected the receivables (credit) payment method. Your payment is due on or before ${d.toLocaleDateString()}`
      );
      processHandler(payment);
    } else {
      processHandler(payment);
    }
  };

  const processHandler = (payment) => {
    // dispatch(saveOrderType(payment));

    dispatch(savePaymentMethod(payment));
    if (orderType?.cardText === "STOCKBANK") {
      navigate("/order-info");
      return;
    } else {
      navigate("/patient");
    }
  };

  const colors = () => {
    if (orderType?.cardText === "BLOOD") return "#B70303";
    if (orderType?.cardText === "OXYGEN") return "#0779F7";
    if (orderType?.cardText === "STOCKBANK") return "#066304";
    return "#DA621B";
  };

  return (
    <div style={{ width: "97%" }}>
      <Row>
        <Col md={1}>
          <IoIosArrowDropleftCircle
            size={50}
            style={{ cursor: "pointer" }}
            onClick={() => navigate(-1)}
            color={colors()}
          />
        </Col>
        <Col md={10}>
          <h3 className={style.header} style={{ color: colors() }}>
            SELECT PAYMENT METHOD
          </h3>
          <div className={style.cards}>
            {!receivables || receivables === "Not applicable"
              ? payments.map((payment) => (
                  <Card
                    {...payment}
                    key={payment.id}
                    onClick={() => paymentHandler(payment)}
                  />
                ))
              : paymentsRecievable.map((payment) => (
                  <Card
                    {...payment}
                    key={payment.id}
                    onClick={() => paymentHandler(payment)}
                  />
                ))}
          </div>
        </Col>
      </Row>

      {/* DIALOG MODAL */}
      <ConfirmationModal
        open={openModal}
        bgcolor='background.paper'
        width='40vw'
        handleClose={() => setOpenModal(false)}
        closemodal={() => setOpenModal(false)}
        instruction={
          <Formik
            initialValues={{ hospital: "", doctor: "" }}
            validationSchema={hmoSchema}
            onSubmit={(values, { setSubmitting }) => {
              // dispatch(savePaymentMethod("HMO"));
              localStorage.setItem("hmo", JSON.stringify(values));
              if (orderType?.cardText === "STOCKBANK") {
                navigate("/order-info");
                return;
              } else {
                navigate("/patient");
              }
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleSubmit,
              isSubmitting,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Form.Group
                  controlId='patient'
                  className={`${style.group} mb-3`}
                >
                  <Form.Control
                    type='text'
                    placeholder='Enter Hospital Name'
                    name='hospital'
                    onChange={handleChange}
                    className={`${style.input} rounded p-2`}
                  ></Form.Control>
                  {errors.hospital && touched.hospital ? (
                    <p className='text-danger mt-1 mx-2'>{errors.hospital}</p>
                  ) : null}
                </Form.Group>

                <Form.Group
                  controlId='doctor'
                  className={`${style.group} mb-3`}
                >
                  <Form.Control
                    type='text'
                    placeholder="Enter Doctor's Name"
                    name='doctor'
                    onChange={handleChange}
                    className={`${style.input} rounded p-2`}
                  ></Form.Control>
                  {errors.doctor && touched.doctor ? (
                    <p className='text-danger mt-1 mx-2'>{errors.doctor}</p>
                  ) : null}
                </Form.Group>

                <button
                  type='submit'
                  variant='primary'
                  className={`${style.button} btn w-100`}
                  style={{
                    background: colors(),
                    color: "#fff",
                    border: `1px solid ${colors()}`,
                  }}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? <SyncOutlined spin /> : "Proceed"}
                </button>
              </Form>
            )}
          </Formik>
        }
      />
    </div>
  );
};

export default Payment;
