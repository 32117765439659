/* 
@example 
    
    export const ENDPOINT_ACTION_NAME = "/endpoint-url"
*/

// @Authentication/User
export const USER_LOGIN_URL = "/lite/v2/api/login";

export const FETCH_USER_PROFILE_URL = "/lite/v2/api/login";

export const CHANGE_PASSWORD_URL = "/lite/v2/api/password/change";
