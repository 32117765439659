import React, { useEffect } from "react";

import {
  useJsApiLoader,
  GoogleMap,
  Marker,
  DirectionsRenderer,
} from "@react-google-maps/api";
import CircularLoader from "../Loader/CircularLoader";
import { useState } from "react";

import style from "./map.module.scss";

import "./Step.css";
import axios from "axios";

const center = {
  lat: 6.5156,
  lng: 3.37402,
};

const libraries = ["places"];

const Maps = ({ orderOrigin, orderDestination, eta }) => {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
  });

  const [map, setMap] = useState(/** @type google.maps.Map */ (null));
  const [directionsResponse] = useState(null);

  const [rider, setRider] = useState();
  const [newRider, setNewRider] = useState();

  const baseUrl = "https://plus.lifebank.ke/lite/v2/api/v3/dispatch/tracking";

  useEffect(() => {
    const fetchData = () => {
      axios.get(baseUrl).then((response) => {
        setRider(response?.data);
      });
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchLocation = () => {
      let result = rider?.map((r) => r);
      setNewRider(result);
    };

    fetchLocation();
  }, [rider]);

  const rid = newRider?.map((n) => n);

  if (!isLoaded) {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <CircularLoader />
      </div>
    );
  }

  return (
    <div className={style.map}>
      <div className={style.googleMap}>
        <GoogleMap
          center={center}
          zoom={15}
          mapContainerStyle={{ width: "100%", height: "100%" }}
          options={{
            streetViewControl: false,
            mapTypeControl: false,
            fullscreenControl: false,
          }}
          onLoad={(map) => setMap(map)}
        >
          {rid?.map((r, index) => (
            <Marker
              key={index}
              position={{
                lat: Number(r?.loca?.split(",")[0]),
                lng: Number(r?.loca?.split(",")[1]),
              }}
              title={r?.dispatch_name}
              label={r?.dispatch_name.split("")[0]}
              map={map}
            />
          ))}

          {directionsResponse && (
            <DirectionsRenderer directions={directionsResponse} />
          )}
        </GoogleMap>
      </div>
    </div>
  );
};

export default Maps;
