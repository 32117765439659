export const pints = [
  { id: 1, label: "PINT" },
  { id: 2, label: "PINTS" },
  { id: 3, label: "PINTS" },
  { id: 4, label: "PINTS" },
  { id: 5, label: "PINTS" },
  { id: 6, label: "PINTS" },
  { id: 7, label: "PINTS" },
  { id: 8, label: "PINTS" },
  { id: 9, label: "PINTS" },
  { id: 10, label: "PINTS" },
  { id: 11, label: "PINTS" },
  { id: 12, label: "PINTS" },
];

export const morePints = [
  { id: 13, label: "PINTS" },
  { id: 14, label: "PINTS" },
  { id: 15, label: "PINTS" },
  { id: 16, label: "PINTS" },
  { id: 17, label: "PINTS" },
  { id: 18, label: "PINTS" },
  { id: 19, label: "PINTS" },
  { id: 20, label: "PINTS" },
  { id: 21, label: "PINTS" },
  { id: 22, label: "PINTS" },
  { id: 23, label: "PINTS" },
  { id: 24, label: "PINTS" },
];

export const units = [
  { id: 1, label: "UNIT" },
  { id: 2, label: "UNITS" },
  { id: 3, label: "UNITS" },
  { id: 4, label: "UNITS" },
  { id: 5, label: "UNITS" },
  { id: 6, label: "UNITS" },
  { id: 7, label: "UNITS" },
  { id: 8, label: "UNITS" },
  { id: 9, label: "UNITS" },
  { id: 10, label: "UNITS" },
  { id: 11, label: "UNITS" },
  { id: 12, label: "UNITS" },
];

export const moreUnits = [
  { id: 13, label: "UNITS" },
  { id: 14, label: "UNITS" },
  { id: 15, label: "UNITS" },
  { id: 16, label: "UNITS" },
  { id: 17, label: "UNITS" },
  { id: 18, label: "UNITS" },
  { id: 19, label: "UNITS" },
  { id: 20, label: "UNITS" },
  { id: 21, label: "UNITS" },
  { id: 22, label: "UNITS" },
  { id: 23, label: "UNITS" },
  { id: 24, label: "UNITS" },
];
