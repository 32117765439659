import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import style from "./Reason.module.scss";

import { IoIosArrowDropleftCircle } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { oxygenReasons, reasons } from "../../../data";
import Blood from "../../../components/InfoCard/Blood";
import { useDispatch, useSelector } from "react-redux";
import { saveOrderReason } from "../../../redux/new-order/actions/new-orders.actions";
import { orderPrice } from "../../../redux/orders/actions/orders.actions";
import CircularLoader from "../../../components/Loader/CircularLoader";

const Reason = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  let userDetails;
  if (localStorage.getItem("userToken")) {
    userDetails = JSON.parse(localStorage.getItem("userToken"));
  }

  if (!userDetails) {
    navigate("/");
  }

  function delay(ms) {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  }

  const {
    orderType,
    hospitalDetails,
    bloodComponent,
    cylinderSize,
    bloodGroup,
  } = useSelector((state) => state.newOrderReducer);

  const processHandler = async (reason) => {
    if (reason.reason === "OTHERS") {
      navigate("/other-reason");
      return;
    } else {
      dispatch(saveOrderReason(reason));
      if (orderType?.cardText === "BLOOD") {
        dispatch(
          orderPrice({
            title: orderType?.cardText === "BLOOD" ? "Blood" : "Oxygen",
            hospitalid: hospitalDetails.hospital.value,
            product: bloodComponent.bloodComponent,
            producttype:
              bloodGroup.bloodGroup === "A+" ||
              bloodGroup.bloodGroup === "O+" ||
              bloodGroup.bloodGroup === "B+" ||
              bloodGroup.bloodGroup === "AB+"
                ? "+"
                : "-",
          })
        );
      } else {
        dispatch(
          orderPrice({
            title: orderType?.cardText === "BLOOD" ? "Blood" : "Oxygen",
            hospitalid: hospitalDetails.hospital.value,
            product: "oxygen",
            producttype: cylinderSize.size,
          })
        );
      }
      setLoading(true);
      await delay(4000);
      setLoading(false);
      navigate("/order-details");

      window.location.reload();
    }
  };

  // const { orderType } = useSelector((state) => state.newOrderReducer);

  const colors = () => {
    if (orderType?.cardText === "BLOOD") return "#B70303";
    if (orderType?.cardText === "OXYGEN") return "#0779F7";
    if (orderType?.cardText === "STOCKBANK") return "#066304";
    return "#DA621B";
  };

  return (
    <div style={{ width: "97%" }}>
      <Row>
        <Col md={1}>
          <IoIosArrowDropleftCircle
            size={50}
            style={{ cursor: "pointer" }}
            onClick={() => navigate(-1)}
            color={colors()}
          />
        </Col>
        <Col md={10}>
          <h3 className={style.header} style={{ color: colors() }}>
            SELECT REASON FOR ORDER
          </h3>
          {loading ? (
            <div className="d-flex justify-content-center align-items-center">
              <CircularLoader />
            </div>
          ) : (
            <div className={style.cards}>
              {orderType?.cardText === "BLOOD"
                ? reasons.map((reason) => (
                    <Blood
                      key={reason.id}
                      {...reason}
                      icon
                      onClick={() => processHandler(reason)}
                    />
                  ))
                : oxygenReasons.map((reason) => (
                    <Blood
                      key={reason.id}
                      {...reason}
                      icon
                      onClick={() => processHandler(reason)}
                    />
                  ))}
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default Reason;
