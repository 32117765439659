import axiosClient from "../../../service/axios";
import {
  NOTIFICATION_COUNT_URL,
  TICKET_URL,
  SHOW_ALL_PO,
  SINGLE_QUOTATION,
  SEE_QUOTATION,
  SEE_BID,
} from "./admin.endpoints";

/*
@get admin dashboard count

*/
const fetchDashboardCount = async () => {
  try {
    return await axiosClient.get(NOTIFICATION_COUNT_URL);
  } catch (error) {
    return error;
  }
};

/*
@get tickets

*/
const fetchTickets = async () => {
  try {
    return await axiosClient.get(TICKET_URL);
  } catch (error) {
    return error;
  }
};

const showAllPo = async () => {
  try {
    return await axiosClient.get(SHOW_ALL_PO);
  } catch (error) {
    return error;
  }
};

const singleQuotation = async (id) => {
  try {
    return await axiosClient.get(SINGLE_QUOTATION + id);
  } catch (error) {
    return error;
  }
};

const seeQuotation = async () => {
  try {
    return await axiosClient.get(SEE_QUOTATION);
  } catch (error) {
    return error;
  }
};

const bidQuotation = async () => {
  try {
    return await axiosClient.get(SEE_BID);
  } catch (error) {
    return error;
  }
};

const AdminService = {
  fetchDashboardCount,
  fetchTickets,
  showAllPo,
  singleQuotation,
  seeQuotation,
  bidQuotation,
};

export default AdminService;
