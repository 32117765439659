import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { Formik } from "formik";
import { SelectField } from "./SelectField";
import { useEffect } from "react";
import Styles from "./Ticket.module.scss";
import { complainOrder } from "../../redux/orders/actions/orders.actions";
import { useDispatch } from "react-redux";
import { Form } from "react-bootstrap";

const statuses = [
  {
    value: "open",
    label: "Open",
  },
  {
    value: "close",
    label: "Close",
  },
];

const priorities = [
  {
    value: "low",
    label: "Low",
  },
  {
    value: "medium",
    label: "Medium",
  },
  {
    value: "high",
    label: "High",
  },
];

const reasons = [
  {
    value: "Product is not good",
    label: "Product is not good",
  },
];

const assigns = [
  {
    value: "operations",
    label: "Operations",
  },
];

export default function Ticket(props) {
  const [status, setStatus] = React.useState("open");
  const [priority, setPriority] = React.useState("medium");
  const [reason, setReason] = React.useState("request");
  const [assign, setAssign] = React.useState("sales");

  const dispatch = useDispatch();

  useEffect(() => {
    let options = statuses?.map((status, index) => ({
      label: status?.label,
      value: status?.value,
    }));
    setStatus(options);
  }, []);

  useEffect(() => {
    let options = priorities?.map((priority) => ({
      label: priority?.label,
      value: priority?.value,
    }));
    setPriority(options);
  }, []);

  useEffect(() => {
    let options = reasons?.map((reason) => ({
      label: reason?.label,
      value: reason?.value,
    }));
    setReason(options);
  }, []);

  useEffect(() => {
    let options = assigns?.map((assign) => ({
      label: assign?.label,
      value: assign?.value,
    }));
    setAssign(options);
  }, []);

  return (
    <>
      <Formik
        initialValues={{
          title: "",
          order_id: "",
          order_by: "",
          agent: "",
          priority: "",
          assign: "",
          reason: "",
        }}
        // // validationSchema={informationSchema}
        // validate={(values) => {
        //   const errors = {};
        //   if (!values.cross) {
        //     errors.cross = "Required";
        //   } else if (!values.temp) {
        //     errors.temp = "Required";
        //   } else if (!values.test) {
        //     errors.test = "Required";
        //   }
        //   return errors;
        // }}
        onSubmit={(values) => {
          values.title = props.title;
          values.order_id = props.id;
          values.priority = values.priority.value;
          values.assign = values.assign.value;
          values.reason = values.reason.label;
          values.order_by = props.order_by;
          values.agent = props.agent;

          dispatch(complainOrder(values));
          props.onClickYes();
          // console.log("complain::", values);
          // props.onClickYes();

          // dispatch(addContactPerson(values, actions));
        }}
      >
        {({
          handleChange,
          handleSubmit,
          isSubmitting,
          values,
          event,
          resetForm,
          setFieldValue,
          handleBlur,
          errors,
          touched,
        }) => (
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": { m: 1, minWidth: "50ch" },
            }}
            noValidate
            autoComplete="off"
          >
            <div>
              <TextField
                id="outlined-required"
                label={`Hospital`}
                defaultValue={props.hospital}
                disabled
              />
              <TextField
                id="outlined-required"
                label={`Order-id`}
                defaultValue={props.order_id}
                disabled
              />
            </div>
            <div className="d-flex mt-3 mb-3 align-items-center">
              <TextField
                id="outlined-required"
                label={`Status`}
                defaultValue="Open"
                disabled
              />
              {/* <div className="w-50 ms-2 me-3">
                <SelectField
                  name="return_duration"
                  options={status}
                  onChange={setFieldValue}
                  // value={values?.hospital}
                  placeholder="Status"
                  onBlur={handleBlur}
                />
              </div> */}

              <div className="w-50 me-2">
                <SelectField
                  style={{ height: "10vh" }}
                  name="priority"
                  options={priority}
                  onChange={setFieldValue}
                  // value={values?.hospital}
                  placeholder="Priority"
                  onBlur={handleBlur}
                />
              </div>
            </div>
            <div className="d-flex mt-3 mb-3">
              <div className="w-50 ms-2 me-3">
                <SelectField
                  name="reason"
                  options={reason}
                  onChange={setFieldValue}
                  // value={values?.hospital}
                  placeholder="Complain Reason"
                  onBlur={handleBlur}
                />
              </div>{" "}
              <div className="w-50 me-2">
                <SelectField
                  name="assign"
                  options={assign}
                  onChange={setFieldValue}
                  // value={values?.hospital}
                  placeholder="Assign to"
                  onBlur={handleBlur}
                />
              </div>
            </div>

            <div>
              {/* <textarea
                // type="text-area"
                // className={`${Styles.formInput} shadow-none form-control`}
                placeholder="Description"
                name="discription"
                onChange={handleChange}
                rows="4"
                cols="100"
                // value={""}
              /> */}
              <Form.Control
                as="textarea"
                onChange={handleChange}
                name="description"
                placeholder="Description"
                style={{ height: "100px" }}
              />
            </div>

            <div className="d-flex justify-content-center mt-3">
              <button
                type="submit"
                className={` shadow-none ${Styles.actionBtn} ${Styles.confirm} `}
                style={{ background: props.btnColor }}
                onClick={() => props.onClickNo()}
              >
                Cancel
              </button>

              <button
                type="submit"
                className={` shadow-none  ${Styles.actionBtn} ${Styles.reject}`}
                style={{ background: props.btnColor }}
                onClick={handleSubmit}
              >
                Create
              </button>
            </div>
          </Box>
        )}
      </Formik>
    </>
  );
}
