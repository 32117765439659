/* 
@example 
    
    export const ENDPOINT_ACTION_NAME = "/endpoint-url"
*/

//@dashboard count
export const NOTIFICATION_COUNT_URL = "/dashboard";

//@ticket
export const TICKET_URL = "/lite/v2/api/v3/dashboard/complain";

export const SHOW_ALL_PO = "/lite/v2/api/admin/po/list";

export const SINGLE_QUOTATION = "/lite/v2/api/admin/products/quotations/single/";

export const SEE_QUOTATION = "/lite/v2/api/admin/products/quotations";

export const SEE_BID = "/lite/v2/api/admin/bids/ready";