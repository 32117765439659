import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Form, Row } from "react-bootstrap";
import { Formik } from "formik";
import style from "./OtherReason.module.scss";
import { IoIosArrowDropleftCircle } from "react-icons/io";
import { GiWaterDrop } from "react-icons/gi";
import FormContainer from "../../../components/FormContainer";
import { saveOrderReason } from "../../../redux/new-order/actions/new-orders.actions";
import { useNavigate } from "react-router-dom";
import { orderPrice } from "../../../redux/orders/actions/orders.actions";
import { SyncOutlined } from "@ant-design/icons";

const OtherReason = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let userDetails;
  if (localStorage.getItem("userToken")) {
    userDetails = JSON.parse(localStorage.getItem("userToken"));
  }

  if (!userDetails) {
    navigate("/");
  }

  const {
    orderType,
    hospitalDetails,
    bloodComponent,
    cylinderSize,
    bloodGroup,
  } = useSelector((state) => state.newOrderReducer);

  const colors = () => {
    if (orderType?.cardText === "BLOOD") return "#B70303";
    if (orderType?.cardText === "OXYGEN") return "#0779F7";
    if (orderType?.cardText === "STOCKBANK") return "#066304";
    return "#DA621B";
  };
  return (
    <>
      <Row>
        <Col md={1}>
          <IoIosArrowDropleftCircle
            size={50}
            color={colors()}
            style={{ cursor: "pointer" }}
            onClick={() => navigate(-1)}
          />
        </Col>
        <Col md={10}>
          <FormContainer>
            <div className={style.form}>
              <div className='mb-5 d-flex justify-content-center'>
                {/* <img src={logo} alt='MEIA' width={158} height={52} /> */}
                <Row>
                  <Col
                    className='d-flex justify-content-center'
                    style={{
                      background: colors(),
                      padding: "10px",
                      borderRadius: "10px",
                    }}
                  >
                    <GiWaterDrop color='white' size={40} />
                  </Col>
                </Row>
              </div>
              <Formik
                initialValues={{ reason: "" }}
                validate={(values) => {
                  const errors = {};
                  if (!values.reason) {
                    errors.reason = "please state a reason";
                  }
                  return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {
                  dispatch(saveOrderReason(values, setSubmitting));
                  if (orderType?.cardText === "BLOOD") {
                    dispatch(
                      orderPrice({
                        title:
                          orderType?.cardText === "BLOOD" ? "Blood" : "Oxygen",
                        hospitalid: hospitalDetails.hospital.value,
                        product: bloodComponent.bloodComponent,
                        producttype:
                          bloodGroup.bloodGroup === "A+" ||
                          bloodGroup.bloodGroup === "O+" ||
                          bloodGroup.bloodGroup === "B+" ||
                          bloodGroup.bloodGroup === "AB+"
                            ? "+"
                            : "-",
                      })
                    );
                    // await delay(3000)
                    // navigate("/order-details");
                  } else {
                    dispatch(
                      orderPrice({
                        title:
                          orderType?.cardText === "BLOOD" ? "Blood" : "Oxygen",
                        hospitalid: hospitalDetails.hospital.value,
                        product: "oxygen",
                        producttype: cylinderSize.size,
                      })
                    );
                  }
                  navigate("/order-details");
                  window.location.reload();
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <Form.Group
                      controlId='reason'
                      className={`${style.group} mb-3`}
                    >
                      <Form.Control
                        type='text'
                        placeholder='Other Reason For Order'
                        name='reason'
                        onChange={handleChange}
                        className={`${style.input} rounded p-2`}
                      ></Form.Control>
                      <GiWaterDrop className={style.icon} />
                      {errors.reason && touched.reason ? (
                        <p className='text-danger mt-1 mx-2'>{errors.reason}</p>
                      ) : null}
                    </Form.Group>

                    <button
                      type='submit'
                      variant='primary'
                      className={`${style.button} btn`}
                      style={{
                        background: colors(),
                        border: `1px solid ${colors()}`,
                      }}
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? <SyncOutlined spin /> : "Proceed"}
                    </button>
                  </Form>
                )}
              </Formik>
            </div>
          </FormContainer>
        </Col>
      </Row>
    </>
  );
};

export default OtherReason;
