import React from "react";
import styles from "./Info.module.scss";

import { ImDroplet } from "react-icons/im";
import { GiScubaTanks, GiFirstAidKit } from "react-icons/gi";
import { BsInboxesFill } from "react-icons/bs";

const Place = ({ bgColor, color, cardText, icon, onClick }) => {
  const icons = () => {
    if (cardText === "BLOOD") return <ImDroplet size={45} />;
    if (cardText === "OXYGEN") return <GiScubaTanks size={40} />;
    if (cardText === "STOCKBANK") return <BsInboxesFill size={45} />;
    return <GiFirstAidKit size={40} />;
  };

  const background = () => {
    if (cardText === "BLOOD") return "#FDE0E0";
    if (cardText === "OXYGEN") return "#BCDEFE";
    if (cardText === "STOCKBANK") return "#E3FEE5";
    return "#FEE5D7";
  };

  const colors = () => {
    if (cardText === "BLOOD") return "#B70303";
    if (cardText === "OXYGEN") return "#0779F7";
    if (cardText === "STOCKBANK") return "#066304";
    return "#DA621B";
  };

  return (
    <>
      <div className={styles.Container} onClick={() => onClick()} id={cardText}>
        <div
          style={{
            backgroundColor: (bgColor = background()),
            color: (color = colors()),
          }}
          className={styles.content}
        >
          <p>{(icon = icons())}</p>
          <p className={styles.name}>{cardText}</p>
        </div>
      </div>
    </>
  );
};

export default Place;
