import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import style from "./Card.module.css";
import { FaUserDoctor } from "react-icons/fa6";
import { GiReceiveMoney } from "react-icons/gi";
import { LuArchiveRestore } from "react-icons/lu";
import axiosClient from "../../service/axios";
import { bidCreateSuccessToast, failedBid } from "../../components/NotificationToast";

const Card = (props) => {
  let { id } = useParams();
  const Navigate = useNavigate();
  // const { handleAccept, fast, cheap } = props
  // console.log("id", id);
  const [fast, setFast] = useState();
  const [cheap, setCheap] = useState();
  // console.log("fast: ", fast);
  // console.log("cheap: ", cheap);

  //Get data from server

  const getData = async () => {
    try {
      const response = await axiosClient.get(
        `lite/v2/api/admin/bids/items/${id}`
      );
      const fastest = response?.data?.data?.fastest;
      const cheapest = response?.data?.data?.cheapest;
      setFast(fastest);
      setCheap(cheapest);
    } catch (error) {
      console.log("get Bid error", error);
    }
  };

  useEffect(() => {
    getData();
  }, [id]);

  const handleFast = async () => { 
    try {
      const response = await axiosClient.post(
        "lite/v2/api/admin/bids/accept",
        {
          bid_id: fast.id,
          ordertype: fast.ordertype,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
  
      if (response?.data?.status === 200) {
        bidCreateSuccessToast();
        // console.log("clicked")
        setTimeout(() => {
          Navigate('/bid')
        }, 3000)
      } else {
        failedBid();
        console.log("error one");
      }
    } catch (error) {
      console.error("Error accepting bid:", error);
      failedBid();
    }
  };


  const handleCheap = async () => { 
    try {
      const response = await axiosClient.post(
        "lite/v2/api/admin/bids/accept",
        {
          bid_id: cheap.id,
          ordertype: cheap.ordertype,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
  
      if (response?.data?.status === 200) {
        bidCreateSuccessToast();
        setTimeout(() => {
          Navigate('/bid')
        }, 3000)
      } else {
        failedBid();
        console.log("error one");
      }
    } catch (error) {
      console.error("Error accepting bid:", error);
      failedBid();
    }
  };

  

  return (
    <div>
      <div>
        <p className={style.title}>System Recommendation</p>
      </div>

      <div className={style.card}>
        <div>
          <p className={style.bid}>Lowest Bids</p>
          <div className={style.background}>
            {cheap ? (
              // cheap.map((cheap, index) => (
              <div>
                <div className={style.detail}>
                  <div className={style.icon}>
                    <FaUserDoctor />
                  </div>
                  <p>Vendor name: {cheap.name}</p>
                </div>

                <div className={style.detail}>
                  <div className={style.icon}>
                    <GiReceiveMoney />
                  </div>
                  <p>Total cost: {cheap.cost_rating}</p>
                </div>

                <div className={style.detail}>
                  <div className={style.icon}>
                    <LuArchiveRestore />
                  </div>
                  <p>Items:</p>
                </div>

                <div className={style.btn}>
                  <button className={style.button} onClick={() => handleCheap()}>
                    Accept
                  </button>
                </div>
              </div>
            ) : (
              // ))
              <div className="d-flex justify-content-center align-items-center">No cheapest Bid For Now!</div>
            )}
          </div>
        </div>

        <div>
          <p className={style.bid}>Closest Bids</p>
          <div className={style.background}>
            {fast ? (
              // fast?.map((fast, index) => (
              <div>
                <div className={style.detail}>
                  <div className={style.icon}>
                    <FaUserDoctor />
                  </div>
                  <p>Vendor name: {fast.name}</p>
                </div>

                <div className={style.detail}>
                  <div className={style.icon}>
                    <GiReceiveMoney />
                  </div>
                  <p>Total cost: {fast.cost_rating}</p>
                </div>

                <div className={style.detail}>
                  <div className={style.icon}>
                    <LuArchiveRestore />
                  </div>
                  <p>Items:</p>
                </div>

                <div className={style.btn}>
                  <button className={style.button} onClick={() => handleFast()}>
                    Accept
                  </button>
                </div>
              </div>
            ) : (
              // ))
              <div className="d-flex justify-content-center align-items-center">No Fatest Bid For Now!</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
