import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Styles from "./ConfirmationModal.module.scss";

export const ConfirmationModal = ({
  open,
  handleClose,
  closemodal,
  instruction,
  button1,
  button2,
  width,
  bgcolor,
  onClickNo,
  onClickYes,
}) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: width,
    // height: height,
    // overflow: "scroll",
    zIndex: 19000000,
    bgcolor: bgcolor,
    borderRadius: "15px",
    boxShadow: "1px 2px 10px 5px rgba(112, 144, 176, 0.1)",
    p: 4,
  };

  // const closemodal = () => {
  //   props.closemodal();
  // };


  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        // BackdropComponent={Backdrop}
        // BackdropProps={{
        //   timeout: 1000,
        // }}
        // {...props}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={Styles.modal}
      >
        <Box sx={style}>
          <>
            <div onClick={closemodal}>
              <button className={Styles.close}>X</button>
            </div>
            {instruction && <div>{instruction}</div>}
            <section className="d-flex justify-content-center mt-3">
              {button1 && (
                <div
                  className={` shadow-none ${Styles.actionBtn} ${Styles.confirm}`}
                  onClick={() => onClickYes()}
                >
                  {button1}
                </div>
              )}

              {button2 && (
                <button
                  className={` shadow-none ${Styles.actionBtn} ${Styles.reject}`}
                  onClick={() => onClickNo()}
                >
                  {button2}
                </button>
              )}
            </section>
          </>
        </Box>
      </Modal>
    </div>
  );
};
