import React from "react";
import Select from "react-select";

import { useFormikContext } from "formik";

function SelectField(props) {
  const {
    id,
    name,
    label,
    placeholder,
    options,
    isMulti,
    isDisabled,
    error,
    isClearable,
    backspaceRemovesValue,
  } = props;

  const { values, setFieldValue, setFieldTouched, touched } =
    useFormikContext();

  const handleChange = (option) => {
    setFieldValue(name, option);
  };

  const handleBlur = () => {
    setFieldTouched(name);
  };

  return (
    <div className="input-field-wrapper">
      {label && (
        <h3 className="input-label" htmlFor={name} error={error}>
          {label}
        </h3>
      )}

      <Select
        id={id}
        placeholder={placeholder}
        options={options}
        value={values[name]}
        onChange={handleChange}
        onBlur={handleBlur}
        touched={touched}
        error={error}
        isMulti={isMulti}
        isDisabled={isDisabled}
        isClearable={isClearable}
        backspaceRemovesValue={backspaceRemovesValue}
        components={{ ClearIndicator: null }}
      />

      {touched && error ? <p className="error-text">{error}</p> : null}
    </div>
  );
}

export { SelectField };
