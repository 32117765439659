// importing actions
import {
  LOAD_DASHBOARD_COUNT,
  TICKET,
  SHOW_ALL_PO,
  GET_ALL_QUOTATION,
  SINGLE_QUOTATION,
  BID_QUOTATION,
} from "../../types";

// state
const INITIAL_STATE = {
  dashboardCounts: null,
  ticket: {},
  seeQuotation: [],
  seeBid: [{}],
  showPo: [],
  singleQuotation: [],
};

// Redux reducer function
const adminReducer = (state = INITIAL_STATE, action) => {
  // Destructuring action
  const { type, payload, page, itemsPerPage } = action;

  switch (type) {
    case LOAD_DASHBOARD_COUNT:
      return {
        ...state,
        dashboardCounts: payload,
      };
    case GET_ALL_QUOTATION:
      return {
        ...state,
        seeQuotation: payload,
      };
    case BID_QUOTATION:
      return {
        ...state,
        seeBid: payload,
      };
    case SHOW_ALL_PO: {
      return {
        ...state,
        showPo: payload || [],
        currentPage: page,
        itemsPerPage: itemsPerPage,
      };
    }
    case SINGLE_QUOTATION:
      return {
        ...state,
        singleQuotation: payload,
      };
    case TICKET:
      return {
        ...state,
        ticket: payload,
      };

    default:
      return state;
  }
};

export default adminReducer;
