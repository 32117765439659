import React, { useState } from "react";
import style from "./Quantity.module.scss";
import { IoIosArrowDropleftCircle } from "react-icons/io";
import { Col, Form, Row } from "react-bootstrap";
import { pints, morePints, units, moreUnits } from "../../../pint";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { saveOrderQuantity } from "../../../redux/new-order/actions/new-orders.actions";
import { ConfirmationModal } from "../../../components/Modal/ConfirmationModal";
import { Formik } from "formik";
import { SyncOutlined } from "@ant-design/icons";

const Quantity = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [openModal, setOpenModal] = useState(false);

  let userDetails;
  if (localStorage.getItem("userToken")) {
    userDetails = JSON.parse(localStorage.getItem("userToken"));
  }

  if (!userDetails) {
    navigate("/");
  }

  const [buttonActive, setbuttonActive] = useState(true);
  const [setTab] = useState("");

  const moreFunc = () => {
    setbuttonActive(!buttonActive);
    setTab("more");
  };

  const processHandler = (pint, mp) => {
    // dispatch(saveOrderType(pint));
    dispatch(saveOrderQuantity(pint));
    navigate("/payment");
  };

  const { orderType } = useSelector((state) => state.newOrderReducer);

  const colors = () => {
    if (orderType?.cardText === "BLOOD") return "#B70303";
    if (orderType?.cardText === "OXYGEN") return "#0779F7";
    if (orderType?.cardText === "STOCKBANK") return "#066304";
    return "#DA621B";
  };

  return (
    <div style={{ width: "97%" }}>
      <Row>
        <Col md={1}>
          <IoIosArrowDropleftCircle
            size={50}
            color={colors()}
            style={{ cursor: "pointer" }}
            onClick={() => navigate(-1)}
          />
        </Col>
        <Col md={10}>
          <h3 className={style.header} style={{ color: colors() }}>
            SELECT QUANTITY
          </h3>
          {buttonActive && (
            <div className={style.cards}>
              {orderType?.cardText === "BLOOD"
                ? pints.map((pint) => (
                    <div
                      key={pint.id}
                      className={style.card}
                      onClick={() => processHandler(pint)}
                    >
                      <p>{pint.id}</p>
                      <p>{pint.label}</p>
                    </div>
                  ))
                : units.map((pint) => (
                    <div
                      key={pint.id}
                      className={style.card}
                      onClick={() => processHandler(pint)}
                    >
                      <p>{pint.id}</p>
                      <p>{pint.label}</p>
                    </div>
                  ))}
            </div>
          )}

          <div onClick={() => moreFunc()}>
            {buttonActive ? (
              <button
                onClick={() => setTab("more")}
                className={style.btn}
                style={{ background: colors() }}
              >
                view more options
              </button>
            ) : (
              <button
                onClick={() => setTab("more")}
                className={style.btn}
                style={{ background: colors() }}
              >
                view less
              </button>
            )}
          </div>

          {!buttonActive && (
            <>
              <div className={style.cards}>
                {orderType?.cardText === "BLOOD"
                  ? morePints.map((mp) => (
                      <div
                        onClick={() => processHandler(mp)}
                        key={mp.id}
                        className={style.card}
                      >
                        <p>{mp.id}</p>
                        <p>{mp.label}</p>
                      </div>
                    ))
                  : moreUnits.map((mp) => (
                      <div
                        onClick={() => processHandler(mp)}
                        key={mp.id}
                        className={style.card}
                      >
                        <p>{mp.id}</p>
                        <p>{mp.label}</p>
                      </div>
                    ))}
              </div>

              <div className={style.other} onClick={() => setOpenModal(true)}>
                Click here to input more{" "}
                {orderType?.cardText === "BLOOD" ? "pints" : "units"}
              </div>
            </>
          )}
        </Col>
      </Row>

      {/* DIALOG MODAL */}
      <ConfirmationModal
        open={openModal}
        bgcolor="background.paper"
        width="30vw"
        handleClose={() => setOpenModal(false)}
        closemodal={() => setOpenModal(false)}
        instruction={
          <Formik
            initialValues={{ id: "" }}
            validate={(values) => {
              const errors = {};
              if (!values.id) {
                errors.id = "This field is mandatory";
              }
              return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              dispatch(saveOrderQuantity(values));
              navigate("/payment");
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleSubmit,
              isSubmitting,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Form.Group
                  controlId="patient"
                  className={`${style.group} mb-3`}
                >
                  <Form.Control
                    type="number"
                    placeholder="Input more"
                    name="id"
                    onChange={handleChange}
                    className={`${style.input} rounded p-2`}
                  ></Form.Control>
                  {errors.id && touched.id ? (
                    <p className="text-danger mt-1 mx-2">{errors.id}</p>
                  ) : null}
                </Form.Group>

                <button
                  type="submit"
                  variant="primary"
                  className={`${style.button} btn w-100`}
                  style={{
                    background: colors(),
                    color: "#fff",
                    border: `1px solid ${colors()}`,
                  }}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? <SyncOutlined spin /> : "Proceed"}
                </button>
              </Form>
            )}
          </Formik>
        }
      />
    </div>
  );
};

export default Quantity;
