import { TbVaccineBottle } from "react-icons/tb";

export const productType = [
  {
    id: 1,
    cardText: "BLOOD",
  },
  {
    id: 2,
    cardText: "OXYGEN",
  },
  {
    id: 3,
    cardText: "STOCKBANK",
  },
  {
    id: 4,
    cardText: "OTHERS",
  },
];

export const bloodComponents = [
  {
    id: 1,
    bloodComponent: "PLATELET",
  },
  {
    id: 2,
    bloodComponent: "CRYOPRECIPITATE",
  },
  {
    id: 3,
    bloodComponent: "WHOLE BLOOD",
  },
  {
    id: 4,
    bloodComponent: "FRESH FROZEN PLASMA",
  },
  {
    id: 5,
    bloodComponent: "PACKED RED BLOOD CELLS",
  },
  {
    id: 6,
    bloodComponent: "OTHERS",
  },
];

export const bloodGroup = [
  {
    id: 1,
    bloodGroup: "A+",
  },
  {
    id: 2,
    bloodGroup: "A-",
  },
  {
    id: 3,
    bloodGroup: "B+",
  },
  {
    id: 4,
    bloodGroup: "B-",
  },
  {
    id: 5,
    bloodGroup: "O+",
  },
  {
    id: 6,
    bloodGroup: "O-",
  },
  {
    id: 7,
    bloodGroup: "AB+",
  },
  {
    id: 8,
    bloodGroup: "AB-",
  },
  {
    id: 9,
    bloodGroup: "UNSURE",
  },
];

export const genotype = [
  {
    id: 1,
    genotype: "AA",
  },
  {
    id: 2,
    genotype: "AS",
  },
  {
    id: 3,
    genotype: "AC",
  },
  {
    id: 4,
    genotype: "CC",
  },
  {
    id: 5,
    genotype: "SC",
  },
  {
    id: 6,
    genotype: "SS",
  },
  {
    id: 7,
    genotype: "UNSURE",
  },
];

export const payments = [
  {
    id: 1,
    label: "POS",
  },
  {
    id: 2,
    label: "CASH ON DELIVERY",
  },
  {
    id: 3,
    label: "CHEQUE",
  },
  {
    id: 4,
    label: "HMO",
  },
];

export const paymentsRecievable = [
  {
    id: 1,
    label: "POS",
  },
  {
    id: 2,
    label: "CASH ON DELIVERY",
  },
  {
    id: 3,
    label: "CHEQUE",
  },
  {
    id: 4,
    label: "HMO",
  },
  {
    id: 5,
    label: "RECEIVABLES",
  },
];

export const editType = [
  {
    id: 1,
    label: "PAYMENT",
  },
  {
    id: 2,
    label: "DISPATCH",
  },
  {
    id: 3,
    label: "VENDOR",
  },

  {
    id: 4,
    label: "QUANTITY",
  },
];

export const editComponent = [
  {
    id: 1,
    label: "PAYMENT",
  },
  {
    id: 2,
    label: "DISPATCH",
  },
  {
    id: 3,
    label: "VENDOR",
  },
  {
    id: 4,
    label: "COMPONENT",
  },
  {
    id: 5,
    label: "QUANTITY",
  },
];

export const oxygenOrder = [
  {
    id: 1,
    label: "REFILL",
  },
  {
    id: 2,
    label: "EXCHANGE",
  },
  {
    id: 3,
    label: "DROP OFF",
  },
];

export const reasons = [
  {
    id: 1,
    reason: "GENERAL SURGERY",
  },
  {
    id: 2,
    reason: "BLOOD LOSS FROM ACCIDENT",
  },
  // {
  //   id: 3,
  //   reason: "WHOLE BLOOD",
  // },
  {
    id: 4,
    reason: "INTENSIVE CARE TREATMENT",
  },
  {
    id: 5,
    reason: "TREATMENT OF NEWBORN BABIES & CHILDREN",
  },
  {
    id: 6,
    reason: "ORTHOPAEDIC",
  },
  {
    id: 7,
    reason: "RENAL SURGERY",
  },
  {
    id: 8,
    reason: "POST PARTUM HEMORRAGE",
  },
  {
    id: 9,
    reason: "OTHERS",
  },
];

export const oxygenReasons = [
  {
    id: 1,
    reason: "GENERAL SURGERY",
  },
  {
    id: 2,
    reason: "PNEUMONIA",
  },

  {
    id: 3,
    reason: "COVID",
  },
  {
    id: 4,
    reason: "ASTHMA",
  },
  {
    id: 5,
    reason: "UPPER RESPITORY TRACT INFECTION",
  },
  {
    id: 6,
    reason: "LOWER RESPITORY TRACT INFECTION",
  },
  {
    id: 7,
    reason: "OTHERS",
  },
];

export const stockbank = [
  {
    id: 1,
    name: "Surgical Gloves 7’",
    desc: "8PPKTS/CTN",
    price: 7000,
    qty: 1,
  },
  {
    id: 2,
    name: "Universal Sample Bottle",
    desc: "25PCS PER PACK",
    price: 1900,
    qty: 1,
  },
  {
    id: 3,
    name: "25PCS PER PACK",
    desc: "12PCS/PACK, 50PKS/CARTON",
    price: 4500,
    qty: 1,
  },
  {
    id: 4,
    name: "10ml Syringes & Needles",
    desc: "8P100PCS/PACK, 12PKS/CTN",
    price: 6500,
    qty: 1,
  },
];

export const cylinderSize = [
  {
    id: 1,
    size: "0.5 Cubic Meter",
  },
  {
    id: 2,
    size: "1 Cubic Meter",
  },
  {
    id: 3,
    size: "1.5 Cubic Meter",
  },
  {
    id: 4,
    size: "1.6 Cubic Meter",
  },
  {
    id: 5,
    size: "2 Cubic Meter",
  },
  {
    id: 6,
    size: "2.5 Cubic Meter",
  },
  {
    id: 7,
    size: "3 Cubic Meter",
  },
  {
    id: 8,
    size: "4 Cubic Meter",
  },
  {
    id: 9,
    size: "5 Cubic Meter",
  },
  {
    id: 10,
    size: "6 Cubic Meter",
  },
  {
    id: 11,
    size: "7 Cubic Meter",
  },
  {
    id: 12,
    size: "7.5 Cubic Meter",
  },
  {
    id: 13,
    size: "8 Cubic Meter",
  },
  {
    id: 14,
    size: "9 Cubic Meter",
  },
  {
    id: 15,
    size: "10 Cubic Meter",
  },
];

export const cylinderCategory = [
  {
    id: 1,
    size: "Small Cylinder",
    icon: <TbVaccineBottle size={40} color='white' />,
  },
  {
    id: 2,
    size: "Medium Cylinder",
    icon: <TbVaccineBottle size={40} color='white' />,
  },
  {
    id: 3,
    size: "Large Cylinder",
    icon: <TbVaccineBottle size={40} color='white' />,
  },
];

export const locations = [
  {
    id: 1,
    lat: 6.4471696,
    lng: 3.5178536,
  },
  {
    id: 2,
    lat: 6.4760679,
    lng: 3.6170514,
  },
  {
    id: 3,
    lat: 6.4760679,
    lng: 3.6170514,
  },
  {
    id: 4,
    lat: 6.4760679,
    lng: 3.6170514,
  },
  {
    id: 5,
    lat: 9.1075535,
    lng: 7.4176679,
  },
];

export const updateBloodComponent = [
  {
    id: 1,
    bloodComponent: "Platelet",
  },
  {
    id: 2,
    bloodComponent: "Cryoprecipitate",
  },
  {
    id: 3,
    bloodComponent: "Whole blood",
  },
  {
    id: 4,
    bloodComponent: "Fresh frozen plasma",
  },
  {
    id: 5,
    bloodComponent: "Packed red blood cells",
  },
];

export const paymentMode = [
  {
    value: "POS",
    label: "POS",
  },
  {
    value: "CASH ON DELIVERY",
    label: "CASH ON DELIVERY",
  },
  {
    value: "BANK TRANSFER",
    label: "BANK TRANSFER",
  },
  {
    value: "CHEQUE",
    label: "CHEQUE",
  },
  {
    value: "BOAT",
    label: "BOAT",
  },
  {
    value: "HMO",
    label: "HMO",
  },
  {
    value: "RECEIVABLES",
    label: "RECEIVABLES",
  },
];
