import React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { IoIosCheckmarkCircle } from 'react-icons/io';
import Styles from './SuccessModal.module.scss';

export const SuccessModal = (props) => {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -10%)',
    width: props.width,
    maxHeight: props.height,
    bgcolor: props.bgcolor,
    borderRadius: '15px',
    boxShadow: '1px 2px 10px 5px rgba(112, 144, 176, 0.1)',
    pt: 5,
    pb: 3,
  };

  return (
    <div>
      <Modal
        // open={open}
        // onClose={handleClose}
        // BackdropComponent={Backdrop}
        // BackdropProps={{
        //   timeout: 1000,
        // }}
        {...props}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={Styles.modal}
      >
        <Box sx={style}>
          {props.instruction && (
            <div>
              <div className={Styles.close}>
                <IoIosCheckmarkCircle size={70} />
              </div>
              {props.instruction}
            </div>
          )}
          {(props.button1 || props.button2) && (
            <section className="d-flex justify-content-center mt-3">
              {props.button1 && (
                <button
                  className={` shadow-none ${Styles.actionBtn} ${Styles.confirm}`}
                  onClick={() => props.onClickYes()}
                >
                  {props.button1}
                </button>
              )}

              {props.button2 && (
                <button
                  className={` shadow-none ${Styles.actionBtn} ${Styles.reject}`}
                  onClick={() => props.onClickNo()}
                >
                  {props.button2}
                </button>
              )}
            </section>
          )}
        </Box>
      </Modal>
    </div>
  );
};
