import { GET_CHAT, POST_CHAT } from "../../types";

//state
const INITIAL_STATE = {
  data: [],
  message: [],
};

//Redux reducer
const chatReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_CHAT:
      return {
        ...state,
        data: payload,
      };
    case POST_CHAT:
        return {
            ...state,
            message: payload,
        }
    default:
      return state;
  }
};

export default chatReducer;
