import React from "react";
import { Col, Row } from "react-bootstrap";
import style from "./OrderInformation.module.scss";
import { IoIosArrowDropleftCircle } from "react-icons/io";
import Card from "../../../components/orders/card/Card";
import { useNavigate } from "react-router-dom";

const OrderInformation = () => {
  const navigate = useNavigate();

  let userDetails;
  if (localStorage.getItem("userToken")) {
    userDetails = JSON.parse(localStorage.getItem("userToken"));
  }

  if (!userDetails) {
    navigate("/");
  }

  return (
    <div style={{ width: "97%" }}>
      <Row>
        <Col md={1}>
          <IoIosArrowDropleftCircle
            size={50}
            color="#b70303"
            style={{ cursor: "pointer" }}
            onClick={() => navigate(-1)}
          />
        </Col>
        <Col md={10}>
          <h3 className={style.header}>ORDER INFORMATION</h3>
          <Card />
          {/* <Card
            question={"CROSS MATCH?"}
            text={"Would you like us to cross-match?"}
            name={"cross"}
            value
          /> */}
          {/* <Card
            question={"DO YOU NEED TEMPERATURE STRIPS?"}
            text={"This helps you monitor blood temperature"}
            name={"temp"}
            value
          />
          <Card
            question={"CONDUCT EXTRA TESTS"}
            text={"Would you like us to conduct Malaria ans PCV tests?"}
            name={"test"}
            value
          /> */}
        </Col>
      </Row>
      {/* <button className={style.btn} onClick={() => quantity()}>
        Proceed
      </button> */}
    </div>
  );
};

export default OrderInformation;
