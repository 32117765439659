import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Form } from "react-bootstrap";
import { Formik } from "formik";
import FormContainer from "../../components/FormContainer";
import style from "./Login.module.scss";
import { SyncOutlined } from "@ant-design/icons";
import { FiMail } from "react-icons/fi";
import { BsEyeFill, BsEyeSlashFill } from "react-icons/bs";
import { login } from "../../redux/user/actions/user.actions";
import { loginSchema } from "../../components/validation/Schema";
import lite from "./image/lite.png";
import { useNavigate } from "react-router-dom";

const LoginScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [passwordType, setPasswordType] = useState("password");

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  useEffect(() => {
    if (localStorage.getItem("userToken")) {
      navigate("/");
    }
  }, []);

  return (
    <>
      <FormContainer>
        <div className={style.form}>
          <div className="mb-2 d-flex justify-content-center">
            <div className="d-flex justify-content-center">
              <img src={lite} alt="LITE" width={150} height={120} />
            </div>
          </div>
          <Formik
            initialValues={{ email: "", password: "" }}
            validationSchema={loginSchema}
            onSubmit={(values, { setSubmitting }) => {
              dispatch(login(values, setSubmitting));
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleSubmit,
              isSubmitting,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="email" className={`${style.group} mb-3`}>
                  <Form.Control
                    type="email"
                    placeholder="EMAIL"
                    name="email"
                    onChange={handleChange}
                    className={`${style.input} rounded p-2 shadow-none`}
                  ></Form.Control>
                  <FiMail className={style.icon} />
                  {errors.email && touched.email ? (
                    <p className="text-danger mt-1 mx-2">{errors.email}</p>
                  ) : null}
                </Form.Group>

                <Form.Group
                  controlId="password"
                  className={`${style.group} mb-3`}
                >
                  <Form.Control
                    type={passwordType}
                    placeholder="PASSWORD"
                    name="password"
                    onChange={handleChange}
                    className={`${style.input} rounded p-2 shadow-none`}
                  ></Form.Control>
                  <div className={style.icon2} onClick={togglePassword}>
                    {passwordType === "password" ? (
                      <BsEyeSlashFill size={20} />
                    ) : (
                      <BsEyeFill size={20} />
                    )}
                  </div>
                  {/* <FiLock className={style.icon} /> */}
                  {errors.password && touched.password ? (
                    <p className="text-danger mt-1 mx-2">{errors.password}</p>
                  ) : null}
                </Form.Group>

                <button
                  type="submit"
                  variant="primary"
                  className={`${style.button} btn`}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? <SyncOutlined spin /> : "Login"}
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </FormContainer>
    </>
  );
};

export default LoginScreen;
