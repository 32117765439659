import React from "react";
import { Col, Row } from "react-bootstrap";
import { IoIosArrowDropleftCircle } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { saveAgentType } from "../../../redux/new-order/actions/new-orders.actions";
import style from "./Agent.module.scss";

const Agent = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { orderType } = useSelector((state) => state.newOrderReducer);

  const colors = () => {
    if (orderType?.cardText === "BLOOD") return "#B70303";
    if (orderType?.cardText === "OXYGEN") return "#0779F7";
    if (orderType?.cardText === "STOCKBANK") return "#066304";
    return "#DA621B";
  };

  const agents = [
    {
      label: "Order from customer",
      value: "customer",
    },
    {
      label: "Order from sales agent",
      value: "sales",
    },
  ];

  const handleClick = (value) => {
    if (orderType?.cardText === "STOCKBANK") {
      dispatch(saveAgentType(value));
      navigate("/place-order/select-products");
      return;
    } else {
      dispatch(saveAgentType(value));
      navigate("/place-order/hospital-detail");
    }
  };
  return (
    <div className={style.container}>
      <Row>
        <Col md={1}>
          <IoIosArrowDropleftCircle
            size={50}
            color={colors()}
            style={{ cursor: "pointer" }}
            onClick={() => navigate(-1)}
          />
        </Col>
        <Col md={10}>
          <h3 className={style.header} style={{ color: colors() }}>
            {/* Agent */}
          </h3>
          <div className={style.contents}>
            <div className={style.cards}>
              {agents.map(({ value, label }) => {
                return (
                  <div
                    className={style.cards_card}
                    style={{ background: colors() }}
                    key={value}
                    onClick={() => handleClick(value)}
                  >
                    {label}
                  </div>
                );
              })}
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Agent;
